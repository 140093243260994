<template>
    <div data-testid="webrtc">
        <!-- <VoiceHintBar ref="voiceHint"
            :question="question"
            :hint="hint"
            :answer="answer"
        /> -->
        <Video ref="video"
            :speechRecognition="speechRecognition"
            :isRealTime="getIsAutoRecognize"
        />
        <AllForOne ref="allForOne"
            v-if="showAllForOne"
            :speechRecognition="speechRecognition"
        /> 
        <!-- <div>
            <input v-model="testText" name="ss" id="" cols="30" class="border" />
            <v-btn
            @click="() => speechRecognition(testText, 'websocket', 123456, 123456)">asdfsf</v-btn>
        </div>-->
        <notifications group="foo" position="top right"/>
        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <span class="close" @click="close_email_model">&times;</span>
                <h2>發送生成式報告至以下Email</h2>
                <div class="sendmail">
                    <input type="email" v-model="email" placeholder="Enter email">
                    <button @click="sendmailAndFinish()">發送</button>
                </div>
            </div>
        </div>
        <audio id='speak' :src="require(`@/assets/speak.wav`)" crossorigin="anonymous"></audio>
        <audio id='write' :src="require(`@/assets/write.mp3`)" crossorigin="anonymous"></audio>
        <audio id='saved' :src="require(`@/assets/saved.wav`)" crossorigin="anonymous"></audio>
        <audio id='success' :src="require(`@/assets/success.wav`)" crossorigin="anonymous"></audio>
		<audio id='evasgo' :src="require(`@/assets/evasgo_1.wav`)" crossorigin="anonymous"></audio>
        <audio id='insertion2cecum' :src="require(`@/assets/insertion2cecum_1.wav`)" crossorigin="anonymous"></audio>
        <audio id='finish' :src="require(`@/assets/finish_1.wav`)" crossorigin="anonymous"></audio>
        <!-- guide voices -->
        <audio id="location" src="/guide/location.mp3" crossorigin="anonymous" />
        <audio id="distance" src="/guide/distance.mp3" crossorigin="anonymous" />
		<audio id="size" src="/guide/size.mp3" crossorigin="anonymous" />
        <audio id="intervention" src="/guide/intervention.mp3" crossorigin="anonymous" />
        <audio id="try-again" src="/guide/try-again.mp3" crossorigin="anonymous" />
        <v-dialog v-model="showEvasgoWarning" width="400">
            <v-card>
                <v-card-title class="justify-center headline">警告</v-card-title>
                <v-card-text class="text-xs-center">
                    本檢查已記錄過時間，是否歸零重新計時?
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn dark color="green darken-1" @click="handleEvasgoWarning(false)">NO</v-btn>
                    <v-btn dark color="red darken-1" @click="handleEvasGo">YES</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CLOSE_RECORD_ALL_TIME } from '@/utils/constants'
import { STORAGE } from '@/utils/localStorage'
import { get } from 'lodash'
import { idleQuestion, idleHint} from '@/components/examinations/VoiceHintBar.vue'
import Video from '@/components/examinations/Video.vue'
import AllForOne from '@/components/examinations/AllForOne.vue'
import { MapUIforFinding, MailMapUiforFinding } from '@/settings/findingUIMap.js'
import { reportServices, ttsServices } from "@/services/db";
import { insertionLevelList, cleanScaleList, complicationsList } from '@/store/data/input-list'
import common from '../utils/common'



const NEED_GUIDE = [MapUIforFinding.Colonoscopy['tubular adenoma']]
const GUIDE_STEPS = {
    [MapUIforFinding.Colonoscopy['tubular adenoma']]: [
        'location',
        'distance',
        'size',
        'intervention',
    ],
}
const FIELD_MAP_GUIDE_STEP = {
    finding: 'finding',
    loca: 'location',
    dist: 'distance',
    size: 'size',
    interven: 'intervention',
}
const GUIDE_STEP_MAP_FIELD = {
    location: 'loca',
    distance: 'dist',
    size: 'size',
    intervention: 'interven',
}

const CATEGORY_MAP = {
    // n: null,
    m: 'maneuver',
    l: 'clo location',
    p: 'patho',
    d: 'device',
    i: 'injection',
    ic: 'intraoperative condition',
    o: 'other',
}

const NO_TIME = '00:00:00'

const array2Object = (fn, arr) => arr.reduce(fn, {})
const combineResultWithItem = (result,item) => ({...result, [item.text.toLowerCase()]: item.value})

const CLEAN_SCALE_LIST = array2Object(combineResultWithItem, cleanScaleList)
const INSERTION_LEVEL_LIST = array2Object(combineResultWithItem, insertionLevelList)
const COMPILACTION_LIST = array2Object(combineResultWithItem, complicationsList)

let complications = [];


export default {
    components: {
        Video,
        AllForOne,
    },
    data() {
        return {
            testText: '',
            answerGroup: [],
            writeInputValue: [],
            audioList: [],
            question: idleQuestion,
            answer: '',
            hint: idleHint,
            testInput: '',

            photoNum: null,
            colonPolypectomyFocus: false,
            //all 通用指令
            commonCommand: ["delete finding", "finding over", "cancel", 
                "evas go", "insertion to", "proximal time", "finish",
                "new location", "delete location"
            ],
            // 分 examType
            actionCommand: ["cleansing level", "indication", "clo", "clo location", "finding", "lesion", "complications"],
            actionCommandArr: [],
            guideSteps: [],
            currentGuideStep: '',
            guideNotMatchCount: 0,
            audioTimeout: 0,
            stopGuide: false,
            storeTimeout: 0,
            isEvasGo: false,
            isInsertionTo: false,
            isProximalColon: false,
            isFinish: false,
            evasgoWarning: false,
            isHandlingAnalysis: false,
            receiveDataFromWS: '',
            username: null,
            showModal: false,
            email:"",
        }
    },
    mounted() {
        window.voiceInput = this.addWindowVoiceInput
        window.speechRecognition = this.speechRecognition
        window.addEventListener('keydown', this.onkeydown)
        window.addEventListener('keyup', this.onkeyup)
        this.username = this.get_username()
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.onkeydown)
        window.removeEventListener('keyup', this.onkeyup)

        if (this.isEvasGo && !this.isFinish) {
            this.updateTimer({ step: 4 })
        }
        this.setTimeCountingStep(0)

        clearTimeout(this.audioTimeout)
        clearTimeout(this.storeTimeout)
    },
    computed: {
        ...mapGetters('examinations', [
            'getQuestionsList',
            'getDoctorList',
            'getTechnicalStaffList',
            'getExaminationData',
            'getLandmarkList',
            'getExaminationTypeList',
            'getInspectionInstrumentList',
            'getWashInstrumentList',
            'getCleanMedList',
            'getSedativeList',
            'getPatient',
            'getStoreHiddenParameter',
            'getVoiceCommandTestKeyword',
            'getIndicationList',
        ]),
        ...mapGetters(['getUser', 'getIsAutoRecognize', 'getSetting']),
        findUiMap() {
            return this.username === "demo" ? MailMapUiforFinding : MapUIforFinding
        },
        showAllForOne () {
            return !this.isFinish && !this.closeRecordAllTime
        },
        closeRecordAllTime () {
            let item = STORAGE.get(CLOSE_RECORD_ALL_TIME)
            return item ? JSON.parse(item) : false
        },
        getFindings() {
            return this.getExaminationData ? this.getExaminationData.findings : null
        },        
        examinationID() {
            return this.getExaminationData.examination.id
        },

        accNo() {
            return this.getExaminationData.examination.accNo
        },

        examination_typeID() {
            return this.getExaminationData.examination.examination_typeID
        },

        examinationSedatives() {
            return this.getExaminationData.sedatives
        },

        getFinding() {
            return this.getExaminationData.findings[this.getStoreHiddenParameter.nowFindingIndex]
        },

        getRecord() {
            return this.getFinding && this.getFinding.records && this.getFinding.records[this.getStoreHiddenParameter.nowRecordIndex]
        },

        examinationLocations() {
            return this.getRecord.questions.location
        },

        getLocation() {
            return this.getRecord.questions.location[this.getStoreHiddenParameter.nowLocationsIndex]
        },

        getInterventions() {
            return this.getRecord.questions.interven.config.options
        },
        getIntervenForVoice() {
            const options = this.getRecord.questions.interven.config.options
            if (!options) return {}
            return Object.entries(options).reduce((acc, [key, val]) => {
                let name = val.toLowerCase();
                return { ...acc, [name]: key };
            }, {});
        },

        getIntervenValue() {
            return this.getRecord.questions.interven.value
        },

        time_start() {
            return this.getExaminationData.examination.time_start
        },

        time_insertion_level() {
            return this.getExaminationData.examination.time_insertion_level
        },

        time_landmark_splenic_flexure() {
            return this.getExaminationData.examination.time_landmark_splenic_flexure
        },

        time_finished() {
            return this.getExaminationData.examination.time_finished
        },

        isGuiding() {
            return !!this.guideSteps.length
        },

        isCounted() {
            return this.getTime(['examination', 'insertionTime']) !== NO_TIME ||
                this.getTime(['examination', 'proximalColonWithTime']) !== NO_TIME ||
                this.getTime(['examination', 'withdrawTime']) !== NO_TIME ||
                this.getTime(['examination', 'totalExamTime']) !== NO_TIME
        },

        showEvasgoWarning: {
            get() {
                return this.evasgoWarning && this.isCounted
            },
            set(v) {
                this.evasgoWarning = v
            },
        },
        indicationKey() {
            return (
                this.getIndicationList &&
                this.getIndicationList.map((indi) => indi.value)
            )
        },
        exam_status() {
            return this.getExaminationData.examination.status
        },
    },
    methods: {
        ...mapActions(['setLoading', 'setLoadingDescript']),
        ...mapActions('examinations', [
            'updateExaminationColumn',
            'setExaminationFinishDialog',
            'setStoreHiddenParameter',
            'setRecording',
            'setTimeCounting',
            'setTimeCountingStep',
            'setTimeFinish',
            'setCommandQueue',
            'setCurrentCommand',
            'sendDICOMStructureReport',
            'fetchDescriptiveParagraph',
            'updateDescriptiveParagraph',
            'sendEmail',
        ]),
         /**
         * Add a new finding to the examination.
         *
         * @param {number} uiNumber - The EVAS number of the finding.
         */
        async addNewFinding (uiNumber){
            await this.$store.dispatch('examinations/addFinding', this.getExaminationData.examination.id)
            const nowFindingIndex = this.getExaminationData.findings.length - 1
            this.setHiddenParameter(nowFindingIndex, 0);
            this.setStoreHiddenParameter({
                target: 'over',
                value: false
            });
            await this.writeInput("finding", "s", uiNumber);
        },
        close_email_model() {
            this.showModal = false
            this.sendReport()
        },
        playAudio (audio, waitT) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    this.audioList.push(audio)
                    audio.play()
                    resolve()
                }, waitT)
            })
        },
        get_username() {
            const user = sessionStorage.getItem("user");
            if (user) {
                return JSON.parse(user).username;
            }
            return null;
        },
        stopAudio () {
            this.audioList.forEach(au => {
                au.pause()
            })
            this.audioList = []
        },
        // TODO: 優化
        checkEachActionCommand (key, value) {
            if (this.examination_typeID === 1) {
                if (key === 'clo') {
                    const lastCloTest = this.getExaminationData.examination.cloTest === '1'
                    return !lastCloTest
                } else if (key === 'cloLoca') {
                    const locations = this.getExaminationData.examination.cloTestLocations
                    const currentVal = value.replace('clo location ', '')
                    const item = {
                        'pylorus': 153,
                        'antrum': 154,
                        'low-body': 155
                    }
                    const last = locations.includes(item[currentVal])
                    return !last
                }
            } else {
                if (key === 'indication') {
                    
                    const indication = this.getExaminationData.examination.indication
                    const regex = /indication\s(9)?/
                    const currentVal = value.replace(regex, '')
                    const last = indication.includes(currentVal)
                    return !last
                }
            }
            return this.actionCommandArr.includes(key)
        },
        async modifyFetchDataAudio(writeValue, instruction) {
            let result = []
            instruction.forEach((instr, idx) => {
                let current = Object.entries(instr).reduce((acc, [key, value]) => {
                    if (Array.isArray(value)) {
                        const result = value.map((val, valIdx) => {
                            if (val === 'common') { return [ key, 'on' ] }
                            return  !writeValue[idx][key][valIdx] && !this.checkEachActionCommand(key, value) ? [val, 'off']: [val, 'on']
                        })
                        return [...acc, ...result]
                    }
                    if (value === 'common') { return [...acc, [key, 'on']] }
                    const status = (!writeValue[idx] || !writeValue[idx][key] || writeValue[idx][key] == 0) && !this.checkEachActionCommand(key, value)? 'off' : 'on'
                    return[...acc, [value, status]]
                }, [])
                result.push(current)
            })
            console.log('write', writeValue)
            console.log('instruction', instruction)

            let waitTime = 0
            result.forEach(async(r) => {
               const audio = await this.fetchAudio(r)
               if (audio) {
                   await this.playAudio(audio, waitTime)
                   waitTime = waitTime + audio.duration * 1000
               }
            })
        },
        async fetchAudio(arr) {
            try {
                const res = await ttsServices.getAudioFeedBack(
                    arr,
                    common.get_tts_url(
                        this.getSetting.asr_domain,
                        this.getSetting.tts_port,
                        this.getSetting.tts_path,
                    ),
                );
                if(!res) {
                    return res
                }
                const audioURL = URL.createObjectURL(res)
                const audio = new Audio(audioURL);
                return new Promise((resolve, reject) => {
                    audio.addEventListener("loadedmetadata", () => {
                        resolve(audio);
                    });
                    audio.addEventListener("error", (error) => {
                        reject(error);
                    });
                });
            } catch (e) {
                // Handle any errors
            }
        },
        onkeydown(evt) {
            if (evt.keyCode === 90) {
                this.stopAudio()
                this.stopGuide = true

                if (this.guideSteps.length) {
                    this.resetGuiding()
                }

                if (this.audio) {
                    this.audio.pause()
                    this.audio.currentTime = 0
                }
                if (this.audioTimeout) {
                    clearTimeout(this.audioTimeout)
                }
            }
        },
        toggleLoading (status, text) {
            this.setLoading(status)
            this.setLoadingDescript(text)
        },
        mailnotify () {
            this.$notify({
                group: 'topCenter',
                text: 'Mail成功發送',
            })
        },
        async sendmailAndFinish() {
            const paragraphObj = {
                eid: this.examinationID,
                etype: this.examination_typeID,
                email: this.email
            }
            await this.sendReport()
            this.toggleLoading(true, '敘述性文章產生中，請稍等...')
            await this.sendEmail(paragraphObj).then(() => {
                console.log("Sending email succeeded, now notifying...");
                this.mailnotify()
            })
            this.toggleLoading(false, '')
        },
        // CUSTOM
        async sendReport () {
            const paragraphObj = {
                eid: this.examinationID,
                etype: this.examination_typeID,
            }
            if (this.getSetting.descriptive) {
                if (this.exam_status === '2') {
                    this.toggleLoading(true, '敘述性文章產生中，請稍等...')
                    await this.updateDescriptiveParagraph(paragraphObj)
                    this.toggleLoading(false, '')
                }
            }

            if (this.getSetting.descriptive) {
                this.toggleLoading(true, '敘述性文章產生中，請稍等...')
                await this.fetchDescriptiveParagraph(paragraphObj)
                this.toggleLoading(false, '')
            }

            // DEMO
            if (this.getSetting.demo) {
                this.$router.push({
                    path: `/reports/${this.examinationID}/accNo/${this.accNo}/preview`
                });
            }
            // reportServices.sendSRReport(Number(this.examinationID), this.examination_typeID)
        },
        reportHIS() {
            console.log(
                `====== report HIS ======> examinationId: ${Number(
                this.examinationID
                )}, worklistId: ${this.accNo}`
            );
            reportServices.uploadHISReport({
                examinationId: Number(this.examinationID),
                worklistId: this.accNo,
            });
        },

        reportHPA() {
            console.log(
                `====== report HPA ======> examinationId: ${Number(
                this.examinationID
                )}, worklistId: ${this.accNo}`
            );
            reportServices.uploadHPAReport({
                examinationId: Number(this.examinationID),
                worklistId: this.accNo,
            });
        },
        onkeyup(evt) {
            if (evt.keyCode === 90) {
                this.stopGuide = false
            }
        },
        musicPlay(voiceinput) {
            this.audio = document.getElementById(voiceinput);
            if (this.audio) {
                return this.audio.play()
            }
            return Promise.resolve()
        },
        setExaminationColumn(column, value) {
            var d = {
                id: this.examinationID,
                column: column,
                value: value
            }
            this.updateExaminationColumn(d)
        },
        async addWindowVoiceInput(voiceInputArray) {
            let self = this
            let p = Promise.resolve();
            let frontEndLog;
            let url;
            const options = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            // voiceInputArray = ["GU","location","angle","orientation","pw","active bleeding","yes"];
            // voiceInputArray = ["intervention","biopsy","apc"];
            this.answerGroup = []
            this.writeInputValue = []
            this.question = ''
            this.answer = ''
            for (let i = 0; i < voiceInputArray.length; i++) {
                if(/delete photo [0-9]+/gi.test(voiceInputArray[i])){
                    console.log("DELETE")
                    continue;
                }
                if (self.currentGuideStep) {
                    if (voiceInputArray[0] === self.currentGuideStep) {
                        await self.voiceCommand(voiceInputArray[i].toLowerCase(), voiceInputArray).then(() => {
                            self.setCurrentCommand(voiceInputArray[i]);
                        });
                    }
                } else {
                    await self.voiceCommand(voiceInputArray[i].toLowerCase(), voiceInputArray).then(() => {
                        self.setCurrentCommand(voiceInputArray[i]);
                    })
                    
                }

                frontEndLog = new Date().getTime().toString();
                console.log('# voiceCommand: ' + frontEndLog + ' => ' + voiceInputArray[i].toLowerCase());

                let params = JSON.stringify({
                    'frontEndLog': 'voiceCommand: ' + frontEndLog + ' ' + voiceInputArray[i].toLowerCase()
                });
                url = '/api/frontEndLog?t=' + (new Date()).getTime().toString();
                self.$http.post(url, params, options)
            }
            if (Number(this.getUser.useGuiding)) {
                await this.modifyFetchDataAudio(this.writeInputValue, this.answerGroup);
            }
            return p;
        },
        speechRecognition(recorderData, mode, startTimestamp, endTimestamp) {
            if (mode == 'test') {
                this.testInput = recorderData;
            }
            const url = '/api/speech-recognition?t=' + (new Date()).getTime().toString();
            var interven = null;
            if (this.getFinding) {
                if (this.getRecord) {
                    if (this.getRecord.questions.interven) {
                        interven = this.getIntervenValue;
                    }
                }
            }

            const data = this.getExaminationData.examination;
			const params = JSON.stringify({
				translationMode: 'o',
				mode: mode,
				file: recorderData,
				focus: this.getStoreHiddenParameter.focus,
				part: this.getStoreHiddenParameter.nowQuestionPart,
				examinationID: this.examinationID,
				examination_typeID: this.examination_typeID,
				examinationDoctorID: this.getExaminationData.examination.examinationDoctorID,
				startTimestamp,
				endTimestamp,
				finding: this.getFinding ? this.getFinding.questions.finding.value : null,
				interven: interven,
                over: this.getStoreHiddenParameter.over,   
                patient: this.getPatient,
                doctor: data.examinationDoctorID > 1 ? this.getDoctorList.filter(item => item.id === data.examinationDoctorID)[0] : null,
                technician1: data.technical_staffID_1 > 1 ? this.getTechnicalStaffList.filter(item => item.id === data.technical_staffID_1)[0]: null,
                technician2: data.technical_staffID_2 > 1 ? this.getTechnicalStaffList.filter(item => item.id === data.technical_staffID_2)[0]: null
            });
            const options = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const _this = this;

            this.$http.post(url, params, options).then((response) => {
                if (mode === 'test') {
                    this.addWindowVoiceInput(response.data.voiceInput)
                } else if (mode === 'websocket') {                    
                    this.receiveDataFromWS = recorderData;
                    if (this.receiveDataFromWS === 'start word detect') {
                        this.musicPlay('speak')
                        this.$emit('isHandlingAnalysis', true)
                        return
                    } else if (this.receiveDataFromWS === 'end word detect')  {
                        this.$emit('isHandlingAnalysis', false)
                        return
                    }
                    if (response.data.voiceInput[0] != '') {
                        if (response.data.voiceInput.length === 3 && response.data.voiceInput[0] === 'photo') {
                            if ( response.data.voiceInput[2] === 'before' || response.data.voiceInput[2] === 'after') {
                                const regex = /^[0-9]*$/g;
                                if (regex.exec(response.data.voiceInput[1]) != null) {
                                    this.photoNum = Number(response.data.voiceInput[1])
                                }
                            }
                        }
                        //驗證是否為數字
                        let numRegex = /^[1-9][0-9]?$|^100$/
                        if(response.data.voiceInput[1] && !numRegex.exec(response.data.voiceInput[1])){
                            let responseVoiceInput = response.data.voiceInput[1].toLowerCase()
                            if (!GUIDE_STEPS[[MapUIforFinding.Colonoscopy['tubular adenoma']]].includes(responseVoiceInput)) {
                                _this.musicPlay(`${responseVoiceInput}`)
                            }
                        }
                        
                        setTimeout(() => {
                            this.musicPlay('success').then(() => {
                                if (this.currentGuideStep) {
                                    // - 第一項與導引項目相同，且是一完整句子
                                    if (response.data.voiceInput[0] === this.currentGuideStep && response.data.voiceInput.length > 1) {
                                        this.guideNotMatchCount = 0
                                    } else {
                                        if (this.guideNotMatchCount > 1) {
                                            this.resetGuiding()
                                        } else if (this.guideNotMatchCount === 0) {
                                            this.guideNotMatchCount += 1
                                            this.autoRecording('try-again')
                                        }
                                    }
                                }
                                this.addWindowVoiceInput(response.data.voiceInput)
                            })
                        }, 300);       
                    } else {
                        if (this.isGuiding) {
                            this.resetGuiding()
                        }
                    }
                }
            });
        },
        // 確認欄位存在並設定 focus
        checkInputExistAndSetFocuse(table, focus, part) {
            if (this.getFinding && (!this.getStoreHiddenParameter.over || focus == "finding")) {
                switch (table) {
                    case "finding":
                        if (this.getFinding.questions[focus]) {
                            this.setStoreHiddenParameter({
                                target: 'focus',
                                value: focus,
                                part: part
                            });
                            return true;
                        }
                        return false;
                    case "record":
                        if (this.getRecord) {
                            if (this.getRecord.questions[focus]) {
                                this.setStoreHiddenParameter({
                                    target: 'focus',
                                    value: focus,
                                    part: part
                                });
                                return true;
                            } else if (this.examinationLocations) {
                                if (this.getLocation?.questions[focus]) {
                                    this.setStoreHiddenParameter({
                                        target: 'focus',
                                        value: focus,
                                        part: part
                                    });
                                    return true;
                                }
                            }
                        }
                        return false;
                }
            }
            return false;
        },
        // 顯示選項提示
        showOptionTip(options) {
            this.hint = JSON.stringify(options).replace(/\"/g, "")
        },
        async autoRecording(step) {
            clearTimeout(this.audioTimeout)
            if (!this.stopGuide) {
                await this.musicPlay(step)
                this.audioTimeout = setTimeout(async () =>  {
                    if (!this.stopGuide) {
                        await this.$refs.video.keydownZ()
                        this.audioTimeout = setTimeout(() => this.$refs.video.keyupZ(), 3500)
                    }
                }, 3500)
            }
        },
        getDom(step) {
            const field = GUIDE_STEP_MAP_FIELD[step]
            switch (field) {
                case 'loca':
                case 'dist':
                    return document.getElementsByClassName(`${this.getLocation.id}-${this.getLocation.questions[field].questionID}`)[0]
                case 'size':
                case 'interven':
                    return document.getElementsByClassName(`${field}-${this.getRecord.id}`)[0]
                default:
                    return null
            }
        },
        highlight(step) {
            const dom = this.getDom(step)
            if (dom) {
                dom.classList.add('--highlight')
                setTimeout(() => dom.classList.remove('--highlight'), 4000);
            }
        },
        isValueUpdated(field, value) {
            switch (field) {
                case 'loca':
                case 'dist':
                    return this.getLocation.questions[field].value === value
                case 'size':
                    return this.getRecord.questions.size.value === value
                case 'interven':
                    return this.getIntervenValue === value
                case 'finding':
                    return true
                default:
                    return false
            }
        },
        nextStep(field, value) {
            const steps = field === 'finding' && this.getFinding.questions.finding.value === value
                ? GUIDE_STEPS[value]
                : this.guideSteps
            const [step, ...other] = steps

            if (steps.length) {
                if (steps.includes(FIELD_MAP_GUIDE_STEP[field])) {
                    this.resetGuiding()
                } else if (this.isValueUpdated(field, value)) {
                    this.guideSteps = other || []
                    this.currentGuideStep = step
                    this.guideNotMatchCount = 0
                    this.highlight(step)
                    this.autoRecording(step)
                }
            } else {
                this.currentGuideStep = ''
            }
        },
        resetGuiding() {
            this.guideSteps = []
            this.currentGuideStep = ''
            this.guideNotMatchCount = 0
        },
        guiding(field, value) {
            if (!!this.getUser.useGuiding && this.getFinding && NEED_GUIDE.includes(this.getFinding.questions.finding.value)) {
                this.nextStep(field, value)
            }
        },
        toggleInputValue (newVal, originVal) {
            if (newVal !== originVal) return newVal
            return ''
        },
        handleWriteInputValue (field, value) {
            const writeValueIndex = field === 'finding' && this.writeInputValue.length !== 0 || this.writeInputValue.length === 0 ? this.writeInputValue.length : this.writeInputValue.length - 1
            if (!this.writeInputValue[writeValueIndex]) {
                this.writeInputValue.push({})
            }
            this.writeInputValue[writeValueIndex] = {
                ...this.writeInputValue[writeValueIndex],
                [field]: value
            }
        },
        // 填寫
        async writeInput(table, inputType, data) {
            // table, inputType 用不到, 暫時保留
            // table: finding, record
            // inputType: c, c2, s, t, ta, n
            // this.musicPlay('write');
            if (!this.getStoreHiddenParameter.over) {
                if (['loca', 'orien', 'dist'].indexOf(this.getStoreHiddenParameter.focus) != -1) {
                    const findingIndex = this.getStoreHiddenParameter.nowFindingIndex
                    const recordIndex = this.getStoreHiddenParameter.nowRecordIndex
                    const field = this.getStoreHiddenParameter.focus

                    let value = ['s'].indexOf(inputType) != -1 
                        ? this.toggleInputValue(data, this.getLocation.questions[field].value)
                        : data

                    await this.$store.dispatch('examinations/updateExaminationLocationColumn', {
                        findingIndex: findingIndex,
                        recordIndex: recordIndex,
                        obj: { id: this.getLocation.id, column: field, value }
                    })
                    const writeValueIndex = field === 'finding' && this.writeInputValue.length !== 0 || this.writeInputValue.length === 0 ? this.writeInputValue.length : this.writeInputValue.length - 1
                    if (!this.writeInputValue[writeValueIndex]) {
                        this.writeInputValue.push({})
                    }
                    this.writeInputValue[writeValueIndex] = {
                        ...this.writeInputValue[writeValueIndex],
                        [field]: value
                    }
                } else {
                    // 參考 Gastroscopy.vue 的 saveQuestionInput
                    const finding_fields = ['finding', 'number', 'takeNote'];
                    const findingIndex = this.getStoreHiddenParameter.nowFindingIndex
                    const recordIndex = this.getStoreHiddenParameter.nowRecordIndex
                    const field = this.getStoreHiddenParameter.focus
                    const value = data
                    const d = {
                        examination_typeID: this.examination_typeID,
                        findingIndex,
                        recordIndex,
                        findingID: this.getFindings[findingIndex].id,
                        field,
                        value
                    }

                    if (finding_fields.indexOf(field) == -1) {
                        d.recordID = this.getFindings[findingIndex].records[recordIndex].id
                        if (['c', 'c2'].indexOf(inputType) != -1) {
                            if (this.getFindings[findingIndex].records[recordIndex].questions[field].value.indexOf(value) == -1) {
                                if(this.colonPolypectomyFocus && ['hotSnare', 'coldSnare', 'snare'].indexOf(field) > -1) {
                                    this.getFindings[findingIndex].records[recordIndex].questions[field].value = []
                                }
                                this.getFindings[findingIndex].records[recordIndex].questions[field].value.push(value);
                            } else {
                                this.getFindings[findingIndex].records[recordIndex].questions[field].value.splice(this.getFindings[findingIndex].records[recordIndex].questions[field].value.indexOf(value), 1);
                            }
                            d.value = this.getFindings[findingIndex].records[recordIndex].questions[field].value;
                        }
                        if (['s'].indexOf(inputType) != -1) {
                            d.value = this.toggleInputValue(value, this.getFindings[findingIndex].records[recordIndex].questions[field].value)
                        }
                    } else {
                        d.recordIndex = null;
                        if (['c', 'c2'].indexOf(inputType) != -1) {
                            if (this.getFindings[findingIndex].questions[field].value.indexOf(value) == -1) {
                                this.getFindings[findingIndex].questions[field].value.push(value);
                            } else {
                                this.getFindings[findingIndex].questions[field].value.splice(this.getFindings[findingIndex].questions[field].value.indexOf(value), 1);
                            }
                            d.value = this.getFindings[findingIndex].questions[field].value;
                        }
                        if (['s'].indexOf(inputType) != -1) {
                            d.value = this.toggleInputValue(value, this.getFindings[findingIndex].questions[field].value)
                        }
                    }
                    const writeValueIndex = field === 'finding' && this.writeInputValue.length !== 0 || this.writeInputValue.length === 0 ? this.writeInputValue.length : this.writeInputValue.length - 1
                    if (!this.writeInputValue[writeValueIndex]) {
                        this.writeInputValue.push({})
                    }
                    
                    if (['c', 'c2'].indexOf(inputType) != -1) {
                        const last = this.writeInputValue[writeValueIndex][field] || []
                        this.writeInputValue[writeValueIndex] = {
                            ...this.writeInputValue[writeValueIndex],
                            [field]: [...last, d.value.includes(value) ? value : '' ]
                        }
                    } else {
                        this.writeInputValue[writeValueIndex] = {
                            ...this.writeInputValue[writeValueIndex],
                            [field]: d.value
                        }
                    }
                    let maneuver = ['coldSnare', 'hotSnare', 'snare']
                    if(field && maneuver.indexOf(field) > -1 && this.getFindings[findingIndex] && this.getFindings[findingIndex].records && this.getFindings[findingIndex].records[recordIndex]){
                        let record =  this.getFindings[findingIndex].records[recordIndex]
                        for(let i = 0; i < maneuver.length; i++){
                            if(record.questions[maneuver[i]] && record.questions[maneuver[i]].value) {
                                let o = {
                                    examination_typeID: this.examination_typeID,
                                    findingIndex,
                                    recordIndex,
                                    findingID: this.getFindings[findingIndex].id,
                                    field: record.questions[maneuver[i]].config.field,
                                    recordID: this.getFindings[findingIndex].records[recordIndex].id,
                                    value: [ null ]
                                }
                                this.$store.dispatch('examinations/updateFindingQuestions', o).then(() => {
                                    this.$store.dispatch('examinations/updateFindingQuestions', d).then(() => {
                                        // setTimeout(() => {
                                        //     this.musicPlay('saved');
                                        // }, 1500);
                                        if (finding_fields.indexOf(field) != -1) {
                                            if (this.getRecord) {
                                                this.setHiddenParameter(this.getStoreHiddenParameter.nowFindingIndex, 0);
                                            } else {
                                                this.setHiddenParameter(this.getStoreHiddenParameter.nowFindingIndex, 0);
                                            }
                                        }
                                    });
                                })
                            }
                        }
                    } else {
                        await this.$store.dispatch('examinations/updateFindingQuestions', d).then(() => {
                            if (finding_fields.indexOf(field) != -1) {
                                this.setHiddenParameter(this.getStoreHiddenParameter.nowFindingIndex, 0);
                            }
                        });
                    }
                }

                if (this.getStoreHiddenParameter.focus == 'interven' && Number(data) == 536) {
                    this.setStoreHiddenParameter({ target: 'focus', value: 'intervenOther' });
                }
            }
        },

        // 設定隱藏參數
        setHiddenParameter(nowFindingIndex, nowRecordIndex) {

            if ((nowFindingIndex == null && nowRecordIndex == null) || nowFindingIndex == -1) {
                this.setStoreHiddenParameter({
                    target: 'focus',
                    value: ''
                });
                this.setStoreHiddenParameter({
                    target: 'nowFindingIndex',
                    value: null
                });
                this.setStoreHiddenParameter({
                    target: 'nowRecordIndex',
                    value: null
                });
                this.setStoreHiddenParameter({
                    target: 'nowLocationsIndex',
                    value: null
                });
            } else {
                if (nowFindingIndex != null) {
                    this.setStoreHiddenParameter({
                        target: 'nowFindingIndex',
                        value: nowFindingIndex
                    });
                }

                if (nowRecordIndex != null) {
                    this.setStoreHiddenParameter({
                        target: 'nowRecordIndex',
                        value: nowRecordIndex
                    });
                }

                const location = this.examinationLocations

                if (location) {
                    if (!location[this.getStoreHiddenParameter.nowLocationsIndex]) {
                        this.setStoreHiddenParameter({
                            target: 'nowLocationsIndex',
                            value: 0
                        });
                    }
                } else {
                    this.setStoreHiddenParameter({
                        target: 'nowLocationsIndex',
                        value: null
                    });
                }
            }

        },

        // 取得 HH:ii:ss
        getHHiiss() {
            var x = new Date();
            //var y = x.getFullYear().toString();
            //var m = (x.getMonth() + 1).toString();
            //var d = x.getDate().toString();
            var h = x.getHours().toString();
            var i = x.getMinutes().toString();
            var s = x.getSeconds().toString();
            //(d.length == 1) && (d = '0' + d);
            //(m.length == 1) && (m = '0' + m);
            (h.length == 1) && (h = '0' + h);
            (i.length == 1) && (i = '0' + i);
            (s.length == 1) && (s = '0' + s);
            var HHiiss = h + ':' + i + ':' + s;
            //console.log(HHiiss);
            return HHiiss;
        },

        // 計算時間
        calculatingTime(a, b) {
            var a = a.split(':');
            var b = b.split(':');
            a = Number(a[0]) * 3600 + Number(a[1]) * 60 + Number(a[2]);
            b = Number(b[0]) * 3600 + Number(b[1]) * 60 + Number(b[2]);
            var c = a - b;
            var HHiiss = '00:00:00';
            if (c > 0) {
                var h = parseInt(c / 3600);
                var i = parseInt((c - (h * 3600)) / 60);
                var s = c - (h * 3600) - (i * 60);
                (String(h).length == 1) && (h = '0' + String(h));
                (String(i).length == 1) && (i = '0' + String(i));
                (String(s).length == 1) && (s = '0' + String(s));
                HHiiss = h + ':' + i + ':' + s;
            }
            return HHiiss;
        },
        checkIsFinding (symptom) {
            const colon = this.getQuestionsList[20]
            const gastro = this.getQuestionsList[19]
            const currentFindingList = colon || gastro
            if (!currentFindingList) return false
            const options = Object.values(currentFindingList.options).map(v => v.toLowerCase())
            return options.length ? options.includes(symptom) : false
        },
        checkQuestionType (voiceInput) {
            // common check
            if (this.commonCommand.indexOf(voiceInput.toLowerCase()) >= 0) {
                return {
                    isQuestion: true,
                    type: 'common'
                }
            }

            if(this.actionCommand.indexOf(voiceInput.toLowerCase()) >= 0) {
                return {
                    isQuestion: true,
                    type: 'actionCommand'
                }
            }

            const otherFocus = !this.getStoreHiddenParameter.focus || this.getStoreHiddenParameter.focus === 'finding'
            // findnigs
            if (otherFocus && this.checkIsFinding(voiceInput) || voiceInput.startsWith('finding')) {
                return {
                    isQuestion: true,
                    type: 'finding'
                }
            }
            // record check
            for (let key in this.getQuestionsList) {
                const question = this.getQuestionsList[key].question
                if (question.toLowerCase() == voiceInput) {
                    return {
                        isQuestion: true,
                        type: 'record'
                    }
                }
            }
            return {
                isQuestion: false,
                type: 'value'
            }
        },
        setQuestionFromInput(voiceInput) {
            if (voiceInput === '') return
            let isQuestion = false
            let type = 'value'

            const isSkipLesion = this.getStoreHiddenParameter.focus === 'des' && voiceInput === 'skip-lesion'
            const isFindingNet = (this.getStoreHiddenParameter.focus === 'dev' || this.getStoreHiddenParameter.focus === '') && voiceInput === 'net' 
            const isInsertionTo = this.getStoreHiddenParameter.focus === 'insertionLevel' && voiceInput === 'anastomosis'
            if (!isSkipLesion && !isFindingNet && !isInsertionTo) {
                const {isQuestion: isQuestionStyle, type: questionType} = this.checkQuestionType(voiceInput)
                isQuestion = isQuestionStyle
                type = questionType
            }

            if (isQuestion) {
                this.question = voiceInput
                if (type === 'finding') {
                    this.answerGroup.push({finding: `finding ${voiceInput}`})
                } else if (type === 'common') {
                    const answerIndex = this.answerGroup.length ? this.answerGroup.length - 1 : 0
                    if (!this.answerGroup[answerIndex]) {
                        this.answerGroup.push({})
                    }
                    this.answerGroup[answerIndex] = {
                        ...this.answerGroup[answerIndex],
                        [voiceInput]: 'common'
                    }
                } else if (type === 'actionCommand') {
                    const addActionCommand = () => this.actionCommandArr.push(this.getStoreHiddenParameter.focus)
                    switch (this.getStoreHiddenParameter.focus) {
                        case 'clo':
                        case 'cloLoca':
                            if (this.examination_typeID === 1) {
                                addActionCommand()
                            }
                            break;
                        case 'indication':
                        case 'complications':
                        case 'cleansing level':
                            if (this.examination_typeID === 2) {
                                addActionCommand()
                            }
                            break;
                        default:
                            addActionCommand()
                            break;
                    }
                   
                } else {
                    // console.group('isQuestion', type)
                    // console.log(112, type)
                }
            } else {
                const generateQuestion = () => {
                    // c, c2, s, t, ta, n
                    const optionsTypes = ['s', 'c', 'c2']
                    let currentFocus = this.getStoreHiddenParameter.focus
                    const RECORD_ITEM = this.getRecord && currentFocus ? this.getRecord.questions[currentFocus] : ''
                    const questionKey = RECORD_ITEM 
                        ? RECORD_ITEM.config?.question || CATEGORY_MAP[RECORD_ITEM.category] || this.question
                        : this.question
                    return {
                        inputType: RECORD_ITEM && RECORD_ITEM.config.inputType,
                        currentFocus,
                        questionKey: questionKey.toLowerCase(), 
                        options: RECORD_ITEM && RECORD_ITEM.config && optionsTypes.includes(RECORD_ITEM.config.inputType)
                            ? Object.values(RECORD_ITEM.config.options).map(v => v.toLowerCase())
                            : [] 
                    }
                }
                const { questionKey, options, currentFocus, inputType } = generateQuestion()
                const answerIndex = this.answerGroup.length ? this.answerGroup.length - 1 : 0

                if (questionKey && currentFocus) {
                    if (!options.length) {
                        this.answerGroup[answerIndex] = {
                            ...this.answerGroup[answerIndex],
                            [currentFocus]: `${questionKey} ${voiceInput}`
                        }
                    } else {
                        const isExist = options.includes(voiceInput)
                        if (isExist) {
                            if (['c', 'c2'].indexOf(inputType) != -1) {
                                const last = this.answerGroup[answerIndex] && this.answerGroup[answerIndex][currentFocus] || []
                                const handleAnswer = questionKey === '未處置原因' ? voiceInput : `${questionKey} ${voiceInput}`
                                this.answerGroup[answerIndex] = {
                                    ...this.answerGroup[answerIndex],
                                    [currentFocus]: [...last, handleAnswer ]
                                }
                            } else {
                                this.answerGroup[answerIndex] = {
                                    ...this.answerGroup[answerIndex],
                                    [currentFocus]: `${questionKey} ${voiceInput}`
                                }
                            }        
                        }
                    }
                    this.question = ''
                }
            }
        },
        
        getFocus(voice, voices) {
            if (voice === 'findingNet') {
                return 'finding'
            }
            // finding 優先權最高
            if (
                !['ro', 'ulcerStage', 'des', 'causes'].includes(this.getStoreHiddenParameter.focus) &&
                !['insertion to', 'location'].includes(voices[0]) &&
                (MapUIforFinding["Gastroscopy"][voice] || MapUIforFinding["Colonoscopy"][voice] || MapUIforFinding["Sigmoidoscopy"][voice])
            ) {
                return 'finding'
            }
            return this.getStoreHiddenParameter.focus
        },

        updateTimer({ step, audio, unfinish = false }) {
            this.setTimeCountingStep(step)
            const now = this.getHHiiss()
            switch (step) {
                case 1: // EVAS GO
                    if (unfinish) {
                        this.setExaminationColumn('time_start', this.time_start)
                    } else {
                        this.setExaminationColumn('time_start', now)
                    }
                    this.setExaminationColumn('time_insertion_level', NO_TIME)
                    this.setExaminationColumn('time_landmark_splenic_flexure', NO_TIME)
                    this.setExaminationColumn('time_finished', NO_TIME)

                    this.setExaminationColumn('insertionTime', NO_TIME)
                    this.setExaminationColumn('proximalColonWithTime', NO_TIME)
                    this.setExaminationColumn('withdrawTime', NO_TIME)
                    this.setExaminationColumn('totalExamTime', NO_TIME)
                    break
                case 2: // INSERTION TO
                    if (unfinish) {
                        this.setExaminationColumn('time_insertion_level', this.time_insertion_level)
                        this.setExaminationColumn('withdrawTime', NO_TIME)
                        this.setExaminationColumn('totalExamTime', NO_TIME)
                    } else {
                        this.setExaminationColumn('time_insertion_level', now)
                        this.setExaminationColumn('insertionTime', this.calculatingTime(now, this.time_start));
                    }
                    break
                case 3: // WITHDRAWAL TO
                   if (unfinish) {
                        this.setExaminationColumn('time_landmark_splenic_flexure', this.time_landmark_splenic_flexure)
                        this.setExaminationColumn('withdrawTime', NO_TIME)
                        this.setExaminationColumn('totalExamTime', NO_TIME)
                   } else {
                       this.setExaminationColumn('time_landmark_splenic_flexure', now)
                        if (this.time_insertion_level !== NO_TIME) {
                            this.setExaminationColumn('proximalColonWithTime', this.calculatingTime(now, this.time_insertion_level))
                        }
                   }
                    break
                case 4: // FINISH
                    this.setExaminationColumn('time_finished', now)
                    this.setExaminationColumn('totalExamTime', this.calculatingTime(now, this.time_start))
                    if (this.time_insertion_level !== NO_TIME) {
                        this.setExaminationColumn('withdrawTime', this.calculatingTime(now, this.time_insertion_level))
                    }
                    break
                default:
                    break;
            }
            if (audio) {
                setTimeout(() => this.musicPlay(audio), 500)
            }
        },

        handleEvasgoWarning(status) {
            this.showEvasgoWarning = status
        },

        resetTimerFlags() {
            this.isEvasGo = true
            this.isInsertionTo = false
            this.isProximalColon = false
            this.isFinish = false
        },

        handleEvasGo() {
            if (!this.isEvasGo) {
                this.resetTimerFlags()
                this.handleEvasgoWarning(false)
                this.setTimeCounting(true)
                this.updateTimer({ step: 1, audio: 'evasgo' })
            }
        },

        // 語音指令
        voiceCommand(voiceInput, voiceInputArray = []) {
            console.log(voiceInput, voiceInputArray);
            return new Promise(async(resolve) => {
              let checkInt;
                //==========================================================================
                //※ 變數
                //==========================================================================
                var option = [];
                // ====
                // if (this.getFocus(voiceInput) === 'cloLoca') {
                //     this.$emit('examCommand', { state: 'cloLoca', value: voiceInput })
                //     return
                // }
                switch (voiceInput) {
                    //==========================================================================
                    //※ 第一層 通用指令
                    //==========================================================================
                    // case "cancel":
                    //     this.$emit('cancel')
                    //     resolve();
                    //     break;
                    case "hey evas":
                    case "hi evas":
                    case "evas stop":
                    case "evas over":
                        resolve()
                        break;
                    case "clo":
                        if (this.examination_typeID === 1) {
                            this.setStoreHiddenParameter({
                                target: 'focus',
                                value: 'clo'
                            });
                            this.$emit('examCommand', { state: 'clo'})
                        }
                        resolve()
                        break;
                    case "clo location":
                    case "test site":
                        if (this.examination_typeID === 1) {
                            this.setStoreHiddenParameter({
                                target: 'focus',
                                value: 'cloLoca'
                            });
                        }
                        resolve()
                        break;
                    case "delete finding": // 移除 Finding
                        if (this.getFindings[this.getStoreHiddenParameter.nowFindingIndex] && !this.getStoreHiddenParameter.over) {
                            var findingID = this.getFindings[this.getStoreHiddenParameter.nowFindingIndex].id;
                            this.$store.dispatch('examinations/deleteFinding', {
                                findingID,
                                findingIndex: this.getStoreHiddenParameter.nowFindingIndex
                            }).then(() => {
                                // this.musicPlay('saved');
                                this.setHiddenParameter((this.getExaminationData.findings.length - 1), 0);
                                resolve();
                            });
                        } else {
                            resolve();
                        }
                        break;
                    case "finding over": // 結束 Finding
                        // 設定隱藏參數
                        this.setStoreHiddenParameter({
                            target: 'over',
                            value: true
                        });
                        this.setStoreHiddenParameter({
                            target: 'focus',
                            value: ''
                        });
                        resolve();
                        break;
                    case "open form": // 開啟麻醉清腸表單             // NOT SURE
                        this.setExaminationFinishDialog(true);
                        resolve();
                        break;
                    case "continue": // 關閉麻醉清腸表單
                        this.setExaminationFinishDialog(false);
                        resolve();
                        break;
                    case "back to the list": // 檢查結束
                        // 更改檢查狀態 -> 已檢查
                        this.setExaminationColumn('status', 2);
                        resolve();

                        // 轉跳到檢查清單
                        this.$router.push({
                            path: `/home`
                        });
                        break;
                    case "observation": // 切換 observation
                        this.setStoreHiddenParameter({
                            target: 'focus',
                            value: 'finding',
                            part: 'd'
                        });
                        resolve();
                        break;
                    case "lesion": // 切換 lesion
                        if (!this.getStoreHiddenParameter.over) {
                            this.setStoreHiddenParameter({
                                target: 'focus',
                                value: 'switchLesion'
                            });
                            // 等下一個 for 迴圈 -> 第三層 value: 1 ~ n 轉換 (value - 1)
                        }
                        resolve();
                        break;
                    case "evas go": // 紀錄時間
                        if (this.isCounted && !this.isEvasGo && this.examination_typeID !== 1) {
                            this.handleEvasgoWarning(true)
                        } else {
                            this.handleEvasGo()
                        }
                        resolve()
                        break;
                    case "photo-change":
                        this.$store.dispatch("examinations/fetchOtherList", {
                            examinationID: this.examinationID,
                            accNo: this.accNo,
                        });
                        break;
                    case "insertion to": // 紀錄時間
                        if (this.isEvasGo && !this.isProximalColon) {
                            this.isInsertionTo = true
                            this.setStoreHiddenParameter({ target: 'focus', value: 'insertionLevel' })
                            this.updateTimer({ step: 2, audio: 'insertion2cecum' })
                        }
                        resolve()
                        break;
                    case "proximal time": // 紀錄時間
                        if (this.isEvasGo && this.isInsertionTo && !this.isProximalColon) {
                            this.isProximalColon = true
                            this.updateTimer({ step: 3 })
                        }
                        resolve()
                        break;
                    case "finish": // 紀錄時間
                        if (this.isEvasGo || (!this.isEvasGo && this.examStatus !== '0')) {
                            this.isFinish = true
                            this.isEvasGo = false
                            this.setTimeFinish(true);
                            // TODO: fix async
                            this.updateTimer({ step: 4, audio: 'finish' })
                            this.setExaminationColumn('status', 2);
                            this.showModal = true
                            // this.sendReport()
                        }
                        resolve()
                        break;
                    case "unfinish": // 紀錄時間
                        if (!this.isEvasGo && this.isFinish) {
                            this.isEvasGo = true
                            this.isFinish = false
                            await this.updateTimer({ step: this.getStoreHiddenParameter.prevTimeCountingStep, unfinish: true })
                            this.setExaminationColumn('status', 1);
                        }
                        resolve()
                        break;
                    case "preparation agent": // 麻醉清腸表單 清腸藥物 clean_medID
                        this.setStoreHiddenParameter({
                            target: 'focus',
                            value: 'clean_medID'
                        });
                        // 填寫的部分等下一個 for 迴圈 -> 第三層 this.setExaminationColumn('clean_medID', this.value);
                        resolve();
                        break;
                    case "indication":     
                        this.setStoreHiddenParameter({
                            target: 'focus',
                            value: 'indication'
                        });
                        this.$emit('examCommand', {state: 'indication', value: 'open'})
                        this.setStoreHiddenParameter({
                            target: 'over',
                            value: true
                        });
                        // 填寫的部分等下一個 for 迴圈 -> 第三層 this.setExaminationColumn('indication', this.value);
                        resolve();
                        break;
                    case "complication":
                    case "complications":
                        this.setStoreHiddenParameter({
                            target: 'focus',
                            value: 'complications'
                        });

                        resolve();
                    break;
                    case "cleansing level": // 麻醉清腸表單 cleanScale
                        this.setStoreHiddenParameter({
                            target: 'focus',
                            value: 'cleanScale'
                        });
                        // 填寫的部分等下一個 for 迴圈 -> 第三層 this.setExaminationColumn('cleanScale', this.value);
                        resolve();
                        break;
                    case "preparation": // 胃腸清空程度
                        this.setStoreHiddenParameter({
                            target: 'focus',
                            value: 'preparation'
                        });
                        // 填寫的部分等下一個 for 迴圈 -> 第三層 this.setExaminationColumn('preparation', this.value);
                        resolve();
                        break;
                    case "incomplete study due to": // 麻醉清腸表單 incompleteDue
                        this.setStoreHiddenParameter({
                            target: 'focus',
                            value: 'incompleteDue'
                        });
                        // 填寫的部分等下一個 for 迴圈 -> 第三層 this.setExaminationColumn('incompleteDue', this.value);
                        resolve();
                        break;
                    case "add sedatives": // 新增麻醉藥物
                        // 新增一筆 sedatives
                        this.$store.dispatch('examinations/addExaminationSedative', {
                            examinationID: this.examinationID
                        }).then(() => {
                            this.musicPlay('saved');
                            // 設定 nowSedativesIndex
                            this.setStoreHiddenParameter({
                                target: 'nowSedativesIndex',
                                value: (this.examinationSedatives.length - 1)
                            });
                            // 設定 focus
                            this.setStoreHiddenParameter({
                                target: 'focus',
                                value: 'sedative'
                            });
                            // 填寫的部分等下一個 for 迴圈 -> 第三層 看是藥物還是劑量(※判斷是否為數字), 依據 nowSedativesIndex 填寫
                            resolve();
                        });
                        break;
                    case "switch to sedatives": // 切換麻醉藥物
                        this.setStoreHiddenParameter({
                            target: 'focus',
                            value: 'switchSedative'
                        });
                        // 等下一個 for 迴圈 -> 第三層 value: 1 ~ n 轉換 (value - 1), 設定 nowSedativesIndex, 設定 focus
                        resolve();
                        break;
                    case "delete sedatives": // 刪除麻醉藥物
                        if (this.examinationSedatives[this.getStoreHiddenParameter.nowSedativesIndex]) {
                            // 依據 nowSedativesIndex 刪除麻醉藥物
                            this.$store.dispatch('examinations/deleteExaminationSedative', {
                                examinationSedativeID: this.examinationSedatives[this.getStoreHiddenParameter.nowSedativesIndex].id
                            }).then(() => {
                                this.musicPlay('saved');
                                // 設定 nowSedativesIndex
                                this.setStoreHiddenParameter({
                                    target: 'nowSedativesIndex',
                                    value: (this.examinationSedatives.length - 1) != -1 ? (this.examinationSedatives.length - 1) : null
                                });
                                resolve();
                            });
                        } else {
                            resolve();
                        }
                        break;
                    case "new location": // 新增 location
                        if (!this.getStoreHiddenParameter.over) {
                            if (this.getRecord) {
                                // 新增一筆 locations
                                this.$store.dispatch('examinations/addExaminationLocation', {
                                    recordID: this.getRecord.id,
                                    findingIndex: this.getStoreHiddenParameter.nowFindingIndex
                                }).then(() => {
                                    // this.musicPlay('saved');
                                    // 設定 nowLocationsIndex
                                    this.setStoreHiddenParameter({
                                        target: 'nowLocationsIndex',
                                        value: (this.examinationLocations.length - 1)
                                    });
                                    // 設定 focus
                                    this.setStoreHiddenParameter({
                                        target: 'focus',
                                        value: 'loca'
                                    });
                                    // 填寫的部分等下一個 for 迴圈 -> 第三層 看是哪個 location, orientation 依據 nowLocationsIndex 填寫
                                    resolve();
                                });
                            }
                        } else {
                            resolve();
                        }
                        break;
                    case "delete location": // 刪除 location
                        if (this.examinationLocations.length > 1 && this.getLocation && !this.getStoreHiddenParameter.over) {
                            // 依據 nowLocationsIndex 刪除 location
                            this.$store.dispatch('examinations/deleteExaminationLocation', {
                                locationID: this.getLocation.id,
                                recordIndex: this.getStoreHiddenParameter.nowRecordIndex,
                                findingIndex: this.getStoreHiddenParameter.nowFindingIndex
                            }).then(() => {
                                this.musicPlay('saved');
                                // 設定 nowLocationsIndex
                                this.setStoreHiddenParameter({
                                    target: 'nowLocationsIndex',
                                    value: (this.examinationLocations.length - 1) != -1 ? (this.examinationLocations.length - 1) : null
                                });
                                resolve();
                            });
                        } else {
                            resolve();
                        }
                        break;
                    default:
                        //==========================================================================
                        //※ 第二層 指定欄位
                        //==========================================================================
                        console.table({voiceInput, focus: this.getFocus(voiceInput, voiceInputArray)})
                        var findingIndex = this.getStoreHiddenParameter.nowFindingIndex
                        var recordIndex = this.getStoreHiddenParameter.nowRecordIndex
                        switch (voiceInput) {
                            case /nil [a-f, z]/.test(voiceInput) && voiceInput:
                                this.checkInputExistAndSetFocuse("record", "nil", "i")
                                if (Number(this.examination_typeID) == 2) {
                                    const obj = {
                                        "nil a": 654,
                                        "nil b": 655,
                                        "nil c": 656,
                                        "nil d": 657,
                                        "nil e": 658,
                                        "nil f": 659,
                                        "nil z": 660
                                    }
                                    if (obj[voiceInput]) {
                                        await this.writeInput("nil", "c", obj[voiceInput]);
                                    }
                                }
                                resolve();
                                break;
                            case "active bleeding":
                                // active bleeding (no.1) (yes, no)
                                switch (this.getStoreHiddenParameter.focus) {
                                    case "bleTen":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "c", 179);
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([34, 575, 576, 577, 648].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "c", 179);
                                                }
                                                break;
                                        }
                                        break;
                                    case "des":
                                        switch (Number(this.examination_typeID)) {
                                            case 1:
                                                if ([22].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "c", 235);
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([21, 22, 551, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 552, 48, 579, 580, 581, 582, 583, 584, 585, 638, 639, 641, 644, 645, 649, 662, 663].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "c", 235);
                                                }
                                                break;
                                        }
                                        break;
                                    case "finding":
                                        switch (Number(this.examination_typeID)) {
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                await this.addNewFinding("586")
                                                break;                                                                              
                                        }
                                        break;
                                    default:
                                        if (this.checkInputExistAndSetFocuse("record", "actBle", "d")) {
                                            option = ["yes", "no"];
                                            this.showOptionTip(option);
                                        }
                                        switch (Number(this.examination_typeID)) {
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                this.setStoreHiddenParameter({ target: 'focus', value: 'finding' })
                                                await this.addNewFinding("586")
                                                break;                                                                              
                                        }
                                }
                                resolve();
                                break;
                            case "bleeding tendency":
                                // bleeding tendency (no.1) (recent bleeding, blood oozing, active bleeding)
                                // bleeding tendency (no.2) (active bleeding, nipple sign)
                                if (this.checkInputExistAndSetFocuse("record", "bleTen", "d")) {
                                    switch (Number(this.examination_typeID)) {
                                        case 1: // Gastroscopy
                                            if ([5, 6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // bleeding tendency (no.2)
                                                option = ["active bleeding", "nipple sign"];
                                            }
                                            break;
                                        case 2: // Colonoscopy
                                        case 3: // copy from case = '2' (Colonoscopy)
                                            if ([34, 575, 576, 577, 648].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // bleeding tendency (no.1)
                                                option = ["recent bleeding", "blood oozing", "active bleeding"];
                                            }
                                            break;                                           
                                    }
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "borrmann":
                                // Borrmann (no.1) (1, 2, 3, 4)
                                if (this.checkInputExistAndSetFocuse("record", "borm", "d")) {
                                    option = ["1", "2", "3", "4"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "chromo and magnify":
                                // chromo and magnify (no.1) (acetic acid, NBI, BLI, Lugol's solution, indigo carmine, magnification)
                                if (this.checkInputExistAndSetFocuse("record", "chroMag", "d")) {
                                    option = ["acetic acid", "NBI", "BLI", "Lugol's solution", "indigo carmine", "magnification"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "chromo sign":
                                // chromo sign (no.1) (silver sign, pink-color sign, brownish color, light color, lugol-voiding)
                                if (this.checkInputExistAndSetFocuse("record", "chroSign", "d")) {
                                    option = ["silver sign", "pink-color sign", "brownish color", "light color", "lugol-voiding"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "color":
                                // color (no.1) (white, blue)
                                if (this.checkInputExistAndSetFocuse("record", "color", "d")) {
                                    option = ["white", "blue"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "consult surgeon":
                                // consult surgeon (no.1) (yes, no)
                                // consult surgeon (no.2) (yes, no)
                                if (this.checkInputExistAndSetFocuse("record", "conSur", "i")) {
                                    switch (Number(this.examination_typeID)) {
                                        case 1: // Gastroscopy
                                            switch (Number(this.getFinding.questions.finding.value)) {
                                                case 5:
                                                    if ([519, 520, 521].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 6:
                                                    if ([522, 523].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 1:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 2:
                                                    if ([53, 61, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 4:
                                                    if ([53, 60, 61, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 7:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 8:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 9:
                                                    if ([53, 59, 60, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 10:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 11:
                                                    if ([53, 58, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 12:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 14:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 15:
                                                    if ([53, 60, 61, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 16:
                                                    if ([53, 58, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 39:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 40:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 41:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 42:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 43:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 45:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 46:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 47:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 565:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 566:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 567:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 568:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 569:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 570:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 571:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 572:
                                                    if ([53, 59, 60, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 573:
                                                    if ([53, 59, 60, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 540:
                                                    if ([53, 59, 60, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 574:
                                                    if ([53, 59, 60, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                            }
                                            break;
                                        case 2: // Colonoscopy
                                        case 3: // copy from case = '2' (Colonoscopy)
                                            if ([51].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // consult surgeon (no.1)
                                                option = ["yes", "no"];
                                            }
                                            switch (Number(this.getFinding.questions.finding.value)) {
                                                case 34:
                                                case 648:
                                                    if ([524, 525, 526].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 9:
                                                case 651:
                                                case 661:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 17:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 18:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 19:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 20:
                                                case 664:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 21:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 22:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 23:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 24:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 25:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 26:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 27:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 28:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 29:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 30:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 31:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 32:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 33:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 38:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 10:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 39:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 40:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 41:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 42:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 43:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 44:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 45:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 46:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 47:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 50:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 52:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 57:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 540:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 541:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 542:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 543:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 544:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 545:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 546:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 547:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 549:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 550:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 551:
                                                case 662:    
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 552:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 553:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 558:
                                                    if ([53, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 560:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 35:
                                                    if ([58, 65].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 36:
                                                    if ([58, 65].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 48:
                                                    if ([58].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 561:
                                                    if ([58].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 548:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 37:
                                                    if ([58, 65].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 564:
                                                    if ([58, 65].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 575:
                                                    if ([524, 525, 526].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 576:
                                                    if ([524, 525, 526].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 577:
                                                    if ([524, 525, 526].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 578:
                                                    if ([58, 65].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 579:
                                                    if ([58].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 580:
                                                    if ([58].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 581:
                                                    if ([58].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 582:
                                                    if ([58].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 583:
                                                    if ([58].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 584:
                                                    if ([58].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 585:
                                                    if ([58].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 586:
                                                    if ([58].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                    break;
                                                case 638:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                break;
                                                case 639:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                break;
                                                case 640:
                                                    if ([58].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                break;
                                                case 641:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                break;
                                                case 642:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                break;
                                                case 643:
                                                    if ([53, 59, 60, 61, 71, 73, 74, 75, 76].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                break;
                                                case 644:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                break;
                                                case 645:
                                                    if ([53, 58, 71, 73].indexOf(Number(this.getIntervenValue)) != -1) { // consult surgeon (no.2)
                                                        option = ["yes", "no"];
                                                    }
                                                break;
                                                
                                            }
                                            break;                                            
                                    }
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "demarcation line":
                                // demarcation line (no.1) (present, absent)
                                if (this.checkInputExistAndSetFocuse("record", "demarc", "d")) {
                                    option = ["present", "absent"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "description":
                                // description (no.1) (edema, erythema, friability, exudate, flat erosion, raised erosion, nodularity, rugal hypertrophy, gastric fold hypertrophy, rugal atrophy, gastric mucosa atrophy, visibility of vascular pattern, intramural bleeding spots, intestinal metaplasia, diffuse redness, erosions)
                                // description (no.2) (elastic, soft, hard, pillow sign, yellow, ulcerative)
                                // description (no.3) (erythema, friability, exudate, tiny, small, giant, shallow, broad, spotted, scattered, confluent, focal, continuous-lesion, skip-lesion, blood oozing, active bleeding, mucus plaque, swelling mucosa, partial obstruction, nearly complete obstruction, complete obstruction, ischemia, necrosis, inflammation, overlying membrane, abscess, recent bleeding)
                                // description (no.4) (erythema, friability, exudate, blood oozing, active bleeding, mucus plaque, swelling mucosa, partial obstruction, nearly complete obstruction, complete obstruction, ischemia, necrosis, inflammation, overlying membrane, abscess, recent bleeding)
                                // description (no.5) (active bleeding, hematin, tarry stool, fresh blood)
                                // description (no.6) (description)
                                // description (no.7) (active bleeding, tarry stool, fresh blood)
                                // description (no.8) (active bleeding, hematin, fresh blood)
                                // description (no.9) (active bleeding, hematin, tarry stool)
                                // description (no.10) (hematin, tarry stool, fresh blood)
                                if (this.checkInputExistAndSetFocuse("record", "des", "d")) {
                                    switch (Number(this.examination_typeID)) {
                                        case 1: // Gastroscopy
                                            if ([8, 566, 567, 568, 569, 570, 571].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.1)
                                                option = ["edema", "erythema", "friability", "exudate", "flat erosion", "raised erosion", "nodularity", "rugal hypertrophy", "gastric fold hypertrophy", "rugal atrophy", "gastric mucosa atrophy", "visibility of vascular pattern", "intramural bleeding spots", "intestinal metaplasia", "diffuse redness", "erosions"];
                                            } else if ([10, 39, 40, 41, 42, 43, 45, 46, 47].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.2)
                                                option = ["elastic", "soft", "hard", "pillow sign", "yellow", "ulcerative"];
                                            } else if ([13, 559, 587, 588, 592, 593, 594, 597, 649].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.6)
                                                option = ["description"];
                                            }
                                            break;
                                        case 2: // Colonoscopy
                                        case 3: // copy from case = '2' (Colonoscopy)
                                            if ([10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 553].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.2)
                                                option = ["elastic", "soft", "hard", "pillow sign", "yellow", "ulcerative"];
                                            } else if ([21, 22, 551, 662].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.3)
                                                option = ["erythema", "friability", "exudate", "tiny", "small", "giant", "shallow", "broad", "spotted", "scattered", "confluent", "focal", "continuous-lesion", "skip-lesion", "blood oozing", "active bleeding", "mucus plaque", "swelling mucosa", "partial obstruction", "nearly complete obstruction", "complete obstruction", "ischemia", "necrosis", "inflammation", "overlying membrane", "abscess", "recent bleeding"];
                                            } else if ([23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 552, 638, 639, 641, 644, 645].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.4)
                                                option = ["erythema", "friability", "exudate", "blood oozing", "active bleeding", "mucus plaque", "swelling mucosa", "partial obstruction", "nearly complete obstruction", "complete obstruction", "ischemia", "necrosis", "inflammation", "overlying membrane", "abscess", "recent bleeding"];
                                            } else if ([48, 579, 580, 581, 582].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.5)
                                                option = ["active bleeding", "hematin", "tarry stool", "fresh blood"];
                                            } else if ([554, 555, 556, 557, 558, 559, 560, 561, 587, 588, 595, 596, 594, 597, 640, 646, 647, 649, 650, 663, 665, 666].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.6)
                                                option = ["description"];
                                            } else if ([583].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.7)
                                                option = ["active bleeding", "tarry stool", "fresh blood"];
                                            } else if ([584].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.8)
                                                option = ["active bleeding", "hematin", "fresh blood"];
                                            } else if ([585].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.9)
                                                option = ["active bleeding", "hematin", "tarry stool"];
                                            } else if ([586].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // description (no.10)
                                                option = ["hematin", "tarry stool", "fresh blood"];
                                            }
                                            break;                                            
                                    }
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "maneuver":
                            case "device":
                            case "injection":
                            case "patho":
                                // device (no.1) (removal forceps, pentapod grasping forceps, grasping forceps, alligator, net, snare)
                                this.setStoreHiddenParameter({ target: 'focus', value: voiceInput })
                                resolve();
                                break;
                            case "distance":
                                // distance (no.1) (cm)
                                if (this.checkInputExistAndSetFocuse("record", "dist", "d")) {
                                    option = ["cm"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "early or advanced":
                                // early or advanced (no.1) (early, advanced)
                                if (this.checkInputExistAndSetFocuse("record", "earlyAdv", "d")) {
                                    option = ["early", "advanced"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "finding":
                                // finding (no.1) (GERD, Barrett's esophagus, hiatal hernia, esophageal CA, esophageal varices, gastric varices, GU, gastritis, polyp, subepithelial tumor, gastric CA, DU, bulbitis, brunner's gland hyperplasia, esophageal dysplasia, gastric dysplasia, lipoma, carcinoid tumor, GIST, metastatic tumor, extraluminal impression, NET, lymphoma, hemangioma, hyperplastic polyp, operation scar, reflux esophagitis, acute gastritis, hemorrhagic gastritis, chronic gastritis, superficial gastritis, atrophic gastritis, portal hypertensive gastropathy, fundic gland polyp, adenomatous polyp, FAP, scar, anastomosis, total gastrectomy, subtotal gastrectomy, stich, stitches)
                                // finding (no.2) (polyp, subepithelial tumor, tumor, lateral spreading tumor, flat tumor, cancer, ulcer, erosion, colitis, IBD, ulcerative colitis, Crohn's disease, pseudomembranous colitis, ischemic colitis, infectious colitis, TB colitis, amoebic colitis, radiation colitis, proctitis, hemorrhoid, diverticulum, diverticula, diverticulosis, xanthoma, lipoma, carcinoid tumor, GIST, metastatic tumor, extraluminal impression, appendiceal tumor, NET, lymphoma, hemangioma, GI bleeding, melanosis coli, ileitis, foreign body, lymphoid hyperplasia, polypoid tumor, hyperplastic polyp, Peutz-Jeghers syndrome, Juvenile polyp, tubular adenoma, tubulovillous adenoma, villous adenoma, sessile serrated adenoma, traditional serrated adenoma, inflammatory adenoma, early colorectal cancer, advanced colorectal cancer, TB related lesion, non-specific colitis, lymphangioma, bechet's disease, s/p partial colectomy, situs inversus, colonic wall cyst, lymphoid follicles, operation scar, suture granuloma, petechia, diverticulitis, internal hemorrhoid, external hemorrhoid, mixed hemorrhoid, diverticular bleeding, upper GI bleeding, lower GI bleeding, occult bleeding, unknown bleeder, hematin, tarry stool, fresh blood, active bleeding, scar, anastomosis, stich, right hemicolectomy, left hemicolectomy, stitches)
                                if (this.checkInputExistAndSetFocuse("finding", "finding", "f")) {
                                    switch (Number(this.examination_typeID)) {
                                        case 1: // Gastroscopy
                                            option = ["GERD", "Barrett's esophagus", "hiatal hernia", "esophageal CA", "esophageal varices", "gastric varices", "GU", "gastritis", "polyp", "subepithelial tumor", "gastric CA", "DU", "bulbitis", "brunner's gland hyperplasia", "esophageal dysplasia", "gastric dysplasia", "lipoma", "carcinoid tumor", "GIST", "metastatic tumor", "extraluminal impression", "NET", "lymphoma", "hemangioma", "hyperplastic polyp", "operation scar", "reflux esophagitis", "acute gastritis", "hemorrhagic gastritis", "chronic gastritis", "superficial gastritis", "atrophic gastritis", "portal hypertensive gastropathy", "fundic gland polyp", "adenomatous polyp", "FAP", "scar", "anastomosis", "total gastrectomy", "subtotal gastrectomy", "stich", "stitches"];
                                            break;
                                        case 2: // Colonoscopy
                                        case 3: // Sigmoidoscopy
                                            option = ["polyp", "subepithelial tumor", "tumor", "lateral spreading tumor", "flat tumor", "cancer", "ulcer", "erosion", "colitis", "IBD", "ulcerative colitis", "Crohn's disease", "pseudomembranous colitis", "ischemic colitis", "infectious colitis", "TB colitis", "amoebic colitis", "radiation colitis", "proctitis", "hemorrhoid", "diverticulum", "diverticula", "diverticulosis", "xanthoma", "lipoma", "carcinoid tumor", "GIST", "metastatic tumor", "extraluminal impression", "appendiceal tumor", "NET", "lymphoma", "hemangioma", "GI bleeding", "melanosis coli", "ileitis", "foreign body", "lymphoid hyperplasia", "polypoid tumor", "hyperplastic polyp", "Peutz-Jeghers syndrome", "Juvenile polyp", "tubular adenoma", "tubulovillous adenoma", "villous adenoma", "sessile serrated adenoma", "traditional serrated adenoma", "inflammatory adenoma", "early colorectal cancer", "advanced colorectal cancer", "TB related lesion", "non-specific colitis", "lymphangioma", "bechet's disease", "s/p partial colectomy", "situs inversus", "colonic wall cyst", "lymphoid follicles", "operation scar", "suture granuloma", "petechia", "diverticulitis", "internal hemorrhoid", "external hemorrhoid", "mixed hemorrhoid", "diverticular bleeding", "upper GI bleeding", "lower GI bleeding", "occult bleeding", "unknown bleeder", "hematin", "tarry stool", "fresh blood", "active bleeding", "scar", "anastomosis", "stich", "right hemicolectomy", "left hemicolectomy", "stitches", "GVHD related colitis"];
                                            break;
                                    }
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "form":
                                // form (no.1) (F1, F2, F3)
                                if (this.checkInputExistAndSetFocuse("record", "form", "d")) {
                                    option = ["F1", "F2", "F3"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "forrest":
                                // Forrest (no.1) (Ia, Ib, IIa, IIb, IIc, III)
                                if (this.checkInputExistAndSetFocuse("record", "forrest", "d")) {
                                    option = ["Ia", "Ib", "IIa", "IIb", "IIc", "III"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "grade":
                                // grade (no.1) (I, II, III, IV)
                                if (this.checkInputExistAndSetFocuse("record", "grade", "d")
                                    || this.checkInputExistAndSetFocuse("record", "laGrade", "d")
                                ) {
                                    option = ["I", "II", "III", "IV"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "hill":
                                // Hill (no.1) (I, II, III, IV)
                                if (this.checkInputExistAndSetFocuse("record", "hill", "d")) {
                                    option = ["I", "II", "III", "IV"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "invasion depth":
                                // invasion depth (no.1) (mucosa, submucosa, serosa)
                                if (this.checkInputExistAndSetFocuse("record", "invaDep", "undefined")) {
                                    option = ["mucosa", "submucosa", "serosa"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "jes":
                                // JES (no.1) (a, B1, B2, B3)
                                if (this.checkInputExistAndSetFocuse("record", "jes", "d")) {
                                    option = ["a", "B1", "B2", "B3"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "jnet":
                                // JNET (no.1) (1, 2A, 2B, 3)
                                if (this.checkInputExistAndSetFocuse("record", "jnet", "d")) {
                                    option = ["1", "2A", "2B", "3"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "kudo":
                                // Kudo (no.1) (I, II, IIIs, IIIL, IV, V)
                                if (this.checkInputExistAndSetFocuse("record", "kudo", "d")) {
                                    option = ["I", "II", "IIIs", "IIIL", "IV", "V"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "l.a. grade":
                                // L.A. grade (no.1) (a, b, c, d)
                                if (this.checkInputExistAndSetFocuse("record", "laGrade", "d")) {
                                    option = ["a", "b", "c", "d"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "location":
                                // location (no.1) (cervical, upper, middle, lower, ECJ)
                                // location (no.2) (antrum, low-body, mid-body, high-body, angle, cardia, fundus, body, upper-body)
                                // location (no.3) (antrum, low-body, mid-body, high-body, angle, cardia, fundus, bulb, SDA, 2nd portion, body, upper-body)
                                // location (no.4) (bulb, SDA, 2nd portion, papilla)
                                // location (no.5) (high-body, cardia, fundus, upper-body)
                                // location (no.6) (terminal ileum, ileocecal valve, appendix orifice, cecum, ascending colon, hepatic flexure, transverse colon, splenic flexure, descending colon, sigmoid colon, RS junction, rectum, anus, all segments of colon, anastomosis)
                                // location (no.7) (internal, external, mixed)
                                // location (no.8) (cervical, upper, middle, lower, ECJ, antrum, low-body, mid-body, high-body, angle, cardia, fundus, bulb, SDA, 2nd portion, papilla, body, upper-body)
                                if (this.checkInputExistAndSetFocuse("record", "loca", "d")) {
                                    switch (Number(this.examination_typeID)) {
                                        case 1: // Gastroscopy
                                            if ([4, 5, 15].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // location (no.1)
                                                option = ["cervical", "upper", "middle", "lower", "ECJ"];
                                            } else if ([7, 8, 11, 16, 566, 567, 568, 569, 570, 571].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // location (no.2)
                                                option = ["antrum", "low-body", "mid-body", "high-body", "angle", "cardia", "fundus", "body", "upper-body"];
                                            } else if ([9, 10, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // location (no.3)
                                                option = ["antrum", "low-body", "mid-body", "high-body", "angle", "cardia", "fundus", "bulb", "SDA", "2nd portion", "body", "upper-body"];
                                            } else if ([12, 14].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // location (no.4)
                                                option = ["bulb", "SDA", "2nd portion", "papilla"];
                                            } else if ([6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // location (no.5)
                                                option = ["high-body", "cardia", "fundus", "upper-body"];
                                            } else if ([559, 587, 588, 592, 593, 594, 597].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // location (no.8)
                                                option = ["cervical", "upper", "middle", "lower", "ECJ", "antrum", "low-body", "mid-body", "high-body", "angle", "cardia", "fundus", "bulb", "SDA", "2nd portion", "papilla", "body", "upper-body"];
                                            } else if ([1].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                option = ["esophagus", "ECJ"];
                                            }
                                            break;
                                        case 2: // Colonoscopy
                                        case 3: // copy from case = '2' (Colonoscopy)
                                            if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 554, 557, 558, 559, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 595, 596, 594, 597, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 649, 650, 651, 661, 662, 663, 664, 665, 666].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // location (no.6)
                                                option = ["terminal ileum", "ileocecal valve", "appendix orifice", "cecum", "ascending colon", "hepatic flexure", "transverse colon", "splenic flexure", "descending colon", "sigmoid colon", "RS junction", "rectum", "anus", "all segments of colon", "anastomosis", "anal retroflex"];
                                            } else if ([34, 648].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // location (no.7)
                                                option = ["internal", "external", "mixed"];
                                            }
                                            break;
                                        
                                    }
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "hot_snare":
                            case "hot snare":
                                // hot snare (no.1) (hot snare)
                                    if(this.colonPolypectomyFocus || this.getFindings[findingIndex].records[recordIndex].questions['interven'].value === "59"){
                                    if (this.checkInputExistAndSetFocuse("record", "hotSnare", "i")) {
                                        await this.writeInput("record", "c2", 63);
                                    }
                                }else{
                                    switch (this.getStoreHiddenParameter.focus) {
                                        case "interven":
                                            switch (Number(this.examination_typeID)) {
                                                case 1: // Gastroscopy
                                                    if ([9, 572, 573, 540, 574, 11, 16, 10, 39, 40, 41, 42, 43, 45, 46, 47].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                        await this.writeInput("record", "s", "76");
                                                    }
                                                    break;
                                                case 2: // Colonoscopy
                                                case 3: // copy from case = '2' (Colonoscopy)
                                                    if ([9, 39, 40, 41, 42, 43, 44, 45, 46, 47, 540, 541, 542, 553, 548, 57, 543, 544, 545, 546, 547, 549, 550, 17, 18, 19, 20, 10, 642, 643, 761, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                        await this.writeInput("record", "s", "76");
                                                    }
                                                    break;
                                            }
                                            break;
                                        default:
                                            if (this.checkInputExistAndSetFocuse("record", "hotSnare", "i")) {
                                                await this.writeInput("record", "c2", 63);
                                            }
                                    }
                                }
                                resolve();
                                break;
                            case "emergent":
                                // emergent (no.1) (emergent)
                                if (this.checkInputExistAndSetFocuse("record", "emer", "i")) {
                                    await this.writeInput("record", "c2", 64);
                                }
                                resolve();
                                break;
                            case "mayo score":
                                // Mayo score (no.1) (0, 1, 2, 3)
                                if (this.checkInputExistAndSetFocuse("record", "mayo", "d")) {
                                    option = ["0", "1", "2", "3"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "ipcl":
                                if(this.checkInputExistAndSetFocuse("record", "ipcl", "d")){
                                    option = ["II", "III", "IV", "V1", "V2", "V3", "V4"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "type":
                                if(this.checkInputExistAndSetFocuse("record", "ipcl", "d")){
                                    option = ["II", "III", "IV", "V1", "V2", "V3", "V4"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "morphology":
                                // morphology (no.1) (0, 0-Ip, 0-Is, 0-IIa, 0-IIb, 0-IIc, 0-III, IIa+c, IIc+a, IIc+IIII, LST-G, LST-NG, I-sp)
                                // morphology (no.2) (IIa+c, IIc+a, Ip, Is, IIa, IIb, IIc, IIc+III, III, I-sp)
                                if (this.checkInputExistAndSetFocuse("record", "morph", "d")) {
                                    switch (Number(this.examination_typeID)) {
                                        case 1: // Gastroscopy
                                            if ([4, 15].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // morphology (no.2)
                                                option = ["IIa+c", "IIc+a", "Ip", "Is", "IIa", "IIb", "IIc", "IIc+III", "III", "I-sp"];
                                            }
                                            break;
                                        case 2: // Colonoscopy
                                        case 3: // copy from case = '2' (Colonoscopy)
                                            if ([9, 17, 18, 19, 20, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // morphology (no.1)
                                                option = ["0", "0-Ip", "0-Is", "0-IIa", "0-IIb", "0-IIc", "0-III", "IIa+c", "IIc+a", "IIc+III", "LST-G", "LST-NG", "I-sp"];
                                            }
                                            break;
                                        
                                    }
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "nice":
                                // NICE (no.1) (1, 2, 3)
                                if (this.checkInputExistAndSetFocuse("record", "nice", "d")) {
                                    option = ["1", "2", "3"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "number":
                                // number (no.1) (數字, several, multiple)
                                if (this.checkInputExistAndSetFocuse("finding", "number", "d")) {
                                    option = ["數字", "several", "multiple"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "object":
                                // object (no.1) (object)
                                if (this.checkInputExistAndSetFocuse("record", "object", "d")) {
                                    option = ["object"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "orientation":
                                // orientation (no.2) (proximal, middle, distal, upper, lower)
                                // orientation (no.1) (AW, PW, GC, LC)
                                if (this.checkInputExistAndSetFocuse("record", "orien", "d")) {
                                    switch (Number(this.examination_typeID)) {
                                        case 1: // Gastroscopy
                                            if ([7, 8, 9, 10, 11, 12, 14, 16, 39, 40, 41, 42, 43, 45, 46, 47, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 559, 587, 588, 592, 593, 594, 597].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // orientation (no.1)
                                                option = ["AW", "PW", "GC", "LC"];
                                            } else if ([1].indexOf(Number(this.getFinding.questions.finding.value)) != -1 ) {
                                                option = ["cervical", "upper", "middle", "lower"];
                                            }
                                            break;
                                        case 2: // Colonoscopy
                                        case 3: // Sigmoidoscopy
                                            if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 554, 557, 558, 559, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 595, 596, 594, 597, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 649, 650, 651, 661, 662, 663, 664, 665, 666].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // orientation (no.2)
                                                option = ["proximal", "middle", "distal", "upper", "lower"];
                                            }
                                            break;
                                    }
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "paris":
                                // Paris (no.1) (0-I, 0-IIa, 0-IIb, 0-IIc, 0-III)
                                if (this.checkInputExistAndSetFocuse("record", "paris", "d")) {
                                    option = ["0-I", "0-IIa", "0-IIb", "0-IIc", "0-III"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "prague c":
                                // Prague C (no.1) (cm)
                                if (this.checkInputExistAndSetFocuse("record", "prgC", "d")) {
                                    option = ["cm"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "prague m":
                                // Prague M (no.1) (cm)
                                if (this.checkInputExistAndSetFocuse("record", "prgM", "d")) {
                                    option = ["cm"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "red color sign":
                                // red color sign (no.1) (negative, red whale marking, cherry-red spot)
                                if (this.checkInputExistAndSetFocuse("record", "rcs", "d")) {
                                    option = ["negative", "red whale marking", "cherry-red spot"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "removed":
                                // removed (no.1) (yes, no)
                                if (this.checkInputExistAndSetFocuse("record", "removed", "d")) {
                                    option = ["yes", "no"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "r/o":
                                // r/o (no.1) (early cancer, advanced cancer)
                                // r/o (no.2) (proctitis, malignancy, IBD, ulcerative colitis, Crohn's disease, infectious colitis, TB colitis, amoebic colitis, radiation colitis, ischemic colitis, pseudomembranous colitis)
                                // r/o (no.3) (proctitis, IBD, ulcerative colitis, Crohn's disease, infectious colitis, TB colitis, amoebic colitis, radiation colitis, ischemic colitis, pseudomembranous colitis)
                                // r/o (no.4) (acute gastritis, hemorrhagic gastritis, chronic gastritis, superficial gastritis, atrophic gastritis, portal hypertensive gastropathy)
                                // r/o (no.5) (diverticulosis, diverticulitis, diverticular bleeding)
                                // r/o (no.6) (fundic gland polyp, adenomatous polyp, hyperplastic polyp, gastric CA, FAP)
                                // r/o (no.7) (IBD, ulcerative colitis, Crohn's disease, TB related lesion, amoeba)
                                // r/o (no.8) (lipoma, carcinoid tumor, GIST, metastatic tumor, extraluminal impression, appendiceal tumor, NET, lymphoma, hemangioma, lymphangioma)
                                // r/o (no.9) (lipoma, carcinoid tumor, GIST, metastatic tumor, extraluminal impression, NET, lymphoma, hemangioma)
                                // r/o (no.10) (upper GI, lower GI, occult bleeding, unknown bleeder)
                                // r/o (no.11) (dysplasia, cancer)
                                // r/o (no.12) (diverticulitis, diverticular bleeding)
                                // r/o (no.13) (diverticulosis, diverticular bleeding)
                                // r/o (no.14) (hemorrhagic gastritis, chronic gastritis, superficial gastritis, atrophic gastritis, portal hypertensive gastropathy)
                                // r/o (no.15) (acute gastritis, chronic gastritis, superficial gastritis, atrophic gastritis, portal hypertensive gastropathy)
                                // r/o (no.16) (acute gastritis, hemorrhagic gastritis, superficial gastritis, atrophic gastritis, portal hypertensive gastropathy)
                                // r/o (no.17) (acute gastritis, hemorrhagic gastritis, chronic gastritis, atrophic gastritis, portal hypertensive gastropathy)
                                // r/o (no.18) (acute gastritis, hemorrhagic gastritis, chronic gastritis, superficial gastritis, portal hypertensive gastropathy)
                                // r/o (no.19) (acute gastritis, hemorrhagic gastritis, chronic gastritis, superficial gastritis, atrophic gastritis)
                                // r/o (no.20) (adenomatous polyp, hyperplastic polyp, gastric CA, FAP)
                                // r/o (no.21) (fundic gland polyp, hyperplastic polyp, gastric CA, FAP)
                                // r/o (no.22) (fundic gland polyp, adenomatous polyp, gastric CA, FAP)
                                // r/o (no.23) (fundic gland polyp, adenomatous polyp, hyperplastic polyp, gastric CA)
                                // r/o (no.24) (proctitis, ulcerative colitis, Crohn's disease, infectious colitis, TB colitis, amoebic colitis, radiation colitis, ischemic colitis, pseudomembranous colitis)
                                // r/o (no.25) (proctitis, IBD, Crohn's disease, infectious colitis, TB colitis, amoebic colitis, radiation colitis, ischemic colitis, pseudomembranous colitis)
                                // r/o (no.26) (proctitis, IBD, ulcerative colitis, infectious colitis, TB colitis, amoebic colitis, radiation colitis, ischemic colitis, pseudomembranous colitis)
                                // r/o (no.27) (proctitis, IBD, ulcerative colitis, Crohn's disease, infectious colitis, TB colitis, amoebic colitis, radiation colitis, ischemic colitis)
                                // r/o (no.28) (proctitis, IBD, ulcerative colitis, Crohn's disease, infectious colitis, TB colitis, amoebic colitis, radiation colitis, pseudomembranous colitis)
                                // r/o (no.29) (proctitis, IBD, ulcerative colitis, Crohn's disease, TB colitis, amoebic colitis, radiation colitis, ischemic colitis, pseudomembranous colitis)
                                // r/o (no.30) (proctitis, IBD, ulcerative colitis, Crohn's disease, infectious colitis, amoebic colitis, radiation colitis, ischemic colitis, pseudomembranous colitis)
                                // r/o (no.31) (proctitis, IBD, ulcerative colitis, Crohn's disease, infectious colitis, TB colitis, radiation colitis, ischemic colitis, pseudomembranous colitis)
                                // r/o (no.32) (proctitis, IBD, ulcerative colitis, Crohn's disease, infectious colitis, TB colitis, amoebic colitis, ischemic colitis, pseudomembranous colitis)
                                // r/o (no.33) (IBD, ulcerative colitis, Crohn's disease, infectious colitis, TB colitis, amoebic colitis, radiation colitis, ischemic colitis, pseudomembranous colitis)
                                // r/o (no.34) (diverticulosis)
                                // r/o (no.35) (occult bleeding, unknown bleeder)
                                // r/o (no.36) (upper GI, lower GI, unknown bleeder)
                                // r/o (no.37) (upper GI, lower GI, occult bleeding)
                                if (this.checkInputExistAndSetFocuse("record", "ro", "d")) {
                                    switch (Number(this.examination_typeID)) {
                                        case 1: // Gastroscopy
                                            if ( [1].indexOf( Number(this.getFinding.questions.finding.value) ) != -1 )
                                                option = ["barrett's esophagus", "hiatal hernia"];
                                            else if ([7, 12].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.1)
                                                option = ["early cancer", "advanced cancer"];
                                            } else if ([8].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.4)
                                                option = ["acute gastritis", "hemorrhagic gastritis", "chronic gastritis", "superficial gastritis", "atrophic gastritis", "portal hypertensive gastropathy"];
                                            } else if ([9].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.6)
                                                option = ["fundic gland polyp", "adenomatous polyp", "hyperplastic polyp", "gastric CA", "FAP"];
                                            } else if ([10].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.9)
                                                option = ["lipoma", "carcinoid tumor", "GIST", "metastatic tumor", "extraluminal impression", "NET", "lymphoma", "hemangioma"];
                                            } else if ([2].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.11)
                                                option = ["dysplasia", "cancer"];
                                            } else if ([566].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.14)
                                                option = ["hemorrhagic gastritis", "chronic gastritis", "superficial gastritis", "atrophic gastritis", "portal hypertensive gastropathy"];
                                            } else if ([567].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.15)
                                                option = ["acute gastritis", "chronic gastritis", "superficial gastritis", "atrophic gastritis", "portal hypertensive gastropathy"];
                                            } else if ([568].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.16)
                                                option = ["acute gastritis", "hemorrhagic gastritis", "superficial gastritis", "atrophic gastritis", "portal hypertensive gastropathy"];
                                            } else if ([569].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.17)
                                                option = ["acute gastritis", "hemorrhagic gastritis", "chronic gastritis", "atrophic gastritis", "portal hypertensive gastropathy"];
                                            } else if ([570].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.18)
                                                option = ["acute gastritis", "hemorrhagic gastritis", "chronic gastritis", "superficial gastritis", "portal hypertensive gastropathy"];
                                            } else if ([571].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.19)
                                                option = ["acute gastritis", "hemorrhagic gastritis", "chronic gastritis", "superficial gastritis", "atrophic gastritis"];
                                            } else if ([572].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.20)
                                                option = ["adenomatous polyp", "hyperplastic polyp", "gastric CA", "FAP"];
                                            } else if ([573].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.21)
                                                option = ["fundic gland polyp", "hyperplastic polyp", "gastric CA", "FAP"];
                                            } else if ([540].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.22)
                                                option = ["fundic gland polyp", "adenomatous polyp", "gastric CA", "FAP"];
                                            } else if ([574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.23)
                                                option = ["fundic gland polyp", "adenomatous polyp", "hyperplastic polyp", "gastric CA"];
                                            }
                                            break;
                                        case 2: // Colonoscopy
                                        case 3: // Colonoscopy
                                            if ([21, 22, 551, 662].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.2)
                                                option = ["proctitis", "malignancy", "IBD", "ulcerative colitis", "Crohn's disease", "infectious colitis", "TB colitis", "amoebic colitis", "radiation colitis", "ischemic colitis", "pseudomembranous colitis"];
                                            } else if ([23, 552, 638, 639, 641, 644].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.3)
                                                option = ["proctitis", "IBD", "ulcerative colitis", "Crohn's disease", "infectious colitis", "TB colitis", "amoebic colitis", "radiation colitis", "ischemic colitis", "pseudomembranous colitis", "GVHD related colitis"];
                                            } else if ([35, 36].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.5)
                                                option = ["diverticulosis", "diverticulitis", "diverticular bleeding"];
                                            } else if ([50].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.7)
                                                option = ["IBD", "ulcerative colitis", "Crohn's disease", "TB related lesion", "amoeba"];
                                            } else if ([10].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.8)
                                                option = ["lipoma", "carcinoid tumor", "GIST", "metastatic tumor", "extraluminal impression", "appendiceal tumor", "NET", "lymphoma", "hemangioma", "lymphangioma"];
                                            } else if ([48, 583, 584, 585, 586].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.10)
                                                option = ["upper GI", "lower GI", "occult bleeding", "unknown bleeder"];
                                            } else if ([37].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.12)
                                                option = ["diverticulitis", "diverticular bleeding"];
                                            } else if ([564].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.13)
                                                option = ["diverticulosis", "diverticular bleeding"];
                                            } else if ([24].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.24)
                                                option = ["proctitis", "ulcerative colitis", "Crohn's disease", "infectious colitis", "TB colitis", "amoebic colitis", "radiation colitis", "ischemic colitis", "pseudomembranous colitis"];
                                            } else if ([25].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.25)
                                                option = ["proctitis", "IBD", "Crohn's disease", "infectious colitis", "TB colitis", "amoebic colitis", "radiation colitis", "ischemic colitis", "pseudomembranous colitis"];
                                            } else if ([26, 645].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.26)
                                                option = ["proctitis", "IBD", "ulcerative colitis", "infectious colitis", "TB colitis", "amoebic colitis", "radiation colitis", "ischemic colitis", "pseudomembranous colitis"];
                                            } else if ([27].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.27)
                                                option = ["proctitis", "IBD", "ulcerative colitis", "Crohn's disease", "infectious colitis", "TB colitis", "amoebic colitis", "radiation colitis", "ischemic colitis"];
                                            } else if ([28].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.28)
                                                option = ["proctitis", "IBD", "ulcerative colitis", "Crohn's disease", "infectious colitis", "TB colitis", "amoebic colitis", "radiation colitis", "pseudomembranous colitis"];
                                            } else if ([29].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.29)
                                                option = ["proctitis", "IBD", "ulcerative colitis", "Crohn's disease", "TB colitis", "amoebic colitis", "radiation colitis", "ischemic colitis", "pseudomembranous colitis"];
                                            } else if ([30].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.30)
                                                option = ["proctitis", "IBD", "ulcerative colitis", "Crohn's disease", "infectious colitis", "amoebic colitis", "radiation colitis", "ischemic colitis", "pseudomembranous colitis"];
                                            } else if ([31].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.31)
                                                option = ["proctitis", "IBD", "ulcerative colitis", "Crohn's disease", "infectious colitis", "TB colitis", "radiation colitis", "ischemic colitis", "pseudomembranous colitis"];
                                            } else if ([32].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.32)
                                                option = ["proctitis", "IBD", "ulcerative colitis", "Crohn's disease", "infectious colitis", "TB colitis", "amoebic colitis", "ischemic colitis", "pseudomembranous colitis"];
                                            } else if ([33].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.33)
                                                option = ["IBD", "ulcerative colitis", "Crohn's disease", "infectious colitis", "TB colitis", "amoebic colitis", "radiation colitis", "ischemic colitis", "pseudomembranous colitis"];
                                            } else if ([578].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.34)
                                                option = ["diverticulosis"];
                                            } else if ([579, 580].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.35)
                                                option = ["occult bleeding", "unknown bleeder"];
                                            } else if ([581].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.36)
                                                option = ["upper GI", "lower GI", "unknown bleeder"];
                                            } else if ([582].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // r/o (no.37)
                                                option = ["upper GI", "lower GI", "occult bleeding"];
                                            }
                                            break;
                                    }
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "sign":
                                // sign (no.1) (bleeding, inflammation, bezoar)
                                if (this.checkInputExistAndSetFocuse("record", "sign", "d")) {
                                    option = ["bleeding", "inflammation", "bezoar"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "size":
                                // size (no.1) (cm)
                                if (this.checkInputExistAndSetFocuse("record", "size", "d")) {
                                    option = ["cm"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "skip-lesion":
                                // skip-lesion (no.1) (yes, no)
                                switch (this.getStoreHiddenParameter.focus) {
                                    case "des":
                                        switch (Number(this.examination_typeID)) {
                                            case 1:
                                                if ([22].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "c", 233);
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([21, 22, 551, 662].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "c", 233);
                                                }
                                                break;                
                                        }
                                        break;
                                    default:
                                        if (this.checkInputExistAndSetFocuse("record", "skipLesion", "d")) {
                                            option = ["yes", "no"];
                                            this.showOptionTip(option);
                                        }
                                }
                                resolve();
                                break;
                            case "sessile or pedunculated":
                                // sessile or pedunculated (no.1) (sessile, pedunculated)
                                if (this.checkInputExistAndSetFocuse("record", "sorP", "d")) {
                                    option = ["sessile", "pedunculated"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "s pattern":
                                // S pattern (no.1) (regular, irregular, absent)
                                if (this.checkInputExistAndSetFocuse("record", "sPat", "d")) {
                                    option = ["regular", "irregular", "absent"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "take notes of":
                                // take notes of (no.1) (the most significant, each, overall)
                                if (this.checkInputExistAndSetFocuse("finding", "takeNote", "d")) {
                                    option = ["the most significant", "each", "overall"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "ulcer stage":
                                // ulcer stage (no.1) (active, healing, scar, A1, A2, H1, H2, S1, S2)
                                if (this.checkInputExistAndSetFocuse("record", "ulcerStage", "d")) {
                                    option = ["active", "healing", "scar", "A1", "A2", "H1", "H2", "S1", "S2"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "v pattern":
                                // V pattern (no.1) (regular, irregular, absent)
                                if (this.checkInputExistAndSetFocuse("record", "vPat", "d")) {
                                    option = ["regular", "irregular", "absent"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "alligator":
                                // alligator (no.1) (alligator)
                                switch (this.getStoreHiddenParameter.focus) {
                                    case "device":
                                        if ([51].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                            this.setStoreHiddenParameter({ target: 'focus', value: 'dev' })
                                            await this.writeInput("record", "c", 253);
                                        } else if ([640, 646].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                            this.setStoreHiddenParameter({ target: 'focus', value: 'alligator' })
                                            await this.writeInput("record", "c", 148);
                                        }
                                        break;
                                    default:
                                        if (this.checkInputExistAndSetFocuse("record", "alligator", "i")) {
                                            await this.writeInput("record", "c2", 148);
                                        }
                                }
                                resolve();
                                break;
                            case "apc":
                                const focus = this.getStoreHiddenParameter.focus;
                                if (focus === 'interven' && this.getIntervenForVoice && this.getIntervenForVoice['apc']) {
                                    await this.writeInput("record", "s", "636");
                                }
                                if (focus === 'device' && this.getRecord.questions.apc) {
                                    this.setStoreHiddenParameter({ target: 'focus', value: 'apc' })
                                    await this.writeInput("record", "c2", 119);
                                }
                                resolve();
                                break;
                            case "biopsy forceps":
                                // biopsy forceps (no.1) (biopsy forceps)
                                if (this.checkInputExistAndSetFocuse("record", "biopFor", "i")) {
                                    await this.writeInput("record", "c2", 145);
                                }
                                resolve();
                                break;
                            case "minor bleeding":
                                // minor bleeding (no.1) (minor bleeding)
                                if (this.checkInputExistAndSetFocuse("record", "bleedMinor", "i")) {
                                    await this.writeInput("record", "c2", 173);
                                }
                                resolve();
                                break;
                            case "cap":
                                // cap (no.1) (cap)
                                if (this.checkInputExistAndSetFocuse("record", "cap", "i")) {
                                    await this.writeInput("record", "c2", 144);
                                }
                                resolve();
                                break;
                            case "hemoclip":
                                // hemoclip (no.1) (hemoclip)
                                if (this.checkInputExistAndSetFocuse("record", "clip", "i")) {
                                    option = ["hemoclip"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "coagrasper":
                                // coagrasper (no.1) (coagrasper)
                                if (this.checkInputExistAndSetFocuse("record", "coag", "i")) {
                                    await this.writeInput("record", "c2", 120);
                                }
                                resolve();
                                break;
                            case "cold_snare":
                            case "cold snare":
                                // cold snare (no.1) (cold snare)
                                if(this.colonPolypectomyFocus || this.getFindings[findingIndex].records[recordIndex].questions['interven'].value === "59"){
                                    if (this.checkInputExistAndSetFocuse("record", "coldSnare", "i")) {
                                        await this.writeInput("record", "c2", 156);
                                    }
                                } else {
                                    switch (this.getStoreHiddenParameter.focus) {
                                        case "interven":
                                            switch (Number(this.examination_typeID)) {
                                                case 1: // Gastroscopy
                                                    if ([9, 572, 573, 540, 574, 11, 16, 10, 39, 40, 41, 42, 43, 45, 46, 47].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                        await this.writeInput("record", "s", "75");
                                                    }
                                                    break;
                                                case 2: // Colonoscopy
                                                case 3: // copy from case = '2' (Colonoscopy)
                                                    if ([9, 39, 40, 41, 42, 43, 44, 45, 46, 47, 540, 541, 542, 553, 548, 57, 543, 544, 545, 546, 547, 549, 550, 17, 18, 19, 20, 10, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                        await this.writeInput("record", "s", "75");
                                                    }
                                                    break;
                                            }
                                            break;
                                        default:
                                            if (this.checkInputExistAndSetFocuse("record", "cold_snare", "m")) {
                                                await this.writeInput("record", "c2", 156);
                                            }
                                            if (this.checkInputExistAndSetFocuse("record", "coldSnare", "i")) {
                                                await this.writeInput("record", "c2", 156);
                                            }
                                    }
                                }
                                resolve();
                                break;
                            case "perforation":
                                if (this.getStoreHiddenParameter.focus === "complications") {
                                    var temp_obj = COMPILACTION_LIST;
                                        
                                        if (temp_obj[voiceInput]) {
                                            const newValue = Number(temp_obj[voiceInput]);
                                            if (complications.includes(newValue)) {
                                                complications = complications.filter(item => item !== newValue);
                                            } else {
                                                complications.push(newValue);
                                            }
                                            this.setExaminationColumn('complication', complications);
                                            this.setStoreHiddenParameter({
                                                target: 'focus',
                                                value: ''
                                            });
                                        }
                                    resolve();
                                    break;
                                }
                                // perforation (no.1) (perforation, closed)
                                if (this.getStoreHiddenParameter.focus == "perfor") {
                                    await this.writeInput("record", "s", "123");
                                } else if (this.checkInputExistAndSetFocuse("record", "perfor", "i")) {
                                    option = ["perforation", "closed"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "completely removed":
                                // completely removed (no.1) (completely removed)
                                if (this.checkInputExistAndSetFocuse("record", "comremove", "i")) {
                                    await this.writeInput("record", "c2", 88);
                                }
                                resolve();
                                break;
                            case "diluted epinephrine":
                                // diluted epinephrine (no.1) (diluted epinephrine)
                                if (this.checkInputExistAndSetFocuse("record", "diEpi", "i")) {
                                    await this.writeInput("record", "c2", 122);
                                }
                                resolve();
                                break;
                            case "dualknife":
                                // dualknife (no.1) (dualknife)
                                if (this.checkInputExistAndSetFocuse("record", "dualK", "i")) {
                                    await this.writeInput("record", "c2", 133);
                                }
                                resolve();
                                break;
                            case "elective":
                                // elective (no.1) (elective)
                                if (this.checkInputExistAndSetFocuse("record", "ele", "i")) {
                                    await this.writeInput("record", "c2", 158);
                                }
                                resolve();
                                break;
                            case "en bloc":
                                // En bloc (no.1) (En bloc)
                                if (this.checkInputExistAndSetFocuse("record", "enbloc", "i")) {
                                    await this.writeInput("record", "c2", 152);
                                }
                                resolve();
                                break;
                            case "endolifter":
                                // endolifter (no.1) (endolifter)
                                if (this.checkInputExistAndSetFocuse("record", "endolift", "i")) {
                                    await this.writeInput("record", "c2", 143);
                                }
                                resolve();
                                break;
                            case "endoloop":
                                // endoloop (no.1) (endoloop)
                                if (this.checkInputExistAndSetFocuse("record", "endoloop", "i")) {
                                    await this.writeInput("record", "c2", 151);
                                }
                                resolve();
                                break;
                            case "flexknife":
                                // flexknife (no.1) (flexknife)
                                if (this.checkInputExistAndSetFocuse("record", "flexK", "i")) {
                                    await this.writeInput("record", "c2", 139);
                                }
                                resolve();
                                break;
                            case "glycerol":
                                // glycerol (no.1) (glycerol)
                                if (this.checkInputExistAndSetFocuse("record", "glyce", "i")) {
                                    await this.writeInput("record", "c2", 128);
                                }
                                resolve();
                                break;
                            case "grasping forceps":
                                // grasping forceps (no.1) (grasping forceps)
                                switch (this.getStoreHiddenParameter.focus) {
                                    case "device":
                                        if ([51].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                            this.setStoreHiddenParameter({ target: 'focus', value: 'dev' })
                                            await this.writeInput("record", "c", 252);
                                        } else if ([640, 646].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                            this.setStoreHiddenParameter({ target: 'focus', value: 'graFor' })
                                            await this.writeInput("record", "c", 146);
                                        }
                                        break;
                                    default:
                                        if (this.checkInputExistAndSetFocuse("record", "graFor", "i")) {
                                            await this.writeInput("record", "c2", 146);
                                        }
                                }
                                resolve();
                                break;
                            case "histoacryl":
                                // histoacryl (no.1) (histoacryl)
                                if (this.checkInputExistAndSetFocuse("record", "histo", "i")) {
                                    await this.writeInput("record", "c2", 160);
                                }
                                resolve();
                                break;
                            case "hookknife":
                                // hookknife (no.1) (hookknife)
                                if (this.checkInputExistAndSetFocuse("record", "hookK", "i")) {
                                    await this.writeInput("record", "c2", 138);
                                }
                                resolve();
                                break;
                            case "heater probe":
                                // heater probe (no.1) (heater probe)
                                if (this.checkInputExistAndSetFocuse("record", "hProbe", "i")) {
                                    await this.writeInput("record", "c2", 121);
                                }
                                resolve();
                                break;
                            case "hyaluronic acid":
                                // hyaluronic acid (no.1) (hyaluronic acid)
                                if (this.checkInputExistAndSetFocuse("record", "hyAcid", "i")) {
                                    await this.writeInput("record", "c2", 129);
                                }
                                resolve();
                                break;
                            case "hybrid knife":
                                // hybrid knife (no.1) (hybrid knife)
                                if (this.checkInputExistAndSetFocuse("record", "hybridK", "i")) {
                                    await this.writeInput("record", "c2", 141);
                                }
                                resolve();
                                break;
                            case "indigo carmine":
                                // indigo carmine (no.1) (indigo carmine)
                                switch (this.getStoreHiddenParameter.focus) {
                                    case "chroMag":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([4, 15].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "c", 188);
                                                }
                                                break;
                                        }
                                        break;
                                    default:
                                        if (this.checkInputExistAndSetFocuse("record", "indigo", "i")) {
                                            await this.writeInput("record", "c2", 131);
                                        }
                                }
                                resolve();
                                break;
                            case "intervention":
                                // intervention (no.1) (biopsy, ARMS, Stretta, hot biopsy, cold biopsy, other)
                                // intervention (no.3) (biopsy, polypectomy, EMR, ESD, hot biopsy, cold biopsy, snare, cold snare, hot snare, s/p endolooping, other)
                                // intervention (no.4) (biopsy, hot biopsy, cold biopsy, other)
                                // intervention (no.5) (biopsy, hemostasis, hot biopsy, cold biopsy, other)
                                // intervention (no.6) (biopsy, EMR, ESD, RFA, hot biopsy, cold biopsy, s/p endolooping, s/p stenting, other)
                                // intervention (no.8) (biopsy, polypectomy, EMR, hot biopsy, cold biopsy, snare, cold snare, hot snare, s/p endolooping, other)
                                // intervention (no.10) (hemostasis, bezoar removal, other)
                                // intervention (no.11) (hemostasis, other)
                                // intervention (no.12) (biopsy, ESD, RFA, hot biopsy, cold biopsy, other)
                                // intervention (no.13) (biopsy, polypectomy, EMR, ESD, STER, hot biopsy, cold biopsy, snare, cold snare, hot snare, s/p tattooing, other)
                                // intervention (no.14) (EVL, EIS, SB tube, other)
                                // intervention (no.15) (GVL, GIS, other)
                                // intervention (no.16) (band ligation, sclerotherapy, infrared coagulation, other)
                                // intervention (no.17) (biopsy, polypectomy, EMR, ESD, hot biopsy, cold biopsy, snare, cold snare, hot snare, s/p endolooping, s/p tattooing, s/p stenting, other)
                                // intervention (no.18) (biopsy, polypectomy, EMR, ESD, hot biopsy, cold biopsy, snare, cold snare, hot snare, s/p tattooing, s/p stenting, other)
                                // intervention (no.19) (hemostasis, s/p tattooing, s/p stenting, other)
                                // intervention (no.20) (biopsy, hemostasis, hot biopsy, cold biopsy, s/p stenting, other)
                                // intervention (no.21) (other)
                                // intervention (no.22) (s/p stenting, other)
                                if (this.checkInputExistAndSetFocuse("record", "interven", "i")) {
                                    switch (Number(this.examination_typeID)) {
                                        case 1: // Gastroscopy
                                            if ([1, 565].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.1)
                                                option = ["biopsy", "ARMS", "Stretta", "hot biopsy", "cold biopsy", "other"];
                                            } else if ([7, 12].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.2)
                                                option = ["biopsy", "hemostasis", "hot biopsy", "cold biopsy", "other"];
                                            } else if ([14].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.4)
                                                option = ["biopsy", "hot biopsy", "cold biopsy", "other"];
                                            } else if ([4, 15].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.6)
                                                option = ["biopsy", "EMR", "ESD", "RFA", "hot biopsy", "cold biopsy", "s/p endolooping", "s/p stenting", "other"];
                                            } else if ([8, 566, 567, 568, 569, 570, 571].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.7)
                                                option = ["biopsy", "hot biopsy", "cold biopsy", "other"];
                                            } else if ([9, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.8)
                                                option = ["biopsy", "polypectomy", "EMR", "hot biopsy", "cold biopsy", "snare", "cold snare", "hot snare", "s/p endolooping", "other"];
                                            } else if ([11, 16].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.9)
                                                option = ["biopsy", "hemostasis", "polypectomy", "EMR", "ESD", "hot biopsy", "cold biopsy", "snare", "cold snare", "hot snare", "s/p endolooping", "s/p tattooing", "s/p stenting", "other"];
                                            } else if ([2].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.12)
                                                option = ["biopsy", "ESD", "RFA", "hot biopsy", "cold biopsy", "other"];
                                            } else if ([10, 39, 40, 41, 42, 43, 45, 46, 47].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.13)
                                                option = ["biopsy", "polypectomy", "EMR", "ESD", "STER", "hot biopsy", "cold biopsy", "snare", "cold snare", "hot snare", "s/p tattooing", "other"];
                                            } else if ([5].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.14)
                                                option = ["EVL", "EIS", "SB tube", "other"];
                                            } else if ([6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.15)
                                                option = ["GVL", "GIS", "other"];
                                            } else if ([3, 13, 592, 593, 594, 597].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.21)
                                                option = ["other"];
                                            } else if ([559, 587, 588].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.22)
                                                option = ["biopsy", "s/p stenting", "other"];
                                            }
                                            break;
                                        case 2: // Colonoscopy
                                        case 3: // Colonoscopy
                                            if ([9, 39, 40, 41, 42, 43, 44, 45, 46, 47, 540, 541, 542, 553, 548, 642, 651, 661].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.3)
                                                option = ["biopsy", "polypectomy", "EMR", "ESD", "hot biopsy", "cold biopsy", "snare", "cold snare", "hot snare", "s/p endolooping", "other"];
                                            } else if ([38, 50, 52, 558].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.4)
                                                option = ["biopsy", "hot biopsy", "cold biopsy", "other"];
                                            } else if ([21, 22, 551, 662].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.5)
                                                option = ["biopsy", "hemostasis", "hot biopsy", "cold biopsy", "other"];
                                            } else if ([35, 36, 37, 564, 578].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.10)
                                                option = ["hemostasis", "bezoar removal", "other"];
                                            } else if ([561, 640].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.11)
                                                option = ["hemostasis", "other"];
                                            } else if ([34, 575, 576, 577, 648].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.16)
                                                option = ["band ligation", "sclerotherapy", "infrared coagulation", "other"];
                                            } else if ([57, 543, 544, 545, 546, 547, 549, 550].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.17)
                                                option = ["biopsy", "polypectomy", "EMR", "ESD", "hot biopsy", "cold biopsy", "snare", "cold snare", "hot snare", "s/p endolooping", "s/p tattooing", "s/p stenting", "other", "EPMR"];
                                            } else if ([17, 18, 19, 20, 10, 643, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.18)
                                                option = ["biopsy", "polypectomy", "EMR", "ESD", "hot biopsy", "cold biopsy", "snare", "cold snare", "hot snare", "s/p tattooing", "s/p stenting", "other"];
                                            } else if ([48, 579, 580, 581, 582, 583, 584, 585, 586].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.19)
                                                option = ["hemostasis", "s/p tattooing", "s/p stenting", "other"];
                                            } else if ([23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 552, 560, 638, 639, 641, 644, 645].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.20)
                                                option = ["biopsy", "hemostasis", "hot biopsy", "cold biopsy", "s/p stenting", "other"];
                                            } else if ([49, 51, 554, 556, 557, 595, 596, 594, 597, 649, 663].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.21)
                                                option = ["other"];
                                            } else if ([555, 559, 587, 588, 647, 650, 665, 666].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.22)
                                                option = ["biopsy", "s/p stenting", "other"];
                                            } else if ([646].indexOf(Number(this.getFinding.questions.finding.value)) != -1) { // intervention (no.22)
                                                option = ["biopsy", "polypectomy", "EMR", "ESD", "hot biopsy", "cold biopsy", "s/p endolooping", "other"];
                                            }
                                            break;
                                    }
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "itknife":
                                // ITKnife (no.1) (ITKnife)
                                if (this.checkInputExistAndSetFocuse("record", "itK", "i")) {
                                    await this.writeInput("record", "c2", 134);
                                }
                                resolve();
                                break;
                            case "itknife-2":
                                // ITKnife-2 (no.1) (ITKnife-2)
                                if (this.checkInputExistAndSetFocuse("record", "itK2", "i")) {
                                    await this.writeInput("record", "c2", 135);
                                }
                                resolve();
                                break;
                            case "itknife nano":
                                // ITKnife nano (no.1) (ITKnife nano)
                                if (this.checkInputExistAndSetFocuse("record", "itKnano", "i")) {
                                    await this.writeInput("record", "c2", 136);
                                }
                                resolve();
                                break;
                            case "specimen label":
                                // specimen label (no.1) (specimen label)
                                if (this.checkInputExistAndSetFocuse("record", "label", "i")) {
                                    option = ["specimen label"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "lipiodol":
                                // lipiodol (no.1) (lipiodol)
                                if (this.checkInputExistAndSetFocuse("record", "lipi", "i")) {
                                    await this.writeInput("record", "c2", 161);
                                }
                                resolve();
                                break;
                            case "needle knife":
                                // needle knife (no.1) (needle knife)
                                if (this.checkInputExistAndSetFocuse("record", "neeK", "i")) {
                                    await this.writeInput("record", "c2", 140);
                                }
                                resolve();
                                break;
                            case "net":
                                // net (no.1) (net)
                                switch (this.getStoreHiddenParameter.focus) {
                                    case "finding":
                                        await this.$store.dispatch('examinations/addFinding', this.getExaminationData.examination.id).then(async () => {
                                            const nowFindingIndex = this.getExaminationData.findings.length - 1
                                            this.setHiddenParameter(nowFindingIndex, 0);
                                            this.setStoreHiddenParameter({
                                                target: 'over',
                                                value: false
                                            });

                                            switch (Number(this.examination_typeID)) {
                                                case 1: // Gastroscopy
                                                case 2: // Colonoscopy
                                                case 3: // copy from case = '2' (Colonoscopy)
                                                    await this.writeInput("finding", "s", "45");
                                                    break;
                                            }
                                        })
                                    break;
                                    case "device":
                                    this.setStoreHiddenParameter({ target: 'focus', value: 'dev' })
                                        switch (Number(this.examination_typeID)) {
                                            case 1:
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([51].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "c", 254);
                                                }
                                                break;
                                        }
                                        break;
                                    case "ro":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([10].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "c", 475);
                                                }
                                                break;                                      
                                        }
                                        break;
                                    default:
                                        if (this.checkInputExistAndSetFocuse("record", "net", "i")) {
                                            await this.writeInput("record", "c2", 147);
                                        } else {
                                            this.voiceCommand('findingNet')
                                        }
                                }
                                resolve();
                                break;
                            case "pentapod grasping forceps":
                                // pentapod grasping forceps (no.1) (pentapod grasping forceps)
                                switch (this.getStoreHiddenParameter.focus) {
                                    case "device":
                                        if ([51].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                            this.setStoreHiddenParameter({ target: 'focus', value: 'dev' })
                                            await this.writeInput("record", "c", 251);
                                        } else if ([640, 646].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                            this.setStoreHiddenParameter({ target: 'focus', value: 'pentaFor' })
                                            await this.writeInput("record", "c", 150);
                                        }
                                        break;
                                    default:
                                        if (this.checkInputExistAndSetFocuse("record", "pentaFor", "i")) {
                                            await this.writeInput("record", "c2", 150);
                                        }
                                }
                                resolve();
                                break;
                            case "removal forceps":
                                switch (this.getStoreHiddenParameter.focus) {
                                    case "device":
                                        if ([51].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                            this.setStoreHiddenParameter({ target: 'focus', value: 'dev' })
                                            await this.writeInput("record", "c", 250);
                                        } else if ([640, 646].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                            this.setStoreHiddenParameter({ target: 'focus', value: 'reFor' })
                                            await this.writeInput("record", "c", 149);
                                        }
                                        break;
                                    default:
                                        if (this.checkInputExistAndSetFocuse("record", "reFor", "i")) {
                                            await this.writeInput("record", "c2", 149);
                                        }
                                }
                                resolve();
                                break;
                            case "rubber band":
                                // rubber band (no.1) (rubber band)
                                if (this.checkInputExistAndSetFocuse("record", "rubband", "i")) {
                                    option = ["rubber band"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "saline":
                                // saline (no.1) (saline)
                                if (this.checkInputExistAndSetFocuse("record", "saline", "i")) {
                                    await this.writeInput("record", "c2", 130);
                                }
                                resolve();
                                break;
                            case "sb tube":
                                // SB tube (no.1) (SB tube)
                                switch (this.getStoreHiddenParameter.focus) {
                                    case "interven":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "s", "521");
                                                }
                                                break;
                                        }
                                        break;
                                    default:
                                        if (this.checkInputExistAndSetFocuse("record", "sbtue", "i")) {
                                            await this.writeInput("record", "c2", 172);
                                        }
                                }
                                resolve();
                                break;
                            case "snare":
                                // snare (no.1) (snare)
                                if(this.colonPolypectomyFocus || this.getFindings[findingIndex].records[recordIndex].questions['interven'].value === "59"){
                                    if (this.checkInputExistAndSetFocuse("record", "snare", "i")) {
                                        await this.writeInput("record", "c2", 142);
                                    }
                                }else{
                                    switch (this.getStoreHiddenParameter.focus) {
                                        case "interven":
                                            switch (Number(this.examination_typeID)) {
                                                case 1: // Gastroscopy
                                                    if ([9, 572, 573, 540, 574, 587, 11, 16, 10, 39, 40, 41, 42, 43, 45, 46, 47].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                        await this.writeInput("record", "s", "74");
                                                    }
                                                    break;
                                                case 2: // Colonoscopy
                                                case 3: // copy from case = '2' (Colonoscopy)
                                                    if ([9, 39, 40, 41, 42, 43, 44, 45, 46, 47, 540, 541, 542, 553, 548, 57, 543, 544, 545, 546, 547, 549, 550, 17, 18, 19, 20, 10, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                        await this.writeInput("record", "s", "74");
                                                    }
                                                    break;
                                            }
                                            break;
                                        case "device":
                                        this.setStoreHiddenParameter({ target: 'focus', value: 'dev' })
                                            switch (Number(this.examination_typeID)) {
                                                case 1:
                                                case 2: // Colonoscopy
                                                case 3: // copy from case = '2' (Colonoscopy)
                                                    if ([51].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                        await this.writeInput("record", "c", 255);
                                                    }
                                                    break;
                                            }
                                            break;
                                        default:
                                            if (this.checkInputExistAndSetFocuse("record", "snare", "i")) {
                                                await this.writeInput("record", "c2", 142);
                                            }
                                    }
                                }
                                resolve();
                                break;
                            case "sucralfate gel":
                                // sucralfate gel (no.1) (sucralfate gel)
                                if (this.checkInputExistAndSetFocuse("record", "sucra", "i")) {
                                    await this.writeInput("record", "c2", 132);
                                }
                                resolve();
                                break;
                            case "pieces of tissue":
                                // pieces of tissue (no.1) (pieces of tissue)
                                if (this.checkInputExistAndSetFocuse("record", "tissue", "i")) {
                                    option = ["pieces of tissue"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "tooth":
                                // tooth (no.1) (tooth)
                                if (this.checkInputExistAndSetFocuse("record", "tooth", "i")) {
                                    await this.writeInput("record", "c2", 174);
                                }
                                resolve();
                                break;
                            case "triangle knife":
                                // triangle knife (no.1) (triangle knife)
                                if (this.checkInputExistAndSetFocuse("record", "triK", "i")) {
                                    await this.writeInput("record", "c2", 137);
                                }
                                resolve();
                                break;
                            case "major bleeding":
                                // major bleeding (no.1) (major bleeding)
                                if (this.checkInputExistAndSetFocuse("record", "bleedMajor", "i")) {
                                    await this.writeInput("record", "c2", 68);
                                }
                                resolve();
                                break;
                            case "intervention others":
                                // intervention others (no.1) (intervention others)
                                if (this.checkInputExistAndSetFocuse("record", "intervenOther", "i")) {
                                    option = ["intervention others"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "bleeding stop":
                                // bleeding stop (no.1) (bleeding stop)
                                if (this.checkInputExistAndSetFocuse("record", "bleedStop", "i")) {
                                    await this.writeInput("record", "c2", 69);
                                }
                                resolve();
                                break;
                            case "bleeding continue":
                                // bleeding continue (no.1) (bleeding continue)
                                if (this.checkInputExistAndSetFocuse("record", "bleedContinue", "i")) {
                                    await this.writeInput("record", "c2", 70);
                                }
                                resolve();
                                break;
                            case "causes":
                                if (this.checkInputExistAndSetFocuse("record", "causes", "d")) {
                                    option = ["chronic esophagitis", "post operation", "cancer", "congenital"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            case "obstruction":
                                if (this.checkInputExistAndSetFocuse("record", "obstruction", "d")) {
                                    option = ["partial obstruction", "complete obstruction"];
                                    this.showOptionTip(option);
                                }
                                resolve();
                                break;
                            default:
                                console.table({voiceInput, focus: this.getFocus(voiceInput, voiceInputArray)})
                                //==========================================================================
                                //※ 第三層 填寫欄位
                                //==========================================================================                        
                                switch (this.getFocus(voiceInput, voiceInputArray)) {
                                    case "actBle":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([7, 12, 21].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "yes":
                                                            await this.writeInput("record", "s", "175");
                                                            break;
                                                        case "no":
                                                            await this.writeInput("record", "s", "176");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "bleTen":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "active bleeding":
                                                            await this.writeInput("record", "c", 179);
                                                            break;
                                                        case "nipple sign":
                                                            await this.writeInput("record", "c", 180);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([34, 575, 576, 577, 648].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "recent bleeding":
                                                            await this.writeInput("record", "c", 177);
                                                            break;
                                                        case "blood oozing":
                                                            await this.writeInput("record", "c", 178);
                                                            break;
                                                        case "active bleeding":
                                                            await this.writeInput("record", "c", 179);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "borm":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([7, 11, 12, 16].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "1":
                                                            await this.writeInput("record", "s", "181");
                                                            break;
                                                        case "2":
                                                            await this.writeInput("record", "s", "182");
                                                            break;
                                                        case "3":
                                                            await this.writeInput("record", "s", "183");
                                                            break;
                                                        case "4":
                                                            await this.writeInput("record", "s", "184");
                                                            break;
                                                    }
                                                }
                                                
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "chroMag":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([4, 15].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "nbi":
                                                            await this.writeInput("record", "c", 185);
                                                            break;
                                                        case "bli":
                                                            await this.writeInput("record", "c", 186);
                                                            break;
                                                        case "lugol's solution":
                                                            await this.writeInput("record", "c", 187);
                                                            break;
                                                        case "indigo carmine":
                                                            await this.writeInput("record", "c", 188);
                                                            break;
                                                        case "magnification":
                                                            await this.writeInput("record", "c", 531);
                                                            break;
                                                        case "acetic acid":
                                                            await this.writeInput("record", "c", 72);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "chroSign":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([4, 15].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "silver sign":
                                                            await this.writeInput("record", "c", 189);
                                                            break;
                                                        case "pink-color sign":
                                                            await this.writeInput("record", "c", 190);
                                                            break;
                                                        case "brownish color":
                                                            await this.writeInput("record", "c", 191);
                                                            break;
                                                        case "light color":
                                                            await this.writeInput("record", "c", 192);
                                                            break;
                                                        case "lugol-voiding":
                                                            await this.writeInput("record", "c", 193);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "color":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "white":
                                                            await this.writeInput("record", "s", "194");
                                                            break;
                                                        case "blue":
                                                            await this.writeInput("record", "s", "195");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "conSur":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6, 1, 2, 4, 7, 8, 9, 10, 11, 12, 14, 15, 16, 21, 22, 39, 40, 41, 42, 43, 45, 46, 47, 51, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587, 650, 667].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "yes":
                                                            await this.writeInput("record", "s", "196");
                                                            break;
                                                        case "no":
                                                            await this.writeInput("record", "s", "197");
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([51].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "yes":
                                                            await this.writeInput("record", "s", "196");
                                                            break;
                                                        case "no":
                                                            await this.writeInput("record", "s", "197");
                                                            break;
                                                    }
                                                } else if ([34, 9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 35, 36, 48, 561, 548, 37, 564, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 646, 648, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "yes":
                                                            await this.writeInput("record", "s", "196");
                                                            break;
                                                        case "no":
                                                            await this.writeInput("record", "s", "197");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "demarc":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([11, 16].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "present":
                                                            await this.writeInput("record", "s", "198");
                                                            break;
                                                        case "absent":
                                                            await this.writeInput("record", "s", "199");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "des":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([8, 566, 567, 568, 569, 570, 571, 667].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "edema":
                                                            await this.writeInput("record", "c", 201);
                                                            break;
                                                        case "erythema":
                                                            await this.writeInput("record", "c", 202);
                                                            break;
                                                        case "friability":
                                                            await this.writeInput("record", "c", 203);
                                                            break;
                                                        case "exudate":
                                                            await this.writeInput("record", "c", 204);
                                                            break;
                                                        case "flat erosion":
                                                            await this.writeInput("record", "c", 205);
                                                            break;
                                                        case "raised erosion":
                                                            await this.writeInput("record", "c", 206);
                                                            break;
                                                        case "nodularity":
                                                            await this.writeInput("record", "c", 207);
                                                            break;
                                                        case "rugal hypertrophy":
                                                            await this.writeInput("record", "c", 208);
                                                            break;
                                                        case "gastric fold hypertrophy":
                                                            await this.writeInput("record", "c", 209);
                                                            break;
                                                        case "rugal atrophy":
                                                            await this.writeInput("record", "c", 210);
                                                            break;
                                                        case "gastric mucosa atrophy":
                                                            await this.writeInput("record", "c", 211);
                                                            break;
                                                        case "visibility of vascular pattern":
                                                            await this.writeInput("record", "c", 212);
                                                            break;
                                                        case "intramural bleeding spots":
                                                            await this.writeInput("record", "c", 213);
                                                            break;
                                                        case "intestinal metaplasia":
                                                            await this.writeInput("record", "c", 214);
                                                            break;
                                                        case "diffuse redness":
                                                            await this.writeInput("record", "c", 215);
                                                            break;
                                                        case "erosions":
                                                            await this.writeInput("record", "c", 216);
                                                            break;
                                                    }
                                                } else if ([10, 39, 40, 41, 42, 43, 45, 46, 47].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "elastic":
                                                            await this.writeInput("record", "c", 217);
                                                            break;
                                                        case "soft":
                                                            await this.writeInput("record", "c", 218);
                                                            break;
                                                        case "hard":
                                                            await this.writeInput("record", "c", 219);
                                                            break;
                                                        case "pillow sign":
                                                            await this.writeInput("record", "c", 220);
                                                            break;
                                                        case "yellow":
                                                            await this.writeInput("record", "c", 221);
                                                            break;
                                                        case "ulcerative":
                                                            await this.writeInput("record", "c", 222);
                                                            break;
                                                    }
                                                } else if ([13, 559, 587, 588, 592, 593, 594, 597, 650, 665].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "ta", voiceInput);
                                                } else if ([22].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "tiny":
                                                            await this.writeInput("record", "c", 223);
                                                            break;
                                                        case "small":
                                                            await this.writeInput("record", "c", 224);
                                                            break;
                                                        case "giant":
                                                            await this.writeInput("record", "c", 225);
                                                            break;
                                                        case "shallow":
                                                            await this.writeInput("record", "c", 226);
                                                            break;
                                                        case "broad":
                                                            await this.writeInput("record", "c", 227);
                                                            break;
                                                        case "spotted":
                                                            await this.writeInput("record", "c", 228);
                                                            break;
                                                        case "scattered":
                                                            await this.writeInput("record", "c", 229);
                                                            break;
                                                        case "confluent":
                                                            await this.writeInput("record", "c", 230);
                                                            break;
                                                        case "focal":
                                                            await this.writeInput("record", "c", 231);
                                                            break;
                                                        case "continuous-lesion":
                                                            await this.writeInput("record", "c", 232);
                                                            break;
                                                        case "skip-lesion":
                                                            await this.writeInput("record", "c", 233);
                                                            break;
                                                        case "recent bleeding":
                                                            await this.writeInput("record", "c", 246);
                                                            break;
                                                        case "blood oozing":
                                                            await this.writeInput("record", "c", 234);
                                                            break;
                                                        case "active bleeding":
                                                            await this.writeInput("record", "c", 235);
                                                            break;
                                                        case "mucus plaque":
                                                            await this.writeInput("record", "c", 236);
                                                            break;
                                                        case "swelling mucosa":
                                                            await this.writeInput("record", "c", 237);
                                                            break;
                                                        case "partial obstruction":
                                                            await this.writeInput("record", "c", 238);
                                                            break;
                                                        case "nearly complete obstruction":
                                                            await this.writeInput("record", "c", 239);
                                                            break;
                                                        case "complete obstruction":
                                                            await this.writeInput("record", "c", 240);
                                                            break;
                                                        case "ischemia":
                                                            await this.writeInput("record", "c", 241);
                                                            break;
                                                        case "necrosis":
                                                            await this.writeInput("record", "c", 242);
                                                            break;
                                                        case "friability":
                                                            await this.writeInput("record", "c", 203);
                                                            break;
                                                        case "erythema":
                                                            await this.writeInput("record", "c", 202);
                                                            break;
                                                        case "inflammation":
                                                            await this.writeInput("record", "c", 243);
                                                            break;
                                                        case "exudate":
                                                            await this.writeInput("record", "c", 204);
                                                            break;
                                                        case "overlying membrane":
                                                            await this.writeInput("record", "c", 244);
                                                            break;
                                                        case "abscess":
                                                            await this.writeInput("record", "c", 245);
                                                            break;
                                                    }
                                                } else if ([668].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "friability":
                                                            await this.writeInput("record", "c", 203);
                                                            break;
                                                        case "easy bleeding":
                                                            await this.writeInput("record", "c", 672);
                                                            break;
                                                        case "food stasis":
                                                            await this.writeInput("record", "c", 673);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 553].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "elastic":
                                                            await this.writeInput("record", "c", 217);
                                                            break;
                                                        case "soft":
                                                            await this.writeInput("record", "c", 218);
                                                            break;
                                                        case "hard":
                                                            await this.writeInput("record", "c", 219);
                                                            break;
                                                        case "pillow sign":
                                                            await this.writeInput("record", "c", 220);
                                                            break;
                                                        case "yellow":
                                                            await this.writeInput("record", "c", 221);
                                                            break;
                                                        case "ulcerative":
                                                            await this.writeInput("record", "c", 222);
                                                            break;
                                                    }
                                                } else if ([21, 22, 551, 662].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "tiny":
                                                            await this.writeInput("record", "c", 223);
                                                            break;
                                                        case "small":
                                                            await this.writeInput("record", "c", 224);
                                                            break;
                                                        case "giant":
                                                            await this.writeInput("record", "c", 225);
                                                            break;
                                                        case "shallow":
                                                            await this.writeInput("record", "c", 226);
                                                            break;
                                                        case "broad":
                                                            await this.writeInput("record", "c", 227);
                                                            break;
                                                        case "spotted":
                                                            await this.writeInput("record", "c", 228);
                                                            break;
                                                        case "scattered":
                                                            await this.writeInput("record", "c", 229);
                                                            break;
                                                        case "confluent":
                                                            await this.writeInput("record", "c", 230);
                                                            break;
                                                        case "focal":
                                                            await this.writeInput("record", "c", 231);
                                                            break;
                                                        case "continuous-lesion":
                                                            await this.writeInput("record", "c", 232);
                                                            break;
                                                        case "skip-lesion":
                                                            await this.writeInput("record", "c", 233);
                                                            break;
                                                        case "recent bleeding":
                                                            await this.writeInput("record", "c", 246);
                                                            break;
                                                        case "blood oozing":
                                                            await this.writeInput("record", "c", 234);
                                                            break;
                                                        case "active bleeding":
                                                            await this.writeInput("record", "c", 235);
                                                            break;
                                                        case "mucus plaque":
                                                            await this.writeInput("record", "c", 236);
                                                            break;
                                                        case "swelling mucosa":
                                                            await this.writeInput("record", "c", 237);
                                                            break;
                                                        case "partial obstruction":
                                                            await this.writeInput("record", "c", 238);
                                                            break;
                                                        case "nearly complete obstruction":
                                                            await this.writeInput("record", "c", 239);
                                                            break;
                                                        case "complete obstruction":
                                                            await this.writeInput("record", "c", 240);
                                                            break;
                                                        case "ischemia":
                                                            await this.writeInput("record", "c", 241);
                                                            break;
                                                        case "necrosis":
                                                            await this.writeInput("record", "c", 242);
                                                            break;
                                                        case "friability":
                                                            await this.writeInput("record", "c", 203);
                                                            break;
                                                        case "erythema":
                                                            await this.writeInput("record", "c", 202);
                                                            break;
                                                        case "inflammation":
                                                            await this.writeInput("record", "c", 243);
                                                            break;
                                                        case "exudate":
                                                            await this.writeInput("record", "c", 204);
                                                            break;
                                                        case "overlying membrane":
                                                            await this.writeInput("record", "c", 244);
                                                            break;
                                                        case "abscess":
                                                            await this.writeInput("record", "c", 245);
                                                            break;
                                                    }
                                                } else if ([23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 552, 638, 639, 641, 644, 645].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "recent bleeding":
                                                            await this.writeInput("record", "c", 246);
                                                            break;
                                                        case "blood oozing":
                                                            await this.writeInput("record", "c", 234);
                                                            break;
                                                        case "active bleeding":
                                                            await this.writeInput("record", "c", 235);
                                                            break;
                                                        case "mucus plaque":
                                                            await this.writeInput("record", "c", 236);
                                                            break;
                                                        case "swelling mucosa":
                                                            await this.writeInput("record", "c", 237);
                                                            break;
                                                        case "partial obstruction":
                                                            await this.writeInput("record", "c", 238);
                                                            break;
                                                        case "nearly complete obstruction":
                                                            await this.writeInput("record", "c", 239);
                                                            break;
                                                        case "complete obstruction":
                                                            await this.writeInput("record", "c", 240);
                                                            break;
                                                        case "ischemia":
                                                            await this.writeInput("record", "c", 241);
                                                            break;
                                                        case "necrosis":
                                                            await this.writeInput("record", "c", 242);
                                                            break;
                                                        case "friability":
                                                            await this.writeInput("record", "c", 203);
                                                            break;
                                                        case "erythema":
                                                            await this.writeInput("record", "c", 202);
                                                            break;
                                                        case "inflammation":
                                                            await this.writeInput("record", "c", 243);
                                                            break;
                                                        case "exudate":
                                                            await this.writeInput("record", "c", 204);
                                                            break;
                                                        case "overlying membrane":
                                                            await this.writeInput("record", "c", 244);
                                                            break;
                                                        case "abscess":
                                                            await this.writeInput("record", "c", 245);
                                                            break;
                                                    }
                                                } else if ([48, 579, 580, 581, 582].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hematin":
                                                            await this.writeInput("record", "c", 247);
                                                            break;
                                                        case "tarry stool":
                                                            await this.writeInput("record", "c", 248);
                                                            break;
                                                        case "fresh blood":
                                                            await this.writeInput("record", "c", 249);
                                                            break;
                                                        case "active bleeding":
                                                            await this.writeInput("record", "c", 235);
                                                            break;
                                                    }
                                                } else if ([554, 555, 556, 557, 558, 559, 560, 561, 587, 588, 595, 596, 594, 597, 640, 646, 647, 649, 650, 663, 665, 666].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "ta", voiceInput);
                                                } else if ([583].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "tarry stool":
                                                            await this.writeInput("record", "c", 248);
                                                            break;
                                                        case "fresh blood":
                                                            await this.writeInput("record", "c", 249);
                                                            break;
                                                        case "active bleeding":
                                                            await this.writeInput("record", "c", 235);
                                                            break;
                                                    }
                                                } else if ([584].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hematin":
                                                            await this.writeInput("record", "c", 247);
                                                            break;
                                                        case "fresh blood":
                                                            await this.writeInput("record", "c", 249);
                                                            break;
                                                        case "active bleeding":
                                                            await this.writeInput("record", "c", 235);
                                                            break;
                                                    }
                                                } else if ([585].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hematin":
                                                            await this.writeInput("record", "c", 247);
                                                            break;
                                                        case "tarry stool":
                                                            await this.writeInput("record", "c", 248);
                                                            break;
                                                        case "active bleeding":
                                                            await this.writeInput("record", "c", 235);
                                                            break;
                                                    }
                                                } else if ([586].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hematin":
                                                            await this.writeInput("record", "c", 247);
                                                            break;
                                                        case "tarry stool":
                                                            await this.writeInput("record", "c", 248);
                                                            break;
                                                        case "fresh blood":
                                                            await this.writeInput("record", "c", 249);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "dist":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 5, 9, 10, 15, 21, 22, 39, 40, 41, 42, 43, 45, 46, 47, 51, 540, 559, 565, 572, 573, 574, 587, 588, 592, 593, 594, 597, 668].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(voiceInput)) {
                                                        await this.writeInput("record", "n", voiceInput);
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 554, 557, 558, 559, 560, 561, 548, 37, 564, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 595, 596, 594, 597, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 649, 650, 651, 661, 662, 663, 664, 665, 666].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(voiceInput)) {
                                                        await this.writeInput("record", "n", voiceInput);
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "earlyAdv":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([4, 11, 15, 16].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "early":
                                                            await this.writeInput("record", "s", "256");
                                                            break;
                                                        case "advanced":
                                                            await this.writeInput("record", "s", "257");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "finding":
                                        checkInt = /^[0-9]*[1-9][0-9]*$/
                                        if (checkInt.test(voiceInput) && Number(voiceInput) > 0) {   
                                            // specific finding which is exisit                                                         
                                            if (this.getExaminationData.findings[(Number(voiceInput) - 1)]) {
                                                
                                                // 設定 nowFindingIndex
                                                this.setHiddenParameter((Number(voiceInput) - 1), 0);
                                                this.setStoreHiddenParameter({
                                                    target: 'nowLocationsIndex',
                                                    value: 0
                                                });

                                                this.setStoreHiddenParameter({
                                                    target: 'over',
                                                    value: false
                                                });
                                            }
                                        } else {
                                            if (voiceInput === 'findingNet') {
                                                voiceInput = 'net'
                                            }
                                            // new finding
                                            let uiNumber
                                            let uiMap = this.findUiMap
                                            switch(this.examination_typeID) {
                                                case 1:
                                                    uiNumber = uiMap["Gastroscopy"][voiceInput]
                                                    break
                                                case 2:
                                                    uiNumber = uiMap["Colonoscopy"][voiceInput]
                                                    break
                                                case 3:
                                                    uiNumber = uiMap["Sigmoidoscopy"][voiceInput]
                                                    break
                                            }


                                            if (uiNumber) {   
                                                await this.$store.dispatch('examinations/addFinding', this.getExaminationData.examination.id).then(async () => {
                                                    if(voiceInput){
                                                        let voiceInputResult = voiceInput;
                                                        if (!GUIDE_STEPS[[MapUIforFinding.Colonoscopy['tubular adenoma']]].includes(voiceInputResult)) {
                                                            this.musicPlay(`${voiceInputResult}`)
                                                        }
                                                        setTimeout(() => { this.musicPlay('success'); }, 300);                            
                                                    }           
                                                    // 設定 nowFindingIndex
                                                    const nowFindingIndex = this.getExaminationData.findings.length - 1
                                                    this.setHiddenParameter(nowFindingIndex, 0);
                                                    this.setStoreHiddenParameter({
                                                        target: 'over',
                                                        value: false
                                                    });
                                                    if (this.checkInputExistAndSetFocuse("finding", "finding", "f")) {
                                                        await this.writeInput("finding", "s", uiNumber);
                                                    }
                                                });
                                                
                                            }
                                        }

                                        resolve(); // here(7)
                                        break;
                                    case "form":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "f1":
                                                            await this.writeInput("record", "s", "258");
                                                            break;
                                                        case "f2":
                                                            await this.writeInput("record", "s", "259");
                                                            break;
                                                        case "f3":
                                                            await this.writeInput("record", "s", "260");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "forrest":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([7, 12, 21].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ia":
                                                            await this.writeInput("record", "s", "261");
                                                            break;
                                                        case "ib":
                                                            await this.writeInput("record", "s", "262");
                                                            break;
                                                        case "iia":
                                                            await this.writeInput("record", "s", "263");
                                                            break;
                                                        case "iib":
                                                            await this.writeInput("record", "s", "264");
                                                            break;
                                                        case "iic":
                                                            await this.writeInput("record", "s", "265");
                                                            break;
                                                        case "iii":
                                                            await this.writeInput("record", "s", "266");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "grade":
                                        switch (Number(this.examination_typeID)) {
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([34, 575, 576, 577, 648].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    const obj = {
                                                        "1": "267",
                                                        "i": "267",
                                                        "2": "268",
                                                        "ii": "268",
                                                        "3": "269",
                                                        "iii": "269",
                                                        "4": "270",
                                                        "iv": "270",
                                                    }
                                                    if (obj[voiceInput]) {
                                                        await this.writeInput("record", "s", obj[voiceInput]);
                                                    }
                                                
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "hill":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([3].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "i":
                                                            await this.writeInput("record", "s", "271");
                                                            break;
                                                        case "ii":
                                                            await this.writeInput("record", "s", "272");
                                                            break;
                                                        case "iii":
                                                            await this.writeInput("record", "s", "273");
                                                            break;
                                                        case "iv":
                                                            await this.writeInput("record", "s", "274");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "invaDep":
                                        switch (Number(this.examination_typeID)) {}
                                        resolve(); // here(7)
                                        break;
                                    case "jes":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([4, 15].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "a":
                                                            await this.writeInput("record", "s", "278");
                                                            break;
                                                        case "b1":
                                                            await this.writeInput("record", "s", "279");
                                                            break;
                                                        case "b2":
                                                            await this.writeInput("record", "s", "280");
                                                            break;
                                                        case "b3":
                                                            await this.writeInput("record", "s", "281");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "jnet":
                                        switch (Number(this.examination_typeID)) {
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "1":
                                                            await this.writeInput("record", "s", "282");
                                                            break;
                                                        case "2a":
                                                            await this.writeInput("record", "s", "283");
                                                            break;
                                                        case "2b":
                                                            await this.writeInput("record", "s", "284");
                                                            break;
                                                        case "3":
                                                            await this.writeInput("record", "s", "285");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "kudo":
                                        switch (Number(this.examination_typeID)) {
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "i":
                                                            await this.writeInput("record", "s", "286");
                                                            break;
                                                        case "1":
                                                            await this.writeInput("record", "s", "286");
                                                            break;
                                                        case "ii":
                                                            await this.writeInput("record", "s", "287");
                                                            break;
                                                        case "2":
                                                            await this.writeInput("record", "s", "287");
                                                            break;
                                                        case "iiis":
                                                            await this.writeInput("record", "s", "288");
                                                            break;
                                                        case "iiil":
                                                            await this.writeInput("record", "s", "289");
                                                            break;
                                                        case "iv":
                                                            await this.writeInput("record", "s", "290");
                                                            break;
                                                         case "4":
                                                            await this.writeInput("record", "s", "290");
                                                            break;
                                                        case "v":
                                                            await this.writeInput("record", "s", "291");
                                                            break;
                                                        case "5":
                                                            await this.writeInput("record", "s", "291");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "laGrade":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 565].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "a":
                                                            await this.writeInput("record", "s", "292");
                                                            break;
                                                        case "b":
                                                            await this.writeInput("record", "s", "293");
                                                            break;
                                                        case "c":
                                                            await this.writeInput("record", "s", "294");
                                                            break;
                                                        case "d":
                                                            await this.writeInput("record", "s", "295");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "loca":
                                        const obj = {
                                            le: {
                                                esophagus: "630",
                                                ecj: "300"
                                            },
                                            ls: {
                                                antrum: "301", 
                                                "low-body": "302", 
                                                "mid-body": "303", 
                                                "high-body": "304", 
                                                angle: "305", 
                                                cardia: "306", 
                                                fundus: "307", 
                                                body: "590", 
                                                "upper-body": "591"
                                            },
                                            lsv: {
                                                "high-body": "304", 
                                                angle: "305", 
                                                cardia: "306",
                                                "upper-body": "591"
                                            },
                                            ld1: {
                                                bulb: "308", 
                                                sda: "309", 
                                                "2nd portion": "310", 
                                                "papilla": "528"
                                            },
                                            ld2: {
                                                bulb: "308", 
                                                sda: "309", 
                                                "2nd portion": "310", 
                                            },
                                        }
                                        const currentFindingId = Number(this.getFinding.questions.finding.value)
                                        if (Number(this.examination_typeID) === 1 && currentFindingId !== 3) {
                                            checkInt = /^[0-9]*[1-9][0-9]*$/
                                            if (checkInt.test(voiceInput) && Number(voiceInput) > 0) {
                                                if (this.examinationLocations[(Number(voiceInput) - 1)]) {
                                                    // 設定 nowLocationsIndex
                                                    this.setStoreHiddenParameter({
                                                        target: 'nowLocationsIndex',
                                                        value: (Number(voiceInput) - 1)
                                                    });
                                                    // 設定 focus
                                                    this.setStoreHiddenParameter({
                                                        target: 'focus',
                                                        value: 'loca'
                                                    });
                                                    resolve();
                                                }
                                            }
                                        }


                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 5, 15, 565, 668].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if(obj.le[voiceInput]){ 
                                                        await this.writeInput("record", "s", obj.le[voiceInput]);
                                                    }
                                                } else if ([7, 8, 11, 16, 566, 567, 568, 569, 570, 571, 667].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "antrum":
                                                            await this.writeInput("record", "s", "301");
                                                            break;
                                                        case "low-body":
                                                            await this.writeInput("record", "s", "302");
                                                            break;
                                                        case "mid-body":
                                                            await this.writeInput("record", "s", "303");
                                                            break;
                                                        case "high-body":
                                                            await this.writeInput("record", "s", "304");
                                                            break;
                                                        case "angle":
                                                            await this.writeInput("record", "s", "305");
                                                            break;
                                                        case "cardia":
                                                            await this.writeInput("record", "s", "306");
                                                            break;
                                                        case "fundus":
                                                            await this.writeInput("record", "s", "307");
                                                            break;
                                                        case "body":
                                                            await this.writeInput("record", "s", "590");
                                                            break;
                                                        case "upper-body":
                                                            await this.writeInput("record", "s", "591");
                                                            break;
                                                        default:
                                                            checkInt = /^[0-9]*[1-9][0-9]*$/
                                                            if (checkInt.test(voiceInput) && Number(voiceInput) > 0) {
                                                                if (this.examinationLocations[(Number(voiceInput) - 1)]) {
                                                                    // 設定 nowLocationsIndex
                                                                    this.setStoreHiddenParameter({
                                                                        target: 'nowLocationsIndex',
                                                                        value: (Number(voiceInput) - 1)
                                                                    });
                                                                    // 設定 focus
                                                                    this.setStoreHiddenParameter({
                                                                        target: 'focus',
                                                                        value: 'loca'
                                                                    });
                                                                    resolve();
                                                                }
                                                            }
                                                    }
                                                } else if ([9, 10, 39, 40, 41, 42, 43, 45, 46, 47, 540, 572, 573, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    let keys = ['le', 'ls', 'ld2'];
                                                    for (let key of keys) {
                                                        if (obj[key][voiceInput]) {
                                                            await this.writeInput("record", "s", obj[key][voiceInput]);
                                                            break;
                                                        }
                                                    }
                                                } else if ([12, 13, 14].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "bulb":
                                                            await this.writeInput("record", "s", "308");
                                                            break;
                                                        case "2nd portion":
                                                            await this.writeInput("record", "s", "310");
                                                            break;
                                                        case "sda":
                                                            await this.writeInput("record", "s", "309");
                                                            break;
                                                        case "papilla":
                                                            await this.writeInput("record", "s", "528");
                                                            break;
                                                        default:
                                                            checkInt = /^[0-9]*[1-9][0-9]*$/
                                                            if (checkInt.test(voiceInput) && Number(voiceInput) > 0) {
                                                                if (this.examinationLocations[(Number(voiceInput) - 1)]) {
                                                                    // 設定 nowLocationsIndex
                                                                    this.setStoreHiddenParameter({
                                                                        target: 'nowLocationsIndex',
                                                                        value: (Number(voiceInput) - 1)
                                                                    });
                                                                    // 設定 focus
                                                                    this.setStoreHiddenParameter({
                                                                        target: 'focus',
                                                                        value: 'loca'
                                                                    });
                                                                    resolve();
                                                                }
                                                            }
                                                    }
                                                } else if ([6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "cardia":
                                                            await this.writeInput("record", "s", "306");
                                                            break;
                                                        case "fundus":
                                                            await this.writeInput("record", "s", "307");
                                                            break;
                                                        case "high-body":
                                                            await this.writeInput("record", "s", "304");
                                                            break;
                                                        case "upper-body":
                                                            await this.writeInput("record", "s", "591");
                                                            break;
                                                        default:
                                                            checkInt = /^[0-9]*[1-9][0-9]*$/
                                                            if (checkInt.test(voiceInput) && Number(voiceInput) > 0) {
                                                                if (this.examinationLocations[(Number(voiceInput) - 1)]) {
                                                                    // 設定 nowLocationsIndex
                                                                    this.setStoreHiddenParameter({
                                                                        target: 'nowLocationsIndex',
                                                                        value: (Number(voiceInput) - 1)
                                                                    });
                                                                    // 設定 focus
                                                                    this.setStoreHiddenParameter({
                                                                        target: 'focus',
                                                                        value: 'loca'
                                                                    });
                                                                    resolve();
                                                                }
                                                            }
                                                    }
                                                } else if ([21, 22, 51, 559, 587, 588, 592, 593, 594, 597, 647, 650, 665, 666].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    let keys = ['le', 'ls', 'ld1'];
                                                    for (let key of keys) {
                                                        if (obj[key][voiceInput]) {
                                                            await this.writeInput("record", "s", obj[key][voiceInput]);
                                                            break;
                                                        }
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 554, 557, 558, 559, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 595, 596, 594, 597, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 649, 650, 651, 661, 662, 663, 664, 665, 666].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "terminal ileum":
                                                            await this.writeInput("record", "s", "312");
                                                            break;
                                                        case "ileocecal valve":
                                                            await this.writeInput("record", "s", "313");
                                                            break;
                                                        case "appendix orifice":
                                                            await this.writeInput("record", "s", "314");
                                                            break;
                                                        case "cecum":
                                                            await this.writeInput("record", "s", "315");
                                                            break;
                                                        case "ascending colon":
                                                            await this.writeInput("record", "s", "316");
                                                            break;
                                                        case "hepatic flexure":
                                                            await this.writeInput("record", "s", "317");
                                                            break;
                                                        case "transverse colon":
                                                            await this.writeInput("record", "s", "318");
                                                            break;
                                                        case "splenic flexure":
                                                            await this.writeInput("record", "s", "319");
                                                            break;
                                                        case "descending colon":
                                                            await this.writeInput("record", "s", "320");
                                                            break;
                                                        case "sigmoid colon":
                                                            await this.writeInput("record", "s", "321");
                                                            break;
                                                        case "rs junction":
                                                            await this.writeInput("record", "s", "322");
                                                            break;
                                                        case "rectum":
                                                            await this.writeInput("record", "s", "323");
                                                            break;
                                                        case "anus":
                                                            await this.writeInput("record", "s", "529");
                                                            break;
                                                        case "all segments of colon":
                                                            await this.writeInput("record", "s", "530");
                                                            break;
                                                        case "anastomosis":
                                                            await this.writeInput("record", "s", "563");
                                                            break;
                                                        case "anal retroflex":
                                                            await this.writeInput("record", "s", "624");
                                                            break;
                                                        default:
                                                            checkInt = /^[0-9]*[1-9][0-9]*$/
                                                            if (checkInt.test(voiceInput) && Number(voiceInput) > 0) {
                                                                if (this.examinationLocations[(Number(voiceInput) - 1)]) {
                                                                    // 設定 nowLocationsIndex
                                                                    this.setStoreHiddenParameter({
                                                                        target: 'nowLocationsIndex',
                                                                        value: (Number(voiceInput) - 1)
                                                                    });
                                                                    // 設定 focus
                                                                    this.setStoreHiddenParameter({
                                                                        target: 'focus',
                                                                        value: 'loca'
                                                                    });
                                                                    resolve();
                                                                }
                                                            }
                                                    }
                                                } else if ([34, 648].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "internal":
                                                            await this.writeInput("record", "s", "502");
                                                            break;
                                                        case "external":
                                                            await this.writeInput("record", "s", "503");
                                                            break;
                                                        case "mixed":
                                                            await this.writeInput("record", "s", "504");
                                                            break;
                                                        default:
                                                            checkInt = /^[0-9]*[1-9][0-9]*$/
                                                            if (checkInt.test(voiceInput) && Number(voiceInput) > 0) {
                                                                if (this.examinationLocations[(Number(voiceInput) - 1)]) {
                                                                    // 設定 nowLocationsIndex
                                                                    this.setStoreHiddenParameter({
                                                                        target: 'nowLocationsIndex',
                                                                        value: (Number(voiceInput) - 1)
                                                                    });
                                                                    // 設定 focus
                                                                    this.setStoreHiddenParameter({
                                                                        target: 'focus',
                                                                        value: 'loca'
                                                                    });
                                                                    resolve();
                                                                }
                                                            }
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "hotSnare":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([9, 10, 11, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hot snare":
                                                            await this.writeInput("record", "c2", 63);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 553, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hot snare":
                                                            await this.writeInput("record", "c2", 63);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "emer":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "emergent":
                                                            await this.writeInput("record", "c2", 64);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // Colonoscopy
                                                if ([34, 575, 576, 577, 648].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "emergent":
                                                            await this.writeInput("record", "c2", 64);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "mayo":
                                        switch (Number(this.examination_typeID)) {
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 552, 638, 639, 641, 644, 645].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "0":
                                                            await this.writeInput("record", "s", "329");
                                                            break;
                                                        case "1":
                                                            await this.writeInput("record", "s", "330");
                                                            break;
                                                        case "2":
                                                            await this.writeInput("record", "s", "331");
                                                            break;
                                                        case "3":
                                                            await this.writeInput("record", "s", "332");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "ipcl":
                                        switch(voiceInput){
                                            case "ii":
                                                await this.writeInput("record", "s", "610");
                                                break;
                                            case "iii":
                                                await this.writeInput("record", "s", "611");
                                                break;
                                            case "iv":
                                                await this.writeInput("record", "s", "612");
                                                break;
                                            case "v1":
                                                await this.writeInput("record", "s", "613");
                                                break;
                                            case "v2":
                                                await this.writeInput("record", "s", "614");
                                                break;
                                            case "v3":
                                                await this.writeInput("record", "s", "615");
                                                break;
                                            case "v4":
                                                await this.writeInput("record", "s", "616");
                                                break;
                                        }
                                        resolve();
                                        break;
                                    case "morph":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([4, 15].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ip":
                                                            await this.writeInput("record", "s", "345");
                                                            break;
                                                        case "is":
                                                            await this.writeInput("record", "s", "346");
                                                            break;
                                                        case "iia":
                                                            await this.writeInput("record", "s", "347");
                                                            break;
                                                        case "iib":
                                                            await this.writeInput("record", "s", "348");
                                                            break;
                                                        case "iic":
                                                            await this.writeInput("record", "s", "349");
                                                            break;
                                                        case "iia+c":
                                                            await this.writeInput("record", "s", "340");
                                                            break;
                                                        case "iic+a":
                                                            await this.writeInput("record", "s", "341");
                                                            break;
                                                        case "iic+iii":
                                                            await this.writeInput("record", "s", "350");
                                                            break;
                                                        case "iii":
                                                            await this.writeInput("record", "s", "351");
                                                            break;
                                                        case "i-sp":
                                                            await this.writeInput("record", "s", "589");
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "0":
                                                            await this.writeInput("record", "s", "333");
                                                            break;
                                                        case "0-ip":
                                                            await this.writeInput("record", "s", "334");
                                                            break;
                                                        case "0-is":
                                                            await this.writeInput("record", "s", "335");
                                                            break;
                                                        case "0-iia":
                                                            await this.writeInput("record", "s", "336");
                                                            break;
                                                        case "0-iib":
                                                            await this.writeInput("record", "s", "337");
                                                            break;
                                                        case "0-iic":
                                                            await this.writeInput("record", "s", "338");
                                                            break;
                                                        case "0-iii":
                                                            await this.writeInput("record", "s", "339");
                                                            break;
                                                        case "iia+c":
                                                            await this.writeInput("record", "s", "340");
                                                            break;
                                                        case "iic+a":
                                                            await this.writeInput("record", "s", "341");
                                                            break;
                                                        case "iic+iii":
                                                            await this.writeInput("record", "s", "342");
                                                            break;
                                                        case "lst-g":
                                                            await this.writeInput("record", "s", "343");
                                                            break;
                                                        case "lst-ng":
                                                            await this.writeInput("record", "s", "344");
                                                            break;
                                                        case "i-sp":
                                                            await this.writeInput("record", "s", "589");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "nice":
                                        switch (Number(this.examination_typeID)) {
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "1":
                                                            await this.writeInput("record", "s", "352");
                                                            break;
                                                        case "2":
                                                            await this.writeInput("record", "s", "353");
                                                            break;
                                                        case "3":
                                                            await this.writeInput("record", "s", "354");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "number":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([4, 7, 9, 10, 11, 12, 15, 16, 21, 22, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "several":
                                                            await this.writeInput("finding", "t", voiceInput);
                                                            break;
                                                        case "multiple":
                                                            await this.writeInput("finding", "t", voiceInput);
                                                            break;
                                                        default:
                                                            checkInt = /^[0-9]*[1-9][0-9]*$/
                                                            if (checkInt.test(voiceInput)) {
                                                                await this.writeInput("finding", "t", voiceInput);
                                                            }
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 553, 557, 560, 548, 642, 643, 649, 661, 662, 663, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "several":
                                                            await this.writeInput("finding", "t", voiceInput);
                                                            break;
                                                        case "multiple":
                                                            await this.writeInput("finding", "t", voiceInput);
                                                            break;
                                                        default:
                                                            checkInt = /^[0-9]*[1-9][0-9]*$/
                                                            if (checkInt.test(voiceInput)) {
                                                                await this.writeInput("finding", "t", voiceInput);
                                                            }
                                                            break;
                                                    }
                                                } else if ([651]) {
                                                    switch (voiceInput) {
                                                        case "multiple":
                                                            await this.writeInput("finding", "t", voiceInput);
                                                            break;
                                                        default:
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "object":
                                        switch (Number(this.examination_typeID)) {
                                            case 1:
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([51].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "t", voiceInput);
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "orien":
                                        const orienObj = {
                                            aw: "359",
                                            pw: "360",
                                            gc: "361",
                                            lc: "362",
                                            cervical: "631",
                                            upper: "632",
                                            middle: "633",
                                            lower: "634",
                                        }
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 5, 15, 565, 668].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "cervical":
                                                        case "upper":
                                                        case "middle":
                                                        case "lower":
                                                            await this.writeInput("record", "s", orienObj[voiceInput]);
                                                            break;
                                                    }
                                                } else if ([6, 12, 13, 14, 667].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "aw":
                                                        case "pw":
                                                        case "gc":
                                                        case "lc":
                                                            await this.writeInput("record", "s", orienObj[voiceInput]);
                                                            break;
                                                    }
                                                } else if ([
                                                        7, 8, 9, 10, 11, 16, 21, 22, 39, 40, 41, 42, 43, 45, 46, 47, 51, 540,
                                                        559, 566, 567, 568, 569, 570, 571, 572, 573, 574, 587, 588, 592, 593, 594,
                                                        597,
                                                    ].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (orienObj[voiceInput]) {
                                                        await this.writeInput("record", "s", orienObj[voiceInput]);
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 554, 557, 558, 559, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 595, 596, 594, 597, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 649, 650, 651, 661, 662, 663, 664, 665, 666].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "proximal":
                                                            await this.writeInput("record", "s", "324");
                                                            break;
                                                        case "middle":
                                                            await this.writeInput("record", "s", "325");
                                                            break;
                                                        case "distal":
                                                            await this.writeInput("record", "s", "326");
                                                            break;
                                                        case "upper":
                                                            await this.writeInput("record", "s", "327");
                                                            break;
                                                        case "lower":
                                                            await this.writeInput("record", "s", "328");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "paris":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([11, 16].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "0-i":
                                                            await this.writeInput("record", "s", "363");
                                                            break;
                                                        case "0-iia":
                                                            await this.writeInput("record", "s", "364");
                                                            break;
                                                        case "0-iib":
                                                            await this.writeInput("record", "s", "365");
                                                            break;
                                                        case "0-iic":
                                                            await this.writeInput("record", "s", "366");
                                                            break;
                                                        case "0-iii":
                                                            await this.writeInput("record", "s", "367");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "prgC":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(voiceInput)) {
                                                        await this.writeInput("record", "n", voiceInput);
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "prgM":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(voiceInput)) {
                                                        await this.writeInput("record", "n", voiceInput);
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "rcs":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "negative":
                                                            await this.writeInput("record", "c", 428);
                                                            break;
                                                        case "red whale marking":
                                                            await this.writeInput("record", "c", 429);
                                                            break;
                                                        case "cherry-red spot":
                                                            await this.writeInput("record", "c", 430);
                                                            break;
                                                        case "positive":
                                                            await this.writeInput("record", "c", 605);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "removed":
                                        switch (Number(this.examination_typeID)) {
                                            case 1:
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([51].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "yes":
                                                            await this.writeInput("record", "s", "431");
                                                            break;
                                                        case "no":
                                                            await this.writeInput("record", "s", "432");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "ro":
                                        switch (Number(this.examination_typeID)) {
                                            
                                            case 1: // Gastroscopy
                                                if ( [1].indexOf( Number(this.getFinding.questions.finding.value) ) != -1 ) {
                                                    switch (voiceInput) {
                                                        case "barrett's esophagus":
                                                            await this.writeInput("record", "s", "606");
                                                            break;
                                                        case "hiatal hernia":
                                                            await this.writeInput("record", "s", "607");
                                                            break;
                                                        }
                                                } else if ([7, 12, 21].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "early cancer":
                                                            await this.writeInput("record", "s", "433");
                                                            break;
                                                        case "advanced cancer":
                                                            await this.writeInput("record", "s", "434");
                                                            break;
                                                    }
                                                } else if ([8, 667].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "acute gastritis":
                                                            await this.writeInput("record", "c", 453);
                                                            break;
                                                        case "hemorrhagic gastritis":
                                                            await this.writeInput("record", "c", 454);
                                                            break;
                                                        case "chronic gastritis":
                                                            await this.writeInput("record", "c", 455);
                                                            break;
                                                        case "superficial gastritis":
                                                            await this.writeInput("record", "c", 456);
                                                            break;
                                                        case "atrophic gastritis":
                                                            await this.writeInput("record", "c", 457);
                                                            break;
                                                        case "portal hypertensive gastropathy":
                                                            await this.writeInput("record", "c", 458);
                                                            break;
                                                    }
                                                } else if ([9].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "fundic gland polyp":
                                                            await this.writeInput("record", "c", 462);
                                                            break;
                                                        case "adenomatous polyp":
                                                            await this.writeInput("record", "c", 463);
                                                            break;
                                                        case "hyperplastic polyp":
                                                            await this.writeInput("record", "c", 464);
                                                            break;
                                                        case "gastric ca":
                                                            await this.writeInput("record", "c", 465);
                                                            break;
                                                        case "fap":
                                                            await this.writeInput("record", "c", 466);
                                                            break;
                                                    }
                                                } else if ([10].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "lipoma":
                                                            await this.writeInput("record", "c", 469);
                                                            break;
                                                        case "carcinoid tumor":
                                                            await this.writeInput("record", "c", 470);
                                                            break;
                                                        case "lymphoma":
                                                            await this.writeInput("record", "c", 476);
                                                            break;
                                                        case "hemangioma":
                                                            await this.writeInput("record", "c", 477);
                                                            break;
                                                        case "gist":
                                                            await this.writeInput("record", "c", 471);
                                                            break;
                                                        case "metastatic tumor":
                                                            await this.writeInput("record", "c", 472);
                                                            break;
                                                        case "extraluminal impression":
                                                            await this.writeInput("record", "c", 473);
                                                            break;
                                                        case "net":
                                                            await this.writeInput("record", "c", 475);
                                                            break;
                                                    }
                                                } else if ([2].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "dysplasia":
                                                            await this.writeInput("record", "s", "488");
                                                            break;
                                                        case "cancer":
                                                            await this.writeInput("record", "s", "489");
                                                            break;
                                                    }
                                                } else if ([22].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "early cancer":
                                                            await this.writeInput("record", "c", 433);
                                                            break;
                                                        case "advanced cancer":
                                                            await this.writeInput("record", "c", 434);
                                                            break;
                                                        case "acute gastritis":
                                                            await this.writeInput("record", "c", 453);
                                                            break;
                                                        case "hemorrhagic gastritis":
                                                            await this.writeInput("record", "c", 454);
                                                            break;
                                                        case "chronic gastritis":
                                                            await this.writeInput("record", "c", 455);
                                                            break;
                                                        case "superficial gastritis":
                                                            await this.writeInput("record", "c", 456);
                                                            break;
                                                        case "atrophic gastritis":
                                                            await this.writeInput("record", "c", 457);
                                                            break;
                                                        case "portal hypertensive gastropathy":
                                                            await this.writeInput("record", "c", 458);
                                                            break;
                                                    }
                                                } else if ([566].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hemorrhagic gastritis":
                                                            await this.writeInput("record", "c", 454);
                                                            break;
                                                        case "chronic gastritis":
                                                            await this.writeInput("record", "c", 455);
                                                            break;
                                                        case "superficial gastritis":
                                                            await this.writeInput("record", "c", 456);
                                                            break;
                                                        case "atrophic gastritis":
                                                            await this.writeInput("record", "c", 457);
                                                            break;
                                                        case "portal hypertensive gastropathy":
                                                            await this.writeInput("record", "c", 458);
                                                            break;
                                                    }
                                                } else if ([567].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "acute gastritis":
                                                            await this.writeInput("record", "c", 453);
                                                            break;
                                                        case "chronic gastritis":
                                                            await this.writeInput("record", "c", 455);
                                                            break;
                                                        case "superficial gastritis":
                                                            await this.writeInput("record", "c", 456);
                                                            break;
                                                        case "atrophic gastritis":
                                                            await this.writeInput("record", "c", 457);
                                                            break;
                                                        case "portal hypertensive gastropathy":
                                                            await this.writeInput("record", "c", 458);
                                                            break;
                                                    }
                                                } else if ([568].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "acute gastritis":
                                                            await this.writeInput("record", "c", 453);
                                                            break;
                                                        case "hemorrhagic gastritis":
                                                            await this.writeInput("record", "c", 454);
                                                            break;
                                                        case "superficial gastritis":
                                                            await this.writeInput("record", "c", 456);
                                                            break;
                                                        case "atrophic gastritis":
                                                            await this.writeInput("record", "c", 457);
                                                            break;
                                                        case "portal hypertensive gastropathy":
                                                            await this.writeInput("record", "c", 458);
                                                            break;
                                                    }
                                                } else if ([569].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "acute gastritis":
                                                            await this.writeInput("record", "c", 453);
                                                            break;
                                                        case "hemorrhagic gastritis":
                                                            await this.writeInput("record", "c", 454);
                                                            break;
                                                        case "chronic gastritis":
                                                            await this.writeInput("record", "c", 455);
                                                            break;
                                                        case "atrophic gastritis":
                                                            await this.writeInput("record", "c", 457);
                                                            break;
                                                        case "portal hypertensive gastropathy":
                                                            await this.writeInput("record", "c", 458);
                                                            break;
                                                    }
                                                } else if ([570].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "acute gastritis":
                                                            await this.writeInput("record", "c", 453);
                                                            break;
                                                        case "hemorrhagic gastritis":
                                                            await this.writeInput("record", "c", 454);
                                                            break;
                                                        case "chronic gastritis":
                                                            await this.writeInput("record", "c", 455);
                                                            break;
                                                        case "superficial gastritis":
                                                            await this.writeInput("record", "c", 456);
                                                            break;
                                                        case "portal hypertensive gastropathy":
                                                            await this.writeInput("record", "c", 458);
                                                            break;
                                                    }
                                                } else if ([571].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "acute gastritis":
                                                            await this.writeInput("record", "c", 453);
                                                            break;
                                                        case "hemorrhagic gastritis":
                                                            await this.writeInput("record", "c", 454);
                                                            break;
                                                        case "chronic gastritis":
                                                            await this.writeInput("record", "c", 455);
                                                            break;
                                                        case "superficial gastritis":
                                                            await this.writeInput("record", "c", 456);
                                                            break;
                                                        case "atrophic gastritis":
                                                            await this.writeInput("record", "c", 457);
                                                            break;
                                                    }
                                                } else if ([572].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "adenomatous polyp":
                                                            await this.writeInput("record", "c", 463);
                                                            break;
                                                        case "hyperplastic polyp":
                                                            await this.writeInput("record", "c", 464);
                                                            break;
                                                        case "gastric ca":
                                                            await this.writeInput("record", "c", 465);
                                                            break;
                                                        case "fap":
                                                            await this.writeInput("record", "c", 466);
                                                            break;
                                                    }
                                                } else if ([573].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "fundic gland polyp":
                                                            await this.writeInput("record", "c", 462);
                                                            break;
                                                        case "hyperplastic polyp":
                                                            await this.writeInput("record", "c", 464);
                                                            break;
                                                        case "gastric ca":
                                                            await this.writeInput("record", "c", 465);
                                                            break;
                                                        case "fap":
                                                            await this.writeInput("record", "c", 466);
                                                            break;
                                                    }
                                                } else if ([540].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "fundic gland polyp":
                                                            await this.writeInput("record", "c", 462);
                                                            break;
                                                        case "adenomatous polyp":
                                                            await this.writeInput("record", "c", 463);
                                                            break;
                                                        case "gastric ca":
                                                            await this.writeInput("record", "c", 465);
                                                            break;
                                                        case "fap":
                                                            await this.writeInput("record", "c", 466);
                                                            break;
                                                    }
                                                } else if ([574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "fundic gland polyp":
                                                            await this.writeInput("record", "c", 462);
                                                            break;
                                                        case "adenomatous polyp":
                                                            await this.writeInput("record", "c", 463);
                                                            break;
                                                        case "hyperplastic polyp":
                                                            await this.writeInput("record", "c", 464);
                                                            break;
                                                        case "gastric ca":
                                                            await this.writeInput("record", "c", 465);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3:
                                                if ([21, 22, 551, 662].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "proctitis":
                                                            await this.writeInput("record", "c", 435);
                                                            break;
                                                        case "malignancy":
                                                            await this.writeInput("record", "c", 436);
                                                            break;
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "infectious colitis":
                                                            await this.writeInput("record", "c", 440);
                                                            break;
                                                        case "tb colitis":
                                                            await this.writeInput("record", "c", 441);
                                                            break;
                                                        case "amoebic colitis":
                                                            await this.writeInput("record", "c", 442);
                                                            break;
                                                        case "radiation colitis":
                                                            await this.writeInput("record", "c", 443);
                                                            break;
                                                        case "ischemic colitis":
                                                            await this.writeInput("record", "c", 444);
                                                            break;
                                                        case "pseudomembranous colitis":
                                                            await this.writeInput("record", "c", 445);
                                                            break;
                                                    }
                                                } else if ([23, 552, 638, 639, 641, 644].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "pseudomembranous colitis":
                                                            await this.writeInput("record", "c", 445);
                                                            break;
                                                        case "ischemic colitis":
                                                            await this.writeInput("record", "c", 444);
                                                            break;
                                                        case "infectious colitis":
                                                            await this.writeInput("record", "c", 440);
                                                            break;
                                                        case "tb colitis":
                                                            await this.writeInput("record", "c", 441);
                                                            break;
                                                        case "amoebic colitis":
                                                            await this.writeInput("record", "c", 442);
                                                            break;
                                                        case "radiation colitis":
                                                            await this.writeInput("record", "c", 443);
                                                            break;
                                                        case "proctitis":
                                                            await this.writeInput("record", "c", 435);
                                                            break;
                                                    }
                                                } else if ([35, 36].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "diverticulosis":
                                                            await this.writeInput("record", "c", 459);
                                                            break;
                                                        case "diverticulitis":
                                                            await this.writeInput("record", "c", 460);
                                                            break;
                                                        case "diverticular bleeding":
                                                            await this.writeInput("record", "c", 461);
                                                            break;
                                                    }
                                                } else if ([50].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "tb related lesion":
                                                            await this.writeInput("record", "c", 467);
                                                            break;
                                                        case "amoeba":
                                                            await this.writeInput("record", "c", 468);
                                                            break;
                                                    }
                                                } else if ([10].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "lipoma":
                                                            await this.writeInput("record", "c", 469);
                                                            break;
                                                        case "carcinoid tumor":
                                                            await this.writeInput("record", "c", 470);
                                                            break;
                                                        case "gist":
                                                            await this.writeInput("record", "c", 471);
                                                            break;
                                                        case "metastatic tumor":
                                                            await this.writeInput("record", "c", 472);
                                                            break;
                                                        case "extraluminal impression":
                                                            await this.writeInput("record", "c", 473);
                                                            break;
                                                        case "appendiceal tumor":
                                                            await this.writeInput("record", "c", 474);
                                                            break;
                                                        case "net":
                                                            await this.writeInput("record", "c", 475);
                                                            break;
                                                        case "lymphoma":
                                                            await this.writeInput("record", "c", 476);
                                                            break;
                                                        case "hemangioma":
                                                            await this.writeInput("record", "c", 477);
                                                            break;
                                                        case "lymphangioma":
                                                            await this.writeInput("record", "c", 562);
                                                            break;
                                                    }
                                                } else if ([48, 583, 584, 585, 586].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "upper gi":
                                                            await this.writeInput("record", "c", 484);
                                                            break;
                                                        case "lower gi":
                                                            await this.writeInput("record", "c", 485);
                                                            break;
                                                        case "occult bleeding":
                                                            await this.writeInput("record", "c", 486);
                                                            break;
                                                        case "unknown bleeder":
                                                            await this.writeInput("record", "c", 487);
                                                            break;
                                                    }
                                                } else if ([37].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "diverticulitis":
                                                            await this.writeInput("record", "s", "460");
                                                            break;
                                                        case "diverticular bleeding":
                                                            await this.writeInput("record", "s", "461");
                                                            break;
                                                    }
                                                } else if ([564].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "diverticulosis":
                                                            await this.writeInput("record", "s", "459");
                                                            break;
                                                        case "diverticular bleeding":
                                                            await this.writeInput("record", "s", "461");
                                                            break;
                                                    }
                                                } else if ([24].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "pseudomembranous colitis":
                                                            await this.writeInput("record", "c", 445);
                                                            break;
                                                        case "ischemic colitis":
                                                            await this.writeInput("record", "c", 444);
                                                            break;
                                                        case "infectious colitis":
                                                            await this.writeInput("record", "c", 440);
                                                            break;
                                                        case "tb colitis":
                                                            await this.writeInput("record", "c", 441);
                                                            break;
                                                        case "amoebic colitis":
                                                            await this.writeInput("record", "c", 442);
                                                            break;
                                                        case "radiation colitis":
                                                            await this.writeInput("record", "c", 443);
                                                            break;
                                                        case "proctitis":
                                                            await this.writeInput("record", "c", 435);
                                                            break;
                                                    }
                                                } else if ([25].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "pseudomembranous colitis":
                                                            await this.writeInput("record", "c", 445);
                                                            break;
                                                        case "ischemic colitis":
                                                            await this.writeInput("record", "c", 444);
                                                            break;
                                                        case "infectious colitis":
                                                            await this.writeInput("record", "c", 440);
                                                            break;
                                                        case "tb colitis":
                                                            await this.writeInput("record", "c", 441);
                                                            break;
                                                        case "amoebic colitis":
                                                            await this.writeInput("record", "c", 442);
                                                            break;
                                                        case "radiation colitis":
                                                            await this.writeInput("record", "c", 443);
                                                            break;
                                                        case "proctitis":
                                                            await this.writeInput("record", "c", 435);
                                                            break;
                                                    }
                                                } else if ([26, 645].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "pseudomembranous colitis":
                                                            await this.writeInput("record", "c", 445);
                                                            break;
                                                        case "ischemic colitis":
                                                            await this.writeInput("record", "c", 444);
                                                            break;
                                                        case "infectious colitis":
                                                            await this.writeInput("record", "c", 440);
                                                            break;
                                                        case "tb colitis":
                                                            await this.writeInput("record", "c", 441);
                                                            break;
                                                        case "amoebic colitis":
                                                            await this.writeInput("record", "c", 442);
                                                            break;
                                                        case "radiation colitis":
                                                            await this.writeInput("record", "c", 443);
                                                            break;
                                                        case "proctitis":
                                                            await this.writeInput("record", "c", 435);
                                                            break;
                                                    }
                                                } else if ([27].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "ischemic colitis":
                                                            await this.writeInput("record", "c", 444);
                                                            break;
                                                        case "infectious colitis":
                                                            await this.writeInput("record", "c", 440);
                                                            break;
                                                        case "tb colitis":
                                                            await this.writeInput("record", "c", 441);
                                                            break;
                                                        case "amoebic colitis":
                                                            await this.writeInput("record", "c", 442);
                                                            break;
                                                        case "radiation colitis":
                                                            await this.writeInput("record", "c", 443);
                                                            break;
                                                        case "proctitis":
                                                            await this.writeInput("record", "c", 435);
                                                            break;
                                                    }
                                                } else if ([28].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "pseudomembranous colitis":
                                                            await this.writeInput("record", "c", 445);
                                                            break;
                                                        case "infectious colitis":
                                                            await this.writeInput("record", "c", 440);
                                                            break;
                                                        case "tb colitis":
                                                            await this.writeInput("record", "c", 441);
                                                            break;
                                                        case "amoebic colitis":
                                                            await this.writeInput("record", "c", 442);
                                                            break;
                                                        case "radiation colitis":
                                                            await this.writeInput("record", "c", 443);
                                                            break;
                                                        case "proctitis":
                                                            await this.writeInput("record", "c", 435);
                                                            break;
                                                    }
                                                } else if ([29].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "pseudomembranous colitis":
                                                            await this.writeInput("record", "c", 445);
                                                            break;
                                                        case "ischemic colitis":
                                                            await this.writeInput("record", "c", 444);
                                                            break;
                                                        case "tb colitis":
                                                            await this.writeInput("record", "c", 441);
                                                            break;
                                                        case "amoebic colitis":
                                                            await this.writeInput("record", "c", 442);
                                                            break;
                                                        case "radiation colitis":
                                                            await this.writeInput("record", "c", 443);
                                                            break;
                                                        case "proctitis":
                                                            await this.writeInput("record", "c", 435);
                                                            break;
                                                    }
                                                } else if ([30].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "pseudomembranous colitis":
                                                            await this.writeInput("record", "c", 445);
                                                            break;
                                                        case "ischemic colitis":
                                                            await this.writeInput("record", "c", 444);
                                                            break;
                                                        case "infectious colitis":
                                                            await this.writeInput("record", "c", 440);
                                                            break;
                                                        case "amoebic colitis":
                                                            await this.writeInput("record", "c", 442);
                                                            break;
                                                        case "radiation colitis":
                                                            await this.writeInput("record", "c", 443);
                                                            break;
                                                        case "proctitis":
                                                            await this.writeInput("record", "c", 435);
                                                            break;
                                                    }
                                                } else if ([31].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "pseudomembranous colitis":
                                                            await this.writeInput("record", "c", 445);
                                                            break;
                                                        case "ischemic colitis":
                                                            await this.writeInput("record", "c", 444);
                                                            break;
                                                        case "infectious colitis":
                                                            await this.writeInput("record", "c", 440);
                                                            break;
                                                        case "tb colitis":
                                                            await this.writeInput("record", "c", 441);
                                                            break;
                                                        case "radiation colitis":
                                                            await this.writeInput("record", "c", 443);
                                                            break;
                                                        case "proctitis":
                                                            await this.writeInput("record", "c", 435);
                                                            break;
                                                    }
                                                } else if ([32].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "pseudomembranous colitis":
                                                            await this.writeInput("record", "c", 445);
                                                            break;
                                                        case "ischemic colitis":
                                                            await this.writeInput("record", "c", 444);
                                                            break;
                                                        case "infectious colitis":
                                                            await this.writeInput("record", "c", 440);
                                                            break;
                                                        case "tb colitis":
                                                            await this.writeInput("record", "c", 441);
                                                            break;
                                                        case "amoebic colitis":
                                                            await this.writeInput("record", "c", 442);
                                                            break;
                                                        case "proctitis":
                                                            await this.writeInput("record", "c", 435);
                                                            break;
                                                    }
                                                } else if ([33].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "ibd":
                                                            await this.writeInput("record", "c", 437);
                                                            break;
                                                        case "ulcerative colitis":
                                                            await this.writeInput("record", "c", 438);
                                                            break;
                                                        case "crohn's disease":
                                                            await this.writeInput("record", "c", 439);
                                                            break;
                                                        case "pseudomembranous colitis":
                                                            await this.writeInput("record", "c", 445);
                                                            break;
                                                        case "ischemic colitis":
                                                            await this.writeInput("record", "c", 444);
                                                            break;
                                                        case "infectious colitis":
                                                            await this.writeInput("record", "c", 440);
                                                            break;
                                                        case "tb colitis":
                                                            await this.writeInput("record", "c", 441);
                                                            break;
                                                        case "amoebic colitis":
                                                            await this.writeInput("record", "c", 442);
                                                            break;
                                                        case "radiation colitis":
                                                            await this.writeInput("record", "c", 443);
                                                            break;
                                                    }
                                                } else if ([578].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "diverticulosis":
                                                            await this.writeInput("record", "c", 459);
                                                            break;
                                                    }
                                                } else if ([579, 580].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "occult bleeding":
                                                            await this.writeInput("record", "c", 486);
                                                            break;
                                                        case "unknown bleeder":
                                                            await this.writeInput("record", "c", 487);
                                                            break;
                                                    }
                                                } else if ([581].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "upper gi":
                                                            await this.writeInput("record", "c", 484);
                                                            break;
                                                        case "lower gi":
                                                            await this.writeInput("record", "c", 485);
                                                            break;
                                                        case "unknown bleeder":
                                                            await this.writeInput("record", "c", 487);
                                                            break;
                                                    }
                                                } else if ([582].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "upper gi":
                                                            await this.writeInput("record", "c", 484);
                                                            break;
                                                        case "lower gi":
                                                            await this.writeInput("record", "c", 485);
                                                            break;
                                                        case "occult bleeding":
                                                            await this.writeInput("record", "c", 486);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "sign":
                                        switch (Number(this.examination_typeID)) {
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([35, 36, 37, 564, 578].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "bleeding":
                                                            await this.writeInput("record", "c", 490);
                                                            break;
                                                        case "inflammation":
                                                            await this.writeInput("record", "c", 491);
                                                            break;
                                                        case "bezoar":
                                                            await this.writeInput("record", "c", 492);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "size":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([4, 9, 10, 11, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574, 594, 597].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(parseInt(voiceInput))) {
                                                        await this.writeInput("record", "t", voiceInput);
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 553, 548, 594, 597, 642, 643, 649, 651, 661, 663, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(parseInt(voiceInput))) {
                                                        await this.writeInput("record", "t", voiceInput);
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "skipLesion":
                                        switch (Number(this.examination_typeID)) {
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 552, 638, 639, 641, 644, 645].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "yes":
                                                            await this.writeInput("record", "s", "494");
                                                            break;
                                                        case "no":
                                                            await this.writeInput("record", "s", "495");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "sorP":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([9, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "sessile":
                                                            await this.writeInput("record", "s", "517");
                                                            break;
                                                        case "pedunculated":
                                                            await this.writeInput("record", "s", "518");
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 540, 541, 542, 548, 642, 651, 661].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "sessile":
                                                            await this.writeInput("record", "s", "517");
                                                            break;
                                                        case "pedunculated":
                                                            await this.writeInput("record", "s", "518");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "sPat":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([11, 16].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "regular":
                                                            await this.writeInput("record", "s", "496");
                                                            break;
                                                        case "irregular":
                                                            await this.writeInput("record", "s", "497");
                                                            break;
                                                        case "absent":
                                                            await this.writeInput("record", "s", "498");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "takeNote":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([7, 9, 10, 12, 21, 22, 39, 40, 41, 42, 43, 45, 46, 47, 4, 15, 11, 13, 16, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "the most significant":
                                                            await this.writeInput("finding", "s", "499");
                                                            break;
                                                        case "each":
                                                            await this.writeInput("finding", "s", "500");
                                                            break;
                                                        case "overall":
                                                            await this.writeInput("finding", "s", "501");
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 21, 22, 540, 541, 542, 551, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 553, 548, 17, 18, 19, 20, 57, 543, 544, 545, 546, 547, 549, 550, 557, 642, 643, 649, 651, 661, 662, 663, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "the most significant":
                                                            await this.writeInput("finding", "s", "499");
                                                            break;
                                                        case "each":
                                                            await this.writeInput("finding", "s", "500");
                                                            break;
                                                        case "overall":
                                                            await this.writeInput("finding", "s", "501");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "ulcerStage":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([7, 12, 21].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "active":
                                                            await this.writeInput("record", "s", "505");
                                                            break;
                                                        case "healing":
                                                            await this.writeInput("record", "s", "506");
                                                            break;
                                                        case "scar":
                                                            await this.writeInput("record", "s", "507");
                                                            break;
                                                        case "a1":
                                                            await this.writeInput("record", "s", "508");
                                                            break;
                                                        case "a2":
                                                            await this.writeInput("record", "s", "509");
                                                            break;
                                                        case "h1":
                                                            await this.writeInput("record", "s", "510");
                                                            break;
                                                        case "h2":
                                                            await this.writeInput("record", "s", "511");
                                                            break;
                                                        case "s1":
                                                            await this.writeInput("record", "s", "512");
                                                            break;
                                                        case "s2":
                                                            await this.writeInput("record", "s", "513");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "vPat":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([11, 16].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "regular":
                                                            await this.writeInput("record", "s", "514");
                                                            break;
                                                        case "irregular":
                                                            await this.writeInput("record", "s", "515");
                                                            break;
                                                        case "absent":
                                                            await this.writeInput("record", "s", "516");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "alligator":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "alligator":
                                                            await this.writeInput("record", "c2", 148);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "alligator":
                                                            await this.writeInput("record", "c2", 148);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "apc":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 667].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "apc":
                                                            await this.writeInput("record", "c2", 119);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "apc":
                                                            await this.writeInput("record", "c2", 119);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "biopFor":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 5, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "biopsy forceps":
                                                            await this.writeInput("record", "c2", 145);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "biopsy forceps":
                                                            await this.writeInput("record", "c2", 145);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "bleedMinor":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6, 1, 2, 4, 7, 8, 9, 10, 11, 12, 14, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "minor bleeding":
                                                            await this.writeInput("record", "c2", 173);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([34, 9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 35, 36, 48, 561, 548, 37, 564, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 648, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "minor bleeding":
                                                            await this.writeInput("record", "c2", 173);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "cap":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "cap":
                                                            await this.writeInput("record", "c2", 144);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "cap":
                                                            await this.writeInput("record", "c2", 144);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "clip":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 21, 22, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587, 667].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(voiceInput)) {
                                                        await this.writeInput("record", "n", voiceInput);
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 646, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(voiceInput)) {
                                                        await this.writeInput("record", "n", voiceInput);
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "coag":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 7, 8, 9, 10, 11, 12, 14, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "coagrasper":
                                                            await this.writeInput("record", "c2", 120);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "coagrasper":
                                                            await this.writeInput("record", "c2", 120);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "coldSnare":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([9, 10, 11, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "cold snare":
                                                            await this.writeInput("record", "c2", 156);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 553, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "cold snare":
                                                            await this.writeInput("record", "c2", 156);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "perfor":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 21, 22, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587, 667].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "perforation":
                                                            await this.writeInput("record", "s", "123");
                                                            break;
                                                        case "closed":
                                                            await this.writeInput("record", "s", "124");
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 561, 548, 37, 564, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 646, 648, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "perforation":
                                                            await this.writeInput("record", "s", "123");
                                                            break;
                                                        case "closed":
                                                            await this.writeInput("record", "s", "124");
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "comremove":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 7, 8, 9, 10, 11, 12, 14, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "completely removed":
                                                            await this.writeInput("record", "c2", 88);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 548, 37, 564, 578, 638, 639, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "completely removed":
                                                            await this.writeInput("record", "c2", 88);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "diEpi":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "diluted epinephrine":
                                                            await this.writeInput("record", "c2", 122);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "diluted epinephrine":
                                                            await this.writeInput("record", "c2", 122);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "dualK":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "dualknife":
                                                            await this.writeInput("record", "c2", 133);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "dualknife":
                                                            await this.writeInput("record", "c2", 133);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "ele":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "elective":
                                                            await this.writeInput("record", "c2", 158);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([34, 575, 576, 577, 648].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "elective":
                                                            await this.writeInput("record", "c2", 158);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "enbloc":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 9, 10, 11, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "en bloc":
                                                            await this.writeInput("record", "c2", 152);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 553, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "en bloc":
                                                            await this.writeInput("record", "c2", 152);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "endolift":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "endolifter":
                                                            await this.writeInput("record", "c2", 143);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "endolifter":
                                                            await this.writeInput("record", "c2", 143);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "endoloop":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "endoloop":
                                                            await this.writeInput("record", "c2", 151);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "endoloop":
                                                            await this.writeInput("record", "c2", 151);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "flexK":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "flexknife":
                                                            await this.writeInput("record", "c2", 139);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "flexknife":
                                                            await this.writeInput("record", "c2", 139);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "glyce":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 9, 10, 11, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "glycerol":
                                                            await this.writeInput("record", "c2", 128);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 553, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "glycerol":
                                                            await this.writeInput("record", "c2", 128);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "graFor":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 6, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "grasping forceps":
                                                            await this.writeInput("record", "c2", 146);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 646, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "grasping forceps":
                                                            await this.writeInput("record", "c2", 146);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "histo":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "histoacryl":
                                                            await this.writeInput("record", "c2", 160);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "hookK":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hookknife":
                                                            await this.writeInput("record", "c2", 138);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hookknife":
                                                            await this.writeInput("record", "c2", 138);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "hProbe":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 7, 8, 9, 10, 11, 12, 14, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "heater probe":
                                                            await this.writeInput("record", "c2", 121);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "heater probe":
                                                            await this.writeInput("record", "c2", 121);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "hyAcid":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 9, 10, 11, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hyaluronic acid":
                                                            await this.writeInput("record", "c2", 129);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 553, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hyaluronic acid":
                                                            await this.writeInput("record", "c2", 129);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "hybridK":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hybrid knife":
                                                            await this.writeInput("record", "c2", 141);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "hybrid knife":
                                                            await this.writeInput("record", "c2", 141);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "indigo":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 9, 10, 11, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "indigo carmine":
                                                            await this.writeInput("record", "c2", 131);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 553, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "indigo carmine":
                                                            await this.writeInput("record", "c2", 131);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "interven":
                                        if (this.getIntervenForVoice && this.getIntervenForVoice[voiceInput]) {
                                            if (voiceInput == 'polypectomy') { this.colonPolypectomyFocus = true; }
                                            await this.writeInput("record", "s", this.getIntervenForVoice[voiceInput]);
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "itK":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 22, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "itknife":
                                                            await this.writeInput("record", "c2", 134);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "itknife":
                                                            await this.writeInput("record", "c2", 134);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "itK2":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "itknife-2":
                                                            await this.writeInput("record", "c2", 135);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "itknife-2":
                                                            await this.writeInput("record", "c2", 135);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "itKnano":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "itknife nano":
                                                            await this.writeInput("record", "c2", 136);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "itknife nano":
                                                            await this.writeInput("record", "c2", 136);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "label":
                                        switch (voiceInput.match(/^[a-zA-Z]$/) && Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 7, 8, 9, 10, 11, 12, 14, 15, 16, 21, 22, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587, 667].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "t", voiceInput.toUpperCase());
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 548, 37, 564, 578, 638, 639, 640, 641, 642, 643, 644, 645, 646, 651, 48, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "t", voiceInput.toUpperCase());
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "lipi":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "lipiodol":
                                                            await this.writeInput("record", "c2", 161);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "neeK":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "needle knife":
                                                            await this.writeInput("record", "c2", 140);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "needle knife":
                                                            await this.writeInput("record", "c2", 140);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "net":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "net":
                                                            await this.writeInput("record", "c2", 147);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "net":
                                                            await this.writeInput("record", "c2", 147);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "pentaFor":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "pentapod grasping forceps":
                                                            await this.writeInput("record", "c2", 150);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "pentapod grasping forceps":
                                                            await this.writeInput("record", "c2", 150);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "reFor":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "removal forceps":
                                                            await this.writeInput("record", "c2", 149);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "removal forceps":
                                                            await this.writeInput("record", "c2", 149);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "rubband":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(voiceInput)) {
                                                        await this.writeInput("record", "n", voiceInput);
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // Sigmoidoscopy
                                                if ([34, 575, 576, 577, 648].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(voiceInput)) {
                                                        await this.writeInput("record", "n", voiceInput);
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "saline":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 9, 10, 11, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "saline":
                                                            await this.writeInput("record", "c2", 130);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // Sigmoidoscopy
                                                if ([9, 17, 18, 19, 20, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 553, 548, 642, 643, 651, 661, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "saline":
                                                            await this.writeInput("record", "c2", 130);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "sbtue":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "sb tube":
                                                            await this.writeInput("record", "c2", 172);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "snare":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "snare":
                                                            await this.writeInput("record", "c2", 142);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "snare":
                                                            await this.writeInput("record", "c2", 142);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "sucra":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 5, 6, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "sucralfate gel":
                                                            await this.writeInput("record", "c2", 132);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "sucralfate gel":
                                                            await this.writeInput("record", "c2", 132);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "tissue":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 7, 8, 9, 10, 11, 12, 14, 15, 16, 21, 22, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587, 667].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(voiceInput)) {
                                                        await this.writeInput("record", "n", voiceInput);
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 548, 638, 639, 640, 641, 642, 643, 644, 645, 646, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    if (!isNaN(voiceInput)) {
                                                        await this.writeInput("record", "n", voiceInput);
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "tooth":
                                        switch (Number(this.examination_typeID)) {
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([35, 36, 37, 564, 578].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "tooth":
                                                            await this.writeInput("record", "c2", 174);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "triK":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([2, 4, 7, 9, 10, 11, 12, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 572, 573, 540, 574].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "triangle knife":
                                                            await this.writeInput("record", "c2", 137);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 560, 561, 548, 37, 564, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "triangle knife":
                                                            await this.writeInput("record", "c2", 137);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "bleedMajor":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6, 1, 2, 4, 7, 8, 9, 10, 11, 12, 14, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "major bleeding":
                                                            await this.writeInput("record", "c2", 68);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([34, 9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 35, 36, 48, 561, 548, 37, 564, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 648, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "major bleeding":
                                                            await this.writeInput("record", "c2", 68);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "intervenOther":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 3, 13, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 559, 587, 588, 592, 593, 594, 597].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "ta", voiceInput);
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 50, 52, 57, 49, 51, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 554, 555, 556, 557, 558, 559, 560, 561, 548, 37, 564, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 595, 596, 594, 597, 638, 639, 641, 642, 643, 644, 645, 646, 647, 648, 649, 651, 661, 662, 663, 664, 665, 666].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    await this.writeInput("record", "ta", voiceInput);
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "bleedStop":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6, 1, 2, 4, 7, 8, 9, 10, 11, 12, 14, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "bleeding stop":
                                                            await this.writeInput("record", "c2", 69);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([34, 9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 35, 36, 48, 561, 548, 37, 564, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 648, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "bleeding stop":
                                                            await this.writeInput("record", "c2", 69);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "bleedContinue":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([5, 6, 1, 2, 4, 7, 8, 9, 10, 11, 12, 14, 15, 16, 39, 40, 41, 42, 43, 45, 46, 47, 565, 566, 567, 568, 569, 570, 571, 572, 573, 540, 574, 587].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "bleeding continue":
                                                            await this.writeInput("record", "c2", 70);
                                                            break;
                                                    }
                                                }
                                                break;
                                            case 2: // Colonoscopy
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                if ([34, 9, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 38, 10, 39, 40, 41, 42, 43, 44, 45, 46, 47, 50, 52, 57, 540, 541, 542, 543, 544, 545, 546, 547, 549, 550, 551, 552, 553, 558, 560, 35, 36, 48, 561, 548, 37, 564, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 586, 638, 639, 640, 641, 642, 643, 644, 645, 648, 651, 661, 662, 664].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "bleeding continue":
                                                            await this.writeInput("record", "c2", 70);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve(); // here(7)
                                        break;
                                    case "causes":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([668].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "chronic esophagitis":
                                                            await this.writeInput("record", "c", 669);
                                                            break;
                                                        case "post operation":
                                                            await this.writeInput("record", "c", 670);
                                                            break;
                                                        case "cancer":
                                                            await this.writeInput("record", "c", 489);
                                                            break;
                                                        case "congenital":
                                                            await this.writeInput("record", "c", 671);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve();
                                        break;
                                    case "obstruction":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // Gastroscopy
                                                if ([668].indexOf(Number(this.getFinding.questions.finding.value)) != -1) {
                                                    switch (voiceInput) {
                                                        case "partial obstruction":
                                                            await this.writeInput("record", "s", 238);
                                                            break;
                                                        case "complete obstruction":
                                                            await this.writeInput("record", "s", 240);
                                                            break;
                                                    }
                                                }
                                                break;
                                        }
                                        resolve();
                                        break;
                                        //==========================================================================
                                        //※ 第三層的特例
                                        //==========================================================================
                                    case "switchLesion":
                                        checkInt = /^[0-9]*[1-9][0-9]*$/
                                        if (checkInt.test(voiceInput) && Number(voiceInput) > 0) {
                                            if (this.getFinding.records[(Number(voiceInput) - 1)]) {
                                                // 設定 nowRecordIndex
                                                this.setHiddenParameter(null, (Number(voiceInput) - 1));
                                                this.setStoreHiddenParameter({
                                                    target: 'nowLocationsIndex',
                                                    value: 0
                                                });
                                                /* this.setStoreHiddenParameter({
                                                    target: 'focus',
                                                    value: ''
                                                }); */
                                            }
                                        }
                                        resolve();
                                        break;
                                    case "clean_medID":
                                        var temp_obj = {
                                            "peg-els": 1,
                                            "phosphosoda": 2,
                                            "magnesium citrate": 3,
                                            "castor oil": 4,
                                            "dulcolax": 5,
                                            "enema": 6,
                                            "fleet": 7,
                                            "klean": 8,
                                            "bowklean": 9
                                        }
                                        if (temp_obj[voiceInput]) {
                                            this.setExaminationColumn('clean_medID', temp_obj[voiceInput]);
                                            this.setStoreHiddenParameter({
                                                target: 'focus',
                                                value: ''
                                            });
                                        }
                                        resolve();
                                        break;
                                    case "cleanScale":
                                        var temp_obj = CLEAN_SCALE_LIST
                                        if (temp_obj[voiceInput]) {
                                            this.setExaminationColumn('cleanScale', temp_obj[voiceInput]);
                                            // this.setStoreHiddenParameter({
                                            //     target: 'focus',
                                            //     value: ''
                                            // });
                                        }
                                        resolve();
                                        break;
                                    case "complications":
                                        var temp_obj = COMPILACTION_LIST;
                                        
                                        if (temp_obj[voiceInput]) {
                                            const newValue = Number(temp_obj[voiceInput]);
                                            if (complications.includes(newValue)) {
                                                complications = complications.filter(item => item !== newValue);
                                            } else {
                                                complications.push(newValue);
                                            }
                                            console.log(complications)
                                            this.setExaminationColumn('complication', complications);
                                            this.setStoreHiddenParameter({
                                                target: 'focus',
                                                value: ''
                                            });
                                        }
                                        resolve();
                                        break;    
                                    case "cloLoca":
                                        if (Number(this.examination_typeID) === 1) {
                                            this.$emit('examCommand', { state: 'cloLoca', value: voiceInput })
                                        }
                                        resolve()
                                        break
                                    case "indication":
                                        if (Number(this.examination_typeID) === 2) {
                                            this.$emit('examCommand', {state: 'indication', value: voiceInput})
                                            if (voiceInput === 'over' || voiceInput === 'cancel') {
                                                this.$emit('cancel')
                                                this.setStoreHiddenParameter({
                                                    target: 'over',
                                                    value: false
                                                });
                                                this.setStoreHiddenParameter({
                                                    target: 'focus',
                                                    value: ''
                                                });
                                            } else {
                                                this.$emit('examCommand', {state: 'indication', value: voiceInput})
                                            }
                                        }
                                        resolve();
                                        break;
                                    case "preparation":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // 胃鏡
                                                var temp_preparation_obj = {
                                                    "food debris": "1",
                                                    "food stasis": "2",
                                                    "food": "3"
                                                }
                                                var temp_preparationLoca_obj = {
                                                    "cervical": "31",
                                                    "upper": "32",
                                                    "middle": "33",
                                                    "lower": "34",
                                                    "ecj": "35",
                                                    "antrum": "36",
                                                    "aw antrum": "37",
                                                    "pw antrum": "38",
                                                    "gc antrum": "39",
                                                    "lc antrum": "40",
                                                    "low-body": "41",
                                                    "aw low-body": "42",
                                                    "pw low-body": "43",
                                                    "gc low-body": "44",
                                                    "lc low-body": "45",
                                                    "mid-body": "46",
                                                    "aw mid-body": "47",
                                                    "pw mid-body": "48",
                                                    "gc mid-body": "49",
                                                    "lc mid-body": "50",
                                                    "high-body": "51",
                                                    "aw high-body": "52",
                                                    "pw high-body": "53",
                                                    "gc high-body": "54",
                                                    "lc high-body": "55",
                                                    "angle": "56",
                                                    "cardia": "57",
                                                    "fundus": "58",
                                                    "bulb": "59",
                                                    "sda": "60",
                                                    "2nd portion": "61",
                                                    "papilla": "62",
                                                    "body": "63",
                                                    "aw body": "64",
                                                    "pw body": "65",
                                                    "gc body": "66",
                                                    "lc body": "67",
                                                    "upper-body": "68",
                                                    "aw upper-body": "69",
                                                    "pw upper-body": "70",
                                                    "gc upper-body": "71",
                                                    "lc upper-body": "72"
                                                }
                                                break;
                                            case 2: // 大腸鏡
                                                var temp_preparation_obj = {
                                                    "stool": "1",
                                                    "stool impaction": "2",
                                                    "stool stasis": "3"
                                                }
                                                var temp_preparationLoca_obj = {
                                                    "terminal ileum": "1",
                                                    "ileocecal valve": "2",
                                                    "appendix orifice": "3",
                                                    "cecum": "4",
                                                    "ascending colon": "5",
                                                    "proximal ascending colon": "6",
                                                    "middle ascending colon": "7",
                                                    "distal ascending colon": "8",
                                                    "hepatic flexure": "9",
                                                    "transverse colon": "10",
                                                    "proximal transverse colon": "11",
                                                    "middle transverse colon": "12",
                                                    "distal transverse colon": "13",
                                                    "splenic flexure": "14",
                                                    "descending colon": "15",
                                                    "proximal descending colon": "16",
                                                    "middle descending colon": "17",
                                                    "distal descending colon": "18",
                                                    "sigmoid colon": "19",
                                                    "proximal sigmoid colon": "20",
                                                    "middle sigmoid colon": "21",
                                                    "distal sigmoid colon": "22",
                                                    "rs junction": "23",
                                                    "rectum": "24",
                                                    "upper rectum": "25",
                                                    "middle rectum": "26",
                                                    "lower rectum": "27",
                                                    "anus": "28",
                                                    "all segments of colon": "29",
                                                    "anastomosis": "30",
                                                    "anal retroflex": "103"
                                                }
                                                break;
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                var temp_preparation_obj = {
                                                    "stool": "1",
                                                    "stool impaction": "2",
                                                    "stool stasis": "3"
                                                }
                                                var temp_preparationLoca_obj = {
                                                    "terminal ileum": "73",
                                                    "ileocecal valve": "74",
                                                    "appendix orifice": "75",
                                                    "cecum": "76",
                                                    "ascending colon": "77",
                                                    "proximal ascending colon": "78",
                                                    "middle ascending colon": "79",
                                                    "distal ascending colon": "80",
                                                    "hepatic flexure": "81",
                                                    "transverse colon": "82",
                                                    "proximal transverse colon": "83",
                                                    "middle transverse colon": "84",
                                                    "distal transverse colon": "85",
                                                    "splenic flexure": "86",
                                                    "descending colon": "87",
                                                    "proximal descending colon": "88",
                                                    "middle descending colon": "89",
                                                    "distal descending colon": "90",
                                                    "sigmoid colon": "91",
                                                    "proximal sigmoid colon": "92",
                                                    "middle sigmoid colon": "93",
                                                    "distal sigmoid colon": "94",
                                                    "rs junction": "95",
                                                    "rectum": "96",
                                                    "upper rectum": "97",
                                                    "middle rectum": "98",
                                                    "lower rectum": "99",
                                                    "anus": "100",
                                                    "all segments of colon": "101",
                                                    "anastomosis": "102",
                                                    "anal retroflex": "104"
                                                }
                                                break;
                                        }
                                        if (temp_preparation_obj[voiceInput]) {
                                            this.setExaminationColumn('preparation', temp_preparation_obj[voiceInput]);
                                        }
                                        if (temp_preparationLoca_obj[voiceInput]) {
                                            if (this.getExaminationData.examination.preparationLoca.indexOf(Number(temp_preparationLoca_obj[voiceInput])) == -1) {
                                                this.getExaminationData.examination.preparationLoca.push(Number(temp_preparationLoca_obj[voiceInput]));
                                            } else {
                                                this.getExaminationData.examination.preparationLoca.splice(this.getExaminationData.examination.preparationLoca.indexOf(Number(temp_preparationLoca_obj[voiceInput])), 1);
                                            }
                                            this.setExaminationColumn('preparationLoca', this.getExaminationData.examination.preparationLoca.join(","));
                                            this.setStoreHiddenParameter({
                                                target: 'focus',
                                                value: ''
                                            });
                                        }
                                        resolve();
                                        break;
                                    case "insertionLevel":
                                        switch (Number(this.examination_typeID)) {
                                            case 1: // 胃鏡
                                                break;
                                            case 2: // 大腸鏡
                                                var temp_obj = INSERTION_LEVEL_LIST
                                                break;
                                            case 3: // copy from case = '2' (Colonoscopy)
                                                var temp_obj = {
                                                    "terminal ileum": "73",
                                                    "ileocecal valve": "74",
                                                    "appendix orifice": "75",
                                                    "cecum": "76",
                                                    "ascending colon": "77",
                                                    "proximal ascending colon": "78",
                                                    "middle ascending colon": "79",
                                                    "distal ascending colon": "80",
                                                    "hepatic flexure": "81",
                                                    "transverse colon": "82",
                                                    "proximal transverse colon": "83",
                                                    "middle transverse colon": "84",
                                                    "distal transverse colon": "85",
                                                    "splenic flexure": "86",
                                                    "descending colon": "87",
                                                    "proximal descending colon": "88",
                                                    "middle descending colon": "89",
                                                    "distal descending colon": "90",
                                                    "sigmoid colon": "91",
                                                    "proximal sigmoid colon": "92",
                                                    "middle sigmoid colon": "93",
                                                    "distal sigmoid colon": "94",
                                                    "rs junction": "95",
                                                    "rectum": "96",
                                                    "upper rectum": "97",
                                                    "middle rectum": "98",
                                                    "lower rectum": "99",
                                                    "anus": "100",
                                                    "all segments of colon": "101",
                                                    "anastomosis": "102",
                                                    "anal retroflex": "104"
                                                }
                                                break;
                                        }
                                        if (temp_obj[voiceInput]) {
                                            this.setExaminationColumn('insertionLevel', temp_obj[voiceInput]);
                                            setTimeout(() => { 
                                                this.setStoreHiddenParameter({
                                                    target: 'focus',
                                                    value: ''
                                                })
                                            }, 300);
                                        }
                                        resolve();
                                        break;    
                                    
                                    case "incompleteDue":
                                        var temp_obj = {
                                            "poor preparation": "1",
                                            "bleeding": "2",
                                            "bowel perforation": "3",
                                            "patient intolerance": "4",
                                            "other": "5",
                                            "food stasis": "6",
                                            "undigested medicine": "7"
                                        }
                                        if (temp_obj[voiceInput]) {
                                            this.setExaminationColumn('incompleteDue', temp_obj[voiceInput]);
                                            this.setStoreHiddenParameter({
                                                target: 'focus',
                                                value: ''
                                            });
                                        }
                                        resolve();
                                        break;
                                    case "switchSedative":
                                        checkInt = /^[0-9]*[1-9][0-9]*$/
                                        if (checkInt.test(voiceInput) && Number(voiceInput) > 0) {
                                            if (this.examinationSedatives[(Number(voiceInput) - 1)]) {
                                                // 設定 nowSedativesIndex
                                                this.setStoreHiddenParameter({
                                                    target: 'nowSedativesIndex',
                                                    value: (Number(voiceInput) - 1)
                                                });
                                                // 設定 focus
                                                this.setStoreHiddenParameter({
                                                    target: 'focus',
                                                    value: 'sedative'
                                                });
                                            }
                                        }
                                        resolve();
                                        break;
                                    case "sedative":
                                        // 判斷是否為數字
                                        if (isNaN(voiceInput)) {
                                            // 藥物
                                            var column = 'sedativeID';
                                            var temp_obj = {
                                                "dormicum": 1,
                                                "fentanyl": 2,
                                                "alfentanil": 3,
                                                "propofol": 4,
                                                "remifentanil": 5,
                                                "demerol": 6,
                                                "ketalar": 7,
                                                "midazolam": 8,
                                                "meperidine": 9,
                                                "alfentanyl": 10
                                            }
                                            if (temp_obj[voiceInput]) {
                                                var value = Number(temp_obj[voiceInput]);
                                                var id = this.examinationSedatives[this.getStoreHiddenParameter.nowSedativesIndex].id;
                                                this.$store.dispatch('examinations/updateExaminationSedativeColumn', {
                                                    column,
                                                    value,
                                                    id
                                                }).then(() => this.musicPlay('saved'));
                                                this.setStoreHiddenParameter({
                                                    target: 'focus',
                                                    value: ''
                                                });
                                            }
                                        } else {
                                            // 劑量
                                            var column = 'value';
                                            var value = Number(voiceInput);
                                            var id = this.examinationSedatives[this.getStoreHiddenParameter.nowSedativesIndex].id;
                                            this.$store.dispatch('examinations/updateExaminationSedativeColumn', {
                                                column,
                                                value,
                                                id
                                            }).then(() => this.musicPlay('saved'));
                                            this.setStoreHiddenParameter({
                                                target: 'focus',
                                                value: ''
                                            });
                                        }
                                        resolve();
                                        break;
                                    default:
                                        //==========================================================================
                                        //※ 第四層 Finding Options
                                        //==========================================================================                                      
                                        let uiNumber         
                                        let uiMap = this.findUiMap
                                        switch(this.examination_typeID) {
                                            case 1:
                                                    uiNumber = uiMap["Gastroscopy"][voiceInput]
                                                break
                                            case 2:
                                                    uiNumber = uiMap["Colonoscopy"][voiceInput]
                                                break
                                            case 3:
                                                uiNumber = uiMap["Sigmoidoscopy"][voiceInput]
                                                break
                                        }
                                        if (uiNumber) {                                     
                                            await this.$store.dispatch('examinations/addFinding', this.getExaminationData.examination.id).then(async() => {
                                                if(voiceInput){
                                                    let voiceInputResult = voiceInput;
                                                    if (!GUIDE_STEPS[[MapUIforFinding.Colonoscopy['tubular adenoma']]].includes(voiceInputResult)) {
                                                        this.musicPlay(`${voiceInputResult}`)
                                                    }
                                                    setTimeout(() => { this.musicPlay('success'); }, 300);                            
                                                }                                                          

                                                // 設定 nowFindingIndex
                                                const nowFindingIndex = this.getExaminationData.findings.length - 1
                                                this.setHiddenParameter(nowFindingIndex, 0);
                                                this.setStoreHiddenParameter({
                                                    target: 'over',
                                                    value: false
                                                });
                                                if (this.checkInputExistAndSetFocuse("finding", "finding", "f")) {
                                                    await this.writeInput("finding", "s", uiNumber);
                                                }
                                                setTimeout(function() {
                                                    resolve();
                                                }, 100);
                                            });                              
                                        }

                                        resolve();                                
                                }
                        }
                }
                this.setQuestionFromInput(voiceInput);
            })
        },

        getTime(fields) {
            return get(this.getExaminationData, fields) || NO_TIME
        },
    }
}
</script>

<style lang="scss" scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.sendmail {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}
.sendmail input {
  width: 100%;
  padding: 10px 10px;
  margin: 20px 0;
  border: 1px solid #888;
}
.sendmail button {
  width: 100%;
  padding: 10px 0px;
  background: #232B5E;
  color: white;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  color: black;
}

.close {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
